import styled, { createGlobalStyle } from 'styled-components'
import styledNormalize from 'styled-normalize'
import { Box } from '@mui/material'
import themeGet from '@styled-system/theme-get'

export const GlobalStyle = createGlobalStyle`
  ${styledNormalize}

  [type="search"] {
    appearance: none;
    outline-offset: initial;
  }

  ::-webkit-input-placeholder {
    font-family: 'Open Sans';
  }
  :-moz-placeholder {
      font-family: 'Open Sans';
  }
  ::-moz-placeholder {
      font-family: 'Open Sans';
  }
  :-ms-input-placeholder {
      font-family: 'Open Sans';
  }


  html,
  body {
    margin: 0;
    width: 100%;
    min-height:100%;
    height: 100%;
    font-family: 'Open Sans', sans-serif;
    color: ${themeGet('palette.text.primary')};
    background: ${themeGet('palette.body')};
    overflow: hidden;

    .fsp-picker {
      font-family: inherit;

      .fsp-modal {
        width: 100%;
        height: 100%;
        background: ${themeGet('palette.body')};

        .fsp-header,
        .fsp-footer,
        .fsp-drop-area,
        .fsp-source-list__item.fsp-source-list__item--active {
          background: ${themeGet('palette.body')};
        }

        .fsp-summary__header {
          border: none;
        }

        .fsp-footer {
          padding: ${themeGet('space.4')}px;
          height: 76px;
          border-top: ${themeGet('borders.layer')};
        }

        .fsp-modal__sidebar {
          background: ${themeGet('palette.component.disabledBackground')};
        }
      }

      /* .fsp-picker__close-button.fsp-icon--close-modal {
        top: 22px;
        right: 22px;
      } */

      .fsp-summary__filter input {
        /* background-color: ${themeGet('palette.search.background')}; */
        border-radius: ${themeGet('radii.search')};
      }

      .fsp-summary__action.fsp-summary__action--button,
      .fsp-button.fsp-button--cancel {
        border: ${themeGet('palette.buttons.default.border')};
        background-color: ${themeGet('palette.buttons.default.backgroundColor')};
        border-radius: ${themeGet('radii.button')};
        color: ${themeGet('palette.buttons.default.color')};
      }

      .fsp-button.fsp-button--outline,
      .fsp-button.fsp-button--primary {
        border: ${themeGet('palette.buttons.primary.border')};
        background-color: ${themeGet('palette.buttons.primary.backgroundColor')};
        border-radius: ${themeGet('radii.button')};
        color: ${themeGet('palette.buttons.primary.color')};
      }

      .fsp-picker__brand-container {
        display: none;
      }
    }

    .fs-main {
      .fs-modal {
        font-family: 'Open Sans', sans-serif;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        transform: none;

        .fs-btn {
          font-size: ${themeGet('fontSizes.label')};
          min-height: 46px;

          .fs-btn-base {
            border: ${themeGet('palette.buttons.default.border')};
            background-color: ${themeGet('palette.buttons.default.backgroundColor')};
            border-radius: ${themeGet('radii.button')};
            color: ${themeGet('palette.buttons.default.color')};
          }

          .fs-btn-primary {
            border: ${themeGet('palette.buttons.primary.border')};
            background-color: ${themeGet('palette.buttons.primary.backgroundColor')};
            border-radius: ${themeGet('radii.button')};
            color: ${themeGet('palette.buttons.primary.color')};
          }
        }
      }
    }
  }

  #root {
    width: 100%;
    height: 100%;
  }

  iframe {
    border: 0;
    margin: 0;
  }

  * {
    box-sizing: border-box;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }
`

export const AppWrapper = styled(Box)`
  background-color: ${themeGet('palette.background.default')};
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;
  overflow: hidden;
`

export const AppView = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-height: 0;
  width: 100%;
`

export const Body = styled.main`
  display: flex;
  flex-direction: column;
  flex: 1 1 100%;
  min-height: 0;
  overflow-x: hidden;
  overflow-y: overlay;
  transition: all ease-in-out .2s;

  /* We love Safari, don't we? */
  @media screen and (-webkit-min-device-pixel-ratio: 0) {
    -webkit-overflow-scrolling: touch;
  }
`
