import { RESOURCE_TYPES } from '@concrete/resource'
import { LAYOUTS } from 'helpers/constants'

const { ALL, STORIES } = RESOURCE_TYPES
const { GRID, LIST, CALENDAR } = LAYOUTS

export const getViewModes = resource => {
  const modes = [
    { id: GRID, icon: 'streamlineGrid' },
    { id: LIST, icon: 'streamlineList', hidden: [ALL, STORIES].includes(resource) },
    { id: CALENDAR, icon: 'streamlineCalendar' },
  ]
  return modes.filter(mode => !mode.hidden)
}
