import { takeLatest } from 'redux-saga/effects'
import { bootstrap } from '@concrete/one-redux'
import { getLoginErrorUrl, getUniversalLoginUrl } from '../auth/helpers'

const ERR_INVALID_SESSION = 'SESS-002'
const ERR_USER_NOT_FOUND = 'SESS-003'

function getErrorFromCode(errorCode) {
  if (errorCode === 404) {
    return ERR_USER_NOT_FOUND
  }

  return ERR_INVALID_SESSION
}

function getLoginErrorPage (error, errorCode, returnUrl) {
  if (error === 'reset_password') return `${getUniversalLoginUrl()}/resetPassword?callbackUrl=${returnUrl}`
  return `${getLoginErrorUrl()}?error=${getErrorFromCode(errorCode)}&callbackUrl=${returnUrl}`
}

// eslint-disable-next-line require-yield
function* handleSessionNotValidError({ error, errorCode }) {
  const returnUrl = encodeURIComponent(`${window.location.origin}/callback`)
  window.location.replace(getLoginErrorPage(error, errorCode, returnUrl))
}

function* saga() {
  yield takeLatest(
    [
      bootstrap.actionTypes.AUTHENTICATION_PARTIAL_FAIL,
      bootstrap.actionTypes.AUTHENTICATION_USER_NOT_FOUND,
    ],
    handleSessionNotValidError
  )
}

const authenticationError = {
  saga,
  handleSessionNotValidError,
}

export default authenticationError
