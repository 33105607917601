import React, { useEffect, useState, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Wrapper, CloseStyle, MessageStyle } from './styles'
import { useTranslation } from 'react-i18next'
import { hide } from 'state/toast'

const Toast = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { message, action } = useSelector(state => state.toast || {})
  const [isShown, setIsShown] = useState(false)

  const hideToast = useCallback(() => {
    setIsShown(false)
    dispatch(hide({ action: 'close' }))
  }, [dispatch])

  useEffect(() => {
    setIsShown(!!message)
    if(message && action !== 'error') {
      setTimeout(() => {
        hideToast()
      }, 3000)
    }
  }, [action, hideToast, message])

  return (
    <Wrapper role="alert" action={action} isShown={isShown} onClick={hideToast}>
      <MessageStyle>{message}</MessageStyle>
      {action && action === 'error' &&
        <CloseStyle>
          <button data-testid="close-toast">
            {t('close')}
          </button>
        </CloseStyle>
      }
    </Wrapper>
  )
}

export default Toast
