import { takeEvery, put } from 'redux-saga/effects'
import {
  documents,
  annotations,
  compliance,
  users,
  distributionLists,
  templates,
  files,
  resource,
  distribution,
  connect,
} from '@concrete/one-redux'
import { RESOURCE_TYPES } from '@concrete/resource'
import { getNumberOfAssignees } from 'helpers'
import tasksUi from './tasksUi'
import uploadsUi from './uploadsUi'
import i18n from '../translate/i18n'
import { modals } from 'state'

const { ASSETS, FORMS , TEMPLATES} = RESOURCE_TYPES

// actions -----
const actionTypes = {
  SHOW_TOAST: 'SHOW_TOAST',
  HIDE_TOAST: 'HIDE_TOAST',
}

export const reducer = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.SHOW_TOAST: {
      return {
        message: action.message,
        action: action.action,
      }
    }
    case actionTypes.HIDE_TOAST: {
      return {}
    }
    default:
      return state
  }
}

// action creators -----
export const show = (message, action = 'success') => ({
  type: actionTypes.SHOW_TOAST,
  message,
  action,
})

export const hide = () => ({
  type: actionTypes.HIDE_TOAST,
  action: 'close',
})

// sagas -----

export function* notifyResourceDistributed({ resourceType, distribution }) {
  if (resourceType === ASSETS) {
    yield put(show(i18n.t(`files:folder.${getNumberOfAssignees(distribution.audience) ? 'folderShared' : 'folderUnshared' }`)))
  } else {
    yield put(show(i18n.t(`${resourceType}:sent`)))
  }
}

export function* notifyResourceScheduled(action) {
  const { commitPayload, resourceType } = action
  const { sentAt } = commitPayload
  yield put(show(i18n.t(`${resourceType}:scheduledFor`, {'scheduledDate' :sentAt})))
}

export function* notifyResourceDistributeFailed({ resourceType }) {
  if (resourceType === ASSETS)
    yield put(show(i18n.t('files:folder.folderShareFailed'), 'error'))
  else
    yield put(show(i18n.t(`${resourceType}:distributeFailed`), 'error'))
}

export function* notifyCopyFailed({ resourceType }) {
  yield notifyMessage({ key: 'copyFailed', namespace: resourceType, type: 'error' })
}

export function* notifyDocumentCompleted(action) {
  const { resourceType } = action
  resourceType === FORMS ?
  yield put(show(i18n.t('forms:formSubmitted'))) :
  yield put(show(i18n.t('tasks:taskCompleted')))
}

export function* notifyNotificationSent() {
  yield put(show(i18n.t('general:notificationSent')))
}

export function* notifySentForReview({ resourceType }) {
  resourceType === FORMS ?
  yield put(show(i18n.t('forms:formSubmitted'))) :
  yield put(show(i18n.t('tasks:taskSentForApproval')))
}

export function* notifyTemplateCreated(action) {
  const { template, showToast } = action
  if (showToast) {
    yield put(show(i18n.t('templates.templateCreated', {'templateName': template.templateName})))
  }
}

export function* notifyTaskAttachmentRemoved() {
  yield put(show(i18n.t('tasks:attachments.deleted')))
}

export function* notifyMessage({ key, namespace, type }) {
  yield put(show(i18n.t(`${namespace}:${key}`), type))
}

export function* notifyNoFilesToDownload(action) {
  if (action.fileIds.length === 0) {
    yield put(show(i18n.t('tasks:attachments:noFilesToDownload'), 'error'))
  }
}

export function* notifyDocumentCancelled({ resourceType, completion, groupName, completions, item = {} }) {
  const { type } = item
  const translationMap = {
    forms: type === TEMPLATES ? 'archivedMessage' : 'cancelSuccess',
    tasks: `cancel.${completion ? 'cancelledForName' : 'taskCancelled'}`,
    stories: 'archivedMessage',
    events: 'archivedMessage',
  }
  const key = translationMap[resourceType]
  yield put(show(i18n.t(`${resourceType}:${key}`, { name: groupName })))
}

export function* notifyCancelDocumentFailed({ resourceType}) {
  yield notifyMessage({ key: 'cancelFailed', namespace: resourceType, type: 'error' })
}

export function* notifyDeleteDocument() {
  yield put(show(i18n.t('tasks:deleteSuccess')))
}

export function* notifyCopied() {
  yield put(show(i18n.t('copyCreated')))
}

export function* notifyGroupCheckedIn({ group }) {
  yield put(show(i18n.t('connect:groupCheckedIn', { name: group.name })))
}

export function* notifyAudienceUpdated({ resourceType, groups, users, showToast }) {
  if (!showToast) return null
  if (resourceType === ASSETS) {
    // TODO: display message for audience updated?
    yield put(show(i18n.t(`files:folder.${getNumberOfAssignees({ groups, users }) ? 'folderShared' : 'folderUnshared' }`)))
  } else {
    yield put(show(i18n.t('audienceUpdated')))
  }
}

export function* notifyAudienceUpdateFailed({ resourceType }) {
  if (resourceType === ASSETS) {
    yield put(show(i18n.t('files:folder.folderShareFailed'), 'error'))
  } else {
    yield put(show(i18n.t('audienceUpdateFailed'), 'error'))
  }
}

export function* notifyUnassigned() {
  yield put(show(i18n.t('tasks:unassigned')))
}

export function* notifyCompletionApproval({ resourceType }) {
  if (resourceType === FORMS) {
    yield put(show(i18n.t('forms:approved')))
  }
}

export function* notifyComplianceCompleted() {
  yield put(show(i18n.t('general:movedToDone')))
}

export function* notifyUploadsCompleted({ completed, failed }) {
  const successCount = completed.length
  const failureCount = failed.length
  const filesCount = successCount + failureCount
  if (failureCount > 0) {
    yield put(show(i18n.t('tasks:filesFailure', { successCount, filesCount, failureCount }), 'error'))
  } else {
    yield put(show(i18n.t('tasks:filesSuccess', { successCount, filesCount })))
  }
}

export function* notifyDocumentSketchSubmitted({ resourceType }) {
  yield put(show(i18n.t(`${resourceType}:submitToast`)))
}

export function* notifyAssetsDeleteFailed({ error }) {
  if (error === 'Folder not empty') {
    yield put(show(i18n.t('files:deleteFailedNonEmptyFolder'), 'error'))
  } else {
    yield put(show(i18n.t('files:deleteFailed'), 'error'))
  }
}

export function* notifyQueueFilesDownloadFailed({ error }) {
  let failedBody
  switch (error) {
    case 'Max download size exceeded':
      failedBody = i18n.t('files:download.maxSizeExceededError')
      break
    case 'No files to download':
      failedBody = i18n.t('files:download.noFiles')
      break
    default:
      failedBody = i18n.t('files:download.genericError')
  }
  yield put(modals.open('DialogBox', {
    iconName: 'dialogWarning',
    iconColor: 'error.main',
    header: i18n.t('prepareFailed'),
    body: failedBody,
    confirmLabel: i18n.t('ok'),
  }))
}

export function* saga() {
  yield takeEvery(
    tasksUi.actionTypes.TASK_REMINDER_SENT,
    notifyMessage,
    { key: 'reminder.hasBeenSent', namespace: 'tasks' },
  )
  yield takeEvery(
    compliance.actionTypes.COMPLIANCE_CANCELLED,
    notifyDocumentCancelled,
  )
  yield takeEvery(
    compliance.actionTypes.COMPLETION_CANCELLED,
    notifyDocumentCancelled,
  )
  yield takeEvery(
    compliance.actionTypes.COMPLIANCE_COMPLETED,
    notifyComplianceCompleted,
  )
  yield takeEvery(
    tasksUi.actionTypes.NOTE_ADDED,
    notifyMessage,
    { key: 'notes.noteWasAdded', namespace: 'tasks' },
  )
  yield takeEvery(
    tasksUi.actionTypes.ADD_NOTE_FAILED,
    notifyMessage,
    { key: 'notes.addNoteFailed', namespace: 'tasks', type: 'error' },
  )
  yield takeEvery(
    tasksUi.actionTypes.NOTE_EDITED,
    notifyMessage,
    { key: 'notes.noteWasEdited', namespace: 'tasks' },
  )
  yield takeEvery(
    tasksUi.actionTypes.EDIT_NOTE_FAILED,
    notifyMessage,
    { key: 'notes.editNoteFailed', namespace: 'tasks', type: 'error' },
  )
  yield takeEvery(
    tasksUi.actionTypes.NOTE_REMOVED,
    notifyMessage,
    { key: 'notes.noteWasRemoved', namespace: 'tasks' },
  )
  yield takeEvery(
    tasksUi.actionTypes.REMOVE_NOTE_FAILED,
    notifyMessage,
    { key: 'notes.removeNoteFailed', namespace: 'tasks', type: 'error' },
  )
  yield takeEvery(distribution.actionTypes.RESOURCE_SCHEDULED, notifyResourceScheduled)
  yield takeEvery(distribution.actionTypes.RESOURCE_DISTRIBUTED, notifyResourceDistributed)
  yield takeEvery(
    annotations.actionTypes.ATTACHMENT_REMOVED,
    notifyTaskAttachmentRemoved,
  )
  yield takeEvery(
    annotations.actionTypes.REMOVE_ATTACHMENT_REQUEST_FAILED,
    notifyMessage,
    { key: 'attachments.deleteFailed', namespace: 'tasks', type: 'error' },
  )
  yield takeEvery(
    compliance.actionTypes.UPDATE_COMPLIANCE_APPROVERS_FAILED,
    notifyMessage,
    { key: 'approvers.approversEditingFailed', namespace: 'tasks', type: 'error' },
  )
  yield takeEvery(resource.actionTypes.RESOURCE_NOTIFIED, notifyNotificationSent)
  yield takeEvery(resource.actionTypes.NOTIFY_RESOURCE_FAILED,
    notifyMessage,
    { key: 'notificationFailure', namespace: 'general', type: 'error' })
  yield takeEvery(compliance.actionTypes.COMPLETION_COMPLETED, notifyDocumentCompleted)
  yield takeEvery(compliance.actionTypes.COMPLETION_APPROVED, notifyCompletionApproval)
  yield takeEvery(compliance.actionTypes.COMPLETION_SUBMITTED_FOR_REVIEW, notifySentForReview)
  yield takeEvery(
    compliance.actionTypes.COMPLETION_REJECTION_REQUEST_FAILED,
    notifyMessage,
    { key: 'rejectFailed', namespace: 'tasks', type: 'error' },
  )
  yield takeEvery(
    users.actionTypes.USER_DETAILS_UPDATED,
    notifyMessage,
    { key: 'user.userProfileUpdated', namespace: 'general' },
  )
  yield takeEvery(
    users.actionTypes.USER_UPDATE_DETAILS_REQUEST_FAILED,
    notifyMessage,
    { key: 'user.userProfileNotUpdated', namespace: 'general', type: 'error' },
  )
  yield takeEvery(
    distributionLists.actionTypes.DISTRIBUTION_LIST_SAVED,
    notifyMessage,
    { key: 'lists.listCreated', namespace: 'general' },
  )
  yield takeEvery(
    distributionLists.actionTypes.SAVE_DISTRIBUTION_LIST_REQUEST_FAILED,
    notifyMessage,
    { key: 'lists.listCreatedFailed', namespace: 'general', type: 'error' },
  )
  yield takeEvery(documents.actionTypes.DOCUMENT_DELETED, notifyDeleteDocument)
  yield takeEvery(compliance.actionTypes.COMPLIANCE_CANCEL_REQUEST_FAILED, notifyCancelDocumentFailed)
  yield takeEvery (
    documents.actionTypes.DELETE_DOCUMENT_FAILED,
    notifyMessage,
    { key: 'deleteFailed', namespace: 'tasks', type: 'error' },
  )
  yield takeEvery(
    templates.actionTypes.TEMPLATE_CREATED,
    notifyTemplateCreated,
  )
  yield takeEvery (
    templates.actionTypes.TEMPLATE_DELETED,
    notifyMessage,
    { key: 'templateDeleted', namespace: 'templates' },
  )
  yield takeEvery (
    distribution.actionTypes.DISTRIBUTE_RESOURCE_FAILED,
    notifyResourceDistributeFailed,
  )
  yield takeEvery(
    documents.actionTypes.DOCUMENT_FORWARDED,
    notifyMessage,
    { key: 'sent', namespace: 'tasks' },
  )
  yield takeEvery(
    documents.actionTypes.DOCUMENT_FORWARD_REQUEST_FAILED,
    notifyMessage,
    { key: 'forwardTaskFailed', namespace: 'tasks', type: 'error' },
  )
  yield takeEvery(
    files.actionTypes.FOLDER_CREATED,
    notifyMessage,
    { key: 'folder.created', namespace: 'files', type: 'success' }
  )

  yield takeEvery(
    files.actionTypes.FOLDER_CREATE_FAILED,
    notifyMessage,
    { key: 'folder.error', namespace: 'files', type: 'error' }
  )

  yield takeEvery(
    files.actionTypes.ASSET_EDIT_FAILED,
    notifyMessage,
    { key: 'folder.editError', namespace: 'files', type: 'error' }
  )
  yield takeEvery(
    files.actionTypes.FILES_DOWNLOAD_SUCCESS,
    notifyNoFilesToDownload
  )
  yield takeEvery(
    files.actionTypes.FILES_DOWNLOAD_FAILED,
    notifyMessage,
    { key: 'downloadError', namespace: 'files', type: 'error' }
  )
  yield takeEvery(files.actionTypes.ASSETS_DELETED, notifyDeleteDocument)
  yield takeEvery(files.actionTypes.ASSETS_DELETED_REQUEST_FAILED, notifyAssetsDeleteFailed)
  yield takeEvery(documents.actionTypes.DOCUMENT_SKETCH_SUBMITTED, notifyDocumentSketchSubmitted)
  yield takeEvery(compliance.actionTypes.COMPLETION_SUBMIT_FAILED, notifyMessage, { key: 'submitFailed', namespace: 'forms', type: 'error' })
  yield takeEvery(documents.actionTypes.DOCUMENT_COPIED, notifyCopied)
  yield takeEvery(documents.actionTypes.DOCUMENT_COPY_FAILED, notifyCopyFailed)
  yield takeEvery(compliance.actionTypes.COMPLETION_START_REQUEST_FAILED, notifyMessage, { key: 'startFailed', namespace: 'forms', type: 'error' })
  yield takeEvery(distribution.actionTypes.DISTRIBUTED_AUDIENCE_UPDATED, notifyAudienceUpdated)
  yield takeEvery(distribution.actionTypes.UPDATE_AUDIENCE_FAILED, notifyAudienceUpdateFailed)
  yield takeEvery(
    connect.actionTypes.GROUP_CHECKED_IN,
    notifyGroupCheckedIn,
  )
  yield takeEvery(
    connect.actionTypes.GROUP_CHECK_IN_FAILED,
    notifyMessage,
    { key: 'checkInFailed', namespace: 'connect', type: 'error' }
  )
  yield takeEvery(
    compliance.actionTypes.COMPLETION_UNASSIGNED,
    notifyUnassigned
  )
  yield takeEvery(
    uploadsUi.actionTypes.UPLOAD_QUEUE_DRAINED,
    notifyUploadsCompleted,
  )
  yield takeEvery(
    files.actionTypes.QUEUE_FILES_DOWNLOAD_FAILED,
    notifyQueueFilesDownloadFailed,
  )
}

const toast = {
  show,
  hide,
  actionTypes,
  reducer,
  saga,
}

export default toast
