import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { compliance } from '@concrete/one-redux'
import { resourceLocation } from 'state'
import { useResourceSelector } from './resource'

export const EMPTY_FIELDS = { filters: {} }
const COMPLIANCE_FIELDS = {
  tasks: {
    actions: true,
    summaryStatus: true,
    status: true,
    startedAt: true,
    filters: {
      filter: true,
      status: true,
      text: true,
      uploads: true,
    },
  },
  announcements: {
    actions: true,
    summaryStatus: true,
    status: true,
    startedAt: true,
    filters: {
      filter: true,
      status: true,
      text: true,
      uploads: true,
    },
  },
  forms: {
    summaryStatus: false,
    status: true,
    statusWithUser: true,
    createdAt: true,
    createdAtWithUser: true,
    filters: {
      results: true,
      text: true,
      status: true,
    },
  },
  stories: {
    summaryUsers: true,
    filters: {
      results: true,
      text: true,
      usersSummary: true,
    },
  },
}

// Note: ported logic from getNodeRollup from the old task breakdown
// TODO: pre-compute in one-redux when doing the rollup
const computeSummary = (node) => {
  if (!node) return {}
  const facets = node.facets
  const done = facets.done || 0
  const cancelled = facets.cancelled || 0
  const doable = node.total - cancelled
  const progress = Math.floor(done / doable * 100) || 0
  const shouldDisplayProgress = done > 0 && done < doable

  const summary = {
    ...node,
    status: 'inprogress',
    progress,
    doable,
    done,
    facets,
  }

  if (cancelled === node.total) {
    summary.status = 'cancelled'
  }

  if (!shouldDisplayProgress) {
    for (const facet in node?.facets) {
      if (facet !== 'cancelled' && node?.facets[facet] === doable) {
        summary.status = facet
        break
      }
    }
  }
  return summary
}

/**
 * Returns a summary (rollup) of completions for a group
 *
 * @param {String} resourceId
 * @param {String} resourceType
 * @param {String} groupId
 */
export const useComplianceGroupSummary = (resourceId, resourceType, groupId) => {
  const currentGroupId = useSelector(state => resourceLocation.selectCurrentLocation(state, resourceType))
  const progress = useSelector(state => compliance.selectComplianceProgress(state, resourceType, resourceId))
  const breakdown = useSelector(
    state => compliance.selectComplianceBreakdown(state, resourceId, resourceType),
  )

  return useMemo(() => {
    let node = breakdown?.[groupId]
    if (!node && progress && currentGroupId === groupId) {
      // there is a compliance progress for current user's group
      node = { facets: { ...progress, total: undefined }, total: progress.total }
    }
    return computeSummary(node)
  }, [breakdown, currentGroupId, groupId, progress])
}

export const useComplianceFields = (resourceId, resourceType) => {
  const resource = useResourceSelector(resourceId, resourceType)
  const fields = COMPLIANCE_FIELDS[resourceType] || EMPTY_FIELDS
  const filters = fields.filters
  // announcements are special flowers
  return {
    ...fields,
    filters: {
      ...filters,
      filter: !!filters.filter && !resource?.isAnnouncement,
      uploads: !!filters.uploads && !resource?.isAnnouncement,
    }
  }
}