import auth0 from 'auth/auth0'
import firebase from 'auth/firebase'
import quinyx from 'auth/quinyx'
import { getQueryStringFromParams } from 'helpers/location'
import { getAuthProvider } from './helpers'

class Auth {
  static instance
  // don't call this. Instead call Auth.getInstance()
  constructor() {
    const authClient = getAuthProvider()

    switch(authClient) {
      case 'auth0':
        Auth.instance = auth0.init()
        break
      case 'firebase':
        Auth.instance = firebase.init()
        break
      case 'quinyx':
        Auth.instance = quinyx.init()
        break
      default:
        throw new Error('Invalid authentication provider')
    }

    return Auth.instance
  }

  static getInstance() {
    if (!Auth.instance) {
      Auth.instance = new Auth()
    }

    return Auth.instance
  }
}

function handleSsoAuth(cb) {
  return Auth.getInstance().handleSsoAuth(cb)
}

function getSsoToken(cb) {
  return Auth.getInstance().getSsoToken(cb)
}

function ssoLogin(redirectUrl) {
  return Auth.getInstance().authorize(redirectUrl)
}

function getSsoLogoutUrl() {
  return Auth.getInstance().getSsoLogoutUrl()
}

function getLogoutPageUrl(remoteLogoutUrl = '', hasFederatedLogout = false) {
  const queryString = getQueryStringFromParams({
    rlurl: remoteLogoutUrl,
    federated: hasFederatedLogout,
  })

  return `/logout${queryString ? '?' + queryString : ''}`
}

async function logout() {
  localStorage.removeItem('initPath')
  return Auth.getInstance().logout()
}

const auth = {
  ssoLogin,
  handleSsoAuth,
  getSsoToken,
  getSsoLogoutUrl,
  getLogoutPageUrl,
  logout,
}

export default auth
