import { session } from '@concrete/one-redux'
import { takeLatest, put, select } from 'redux-saga/effects'

const actionTypes = {
  LOCATION_CHANGE_REQUESTED: 'LOCATION_CHANGE_REQUESTED',
  LOCATION_CHANGED: 'LOCATION_CHANGED',
}

const changeLocation = (resource, groupId) => ({
  type: actionTypes.LOCATION_CHANGE_REQUESTED,
  resource,
  groupId,
})

const selectCurrentLocation = (state, resource) => {
  const userId = session.selectCurrentUserId(state)
  const groupId = localStorage.getItem(`${resource}-current-location-${userId}`)
  return groupId || session.selectCurrentGroupId(state)
}

function* changeLocationSaga({ resource, groupId }) {
  const userId = yield select(session.selectCurrentUserId)
  localStorage.setItem(`${resource}-current-location-${userId}`, groupId)
  yield put({
    type: actionTypes.LOCATION_CHANGED,
    resource,
    groupId,
  })
}

function* saga() {
  yield takeLatest(actionTypes.LOCATION_CHANGE_REQUESTED, changeLocationSaga)
}

const resourceLocation = {
  actionTypes,
  changeLocation,
  changeLocationSaga,
  saga,
  selectCurrentLocation,
}

export default resourceLocation
