import styled from 'styled-components'
import themeGet from '@styled-system/theme-get'
import { Flex, media } from '@concrete/web-components'

export const AppNavWrapper = styled(Flex).attrs({ as: 'nav' })`
  margin: ${themeGet('space.4')}px auto;
  width: 100%;
  justify-content: space-between;
  flex-wrap: wrap;
  flex-shrink: 0;
`

export const AppNavSlider = styled(Flex)`
  overflow: hidden;
  overflow-x: auto;
  margin-bottom: ${themeGet('space.3')}px;

  ${media.large`
    margin-bottom: 0
  `}
`
