import { compliance, documents } from '@concrete/one-redux'
import { get as getProp } from 'lodash'

const actionTypes = {
  COMPLIANCE_BREAKDOWN_NODE_TOGGLED: 'COMPLIANCE_BREAKDOWN_NODE_TOGGLED',
  COMPLIANCE_BREAKDOWN_TOGGLED: 'COMPLIANCE_BREAKDOWN_TOGGLED',
}

// Action creators ---
const toggleBreakdown = resourceId => ({
  type: actionTypes.COMPLIANCE_BREAKDOWN_TOGGLED,
  resourceId,
})

const toggleBreakdownNode = (resourceId, nodeId) => ({
  type: actionTypes.COMPLIANCE_BREAKDOWN_NODE_TOGGLED,
  resourceId,
  nodeId,
})

const reducer = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.COMPLIANCE_BREAKDOWN_TOGGLED: {      
      return {
        ...state,
        [action.resourceId]: {
          expanded: !state[action.resourceId]?.expanded,
        },
      }
    }

    case actionTypes.COMPLIANCE_BREAKDOWN_NODE_TOGGLED: {
      const path = `${action.resourceId}/open_nodes`
      const existingState = state[path] || {}
      
      return {
        ...state,
        [path]: {
          ...existingState,
          [action.nodeId]: !existingState[action.nodeId],
        },
      }
    }

    case compliance.actionTypes.COMPLETIONS_REQUESTED: {
      return {
        ...state,
        [action.resourceId]: {
          ...state[action.resourceId],
          loading: true,
        }
      }
    }

    case compliance.actionTypes.COMPLETIONS_REQUEST_FAILED:
    case compliance.actionTypes.COMPLETIONS_LOADED: {
      return {
        ...state,
        [action.resourceId]: {
          ...state[action.resourceId],
          loading: false,
        }
      }
    }

    case compliance.actionTypes.COMPLETION_STARTED: {
      const startedCompletionId = action.completion._id
        return {
          ...state,
          startedCompletionId,
        }
      }

    case documents.actionTypes.DOCUMENT_REQUESTED: {
      return {
        ...state,
        startedCompletionId: undefined,
      }
    }

    default:
      return state
  }
}

// Selectors
const isBreakdownExpanded = (state, resourceId) =>
  getProp(state, `complianceUi.${resourceId}.expanded`)
const isBreakdownNodeExpanded = (state, resourceId, nodeId, defaultValue = false) =>
  getProp(state, `complianceUi.${resourceId}/open_nodes.${nodeId}`) ?? defaultValue
const areCompletionsLoading = (state, resourceId) =>
  getProp(state, `complianceUi.${resourceId}.loading`, false)
const selectStartedCompletionId = state =>
  getProp(state, 'complianceUi.startedCompletionId')
  
const complianceUi = {
  actionTypes,
  toggleBreakdown,
  toggleBreakdownNode,
  reducer,
  isBreakdownExpanded,
  isBreakdownNodeExpanded,
  areCompletionsLoading,
  selectStartedCompletionId,
}

export default complianceUi
