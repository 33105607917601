import React from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { RESOURCE_TYPES } from '@concrete/resource'
import { documents, resource } from '@concrete/one-redux'
import { Button, SvgIcon, Box } from '@concrete/web-components'
import { modals, tracker, resourceLocation } from 'state'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

const ButtonLabel = styled(Box)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const { ASSETS } = RESOURCE_TYPES

const FacetsFilterButton = ({ resourceType, activeItem, layout, navigator }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const isModalOpen = useSelector(state => modals.isModalOpen(state, 'FacetsSidebar'))

  const onSidebarItemClick = id => {
    navigator.replace({ facet: id, page: 1 })
    dispatch(tracker.event.newDashboard.clickedSidebarItem(id, resourceType))
    dispatch(tracker.event.newDashboard.clickedSidebarFacet(layout, id))
  }
  const facetGroups = {
    all: '1',
    overview: '1',
    my: '2',
    unassigned: '2',
    awaitingApproval: '2',
    sent: '3',
    scheduled: '3',
    active: '3',
    published: '3',
    drafts: '3',
    feed: '3',
    completed: '4',
  }
  const currentGroupId = useSelector(state => resourceLocation.selectCurrentLocation(state, resourceType))
  const resourceFacets = useSelector(state => resource.selectFacets(state, resourceType, currentGroupId)) || []
  const areFacetsLoading = useSelector(state => documents.selectDocumentFacetsAreLoading(state, resourceType, currentGroupId))

  const getNavItems = () => {
    const namespace = resourceType === ASSETS ? 'files' : resourceType
    return resourceFacets.map(facet => ({
      id: facet.key,
      label: t(`${namespace}:facets.${facet.key}`),
      count: facet.total,
      isLoading: areFacetsLoading,
      group: facetGroups[facet.key],
    }))
  }

  const namespace = resourceType === ASSETS ? 'files' : resourceType

  const handleClick = () => {
    dispatch(modals.open('FacetsSidebar', {
      title: t('tasks:dashboard.filters.facetsSidebarTitle'),
      activeItem,
      resourceType,
      items: getNavItems(),
      isLoading: areFacetsLoading,
      onItemClick: id => {
        onSidebarItemClick(id)
      },
    }))
  }

  if (!activeItem) return null
  return (
    <Button iconPosition="center" small type="pillActiveWhite" onClick={handleClick} data-testid="facets-filter-button">
      <ButtonLabel mr={1}>{t(`${namespace}:facets.${activeItem}`)}</ButtonLabel>
      <SvgIcon name="chevron" size="xs" rotateRight={isModalOpen} />
    </Button>
  )
}

FacetsFilterButton.propTypes = {
  resourceType: PropTypes.string,
  activeItem: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  layout: PropTypes.string,
  navigator: PropTypes.object,
}

export default FacetsFilterButton
