import React from 'react'
import { useSelector } from 'react-redux'
import { resource } from '@concrete/one-redux'
import { Chip } from '@mui/material'
import { useResourceState } from 'hooks'

const ResourceCount = ({ resourceType }) => {
  const resourceState = useResourceState(resourceType)
  const count = useSelector(state => resource.selectResourceCount(state, resourceState)) || 0
  return (
    <Chip
      data-testid="resource-count"
      label={count}
      color="primary"
      size="small"
      sx={{
        alignSelf: 'center',
        mx: 2,
      }}
    />
  )
}

export default ResourceCount
