import { hooks } from '@concrete/web-components'

export const GRID_WIDTH_SMALL = 500
export const GRID_WIDTH_MEDIUM = 750
export const GRID_WIDTH_LARGE = 1000

export const useGridSize = () => {
  const { width: windowSize } = hooks.useWindowSize()
  const isSmall = hooks.useMediaSize('small')
  const isMedium = hooks.useMediaSize('medium')
  const isLarge = hooks.useMediaSize('large')
  let size = windowSize
  if (isSmall) size = GRID_WIDTH_SMALL
  if (isMedium) size = GRID_WIDTH_MEDIUM
  if (isLarge) size = GRID_WIDTH_LARGE
  return Math.min(size, windowSize)
}
