import moment from 'moment'
import i18next from 'i18next'

export const formatDate = (date, format = 'date.dayMonthYearHourMinute', relativeDate = false, allDay = false) => {
  const localDate = moment(date).utc().local()
  const time = localDate.format("HH:mm")
  const defaultEndTime = "23:59"
  const defaultStartTime = "00:01"
  if (!date || date === '' || date === '2040-01-01T00:00:00') return 'None'
  if (relativeDate) {
    const now = moment()
    if (localDate.isSame(now, 'day')) return `${i18next.t('date.today')} - ${time}`
    if (localDate.isSame(now.subtract(1, 'day'), 'day')) return i18next.t('date.yesterday')
  }
  if (allDay && [defaultEndTime, defaultStartTime].includes(time)) {
    return `${localDate.format(i18next.t('date.dayMonthYear'))} - ${i18next.t('date.allDay')}`
  }
  return moment.utc(date).local().format(i18next.t(format))
}

export const isDateInTheFuture = (date) => !!date && moment().isBefore(date)
export const isDateInThePast = (date) => !!date && moment().isAfter(date)

export const formatTime = (date) => {
  if (!date || date === '') return 'N/A'
  return moment.utc(date).local().format(i18next.t('date.short24Hour'))
}

export const getCurrentWeek = () => {
  const startOfWeek = moment().startOf('week').utc()
  const endOfWeek = moment().endOf('week').endOf('day').utc()
  return `${startOfWeek.toISOString()},${endOfWeek.toISOString()}`
}

export const daysInFuture = (sentAt, createdAt) => {
  const sentDate = moment(sentAt)
  const createDate = moment(createdAt)
  return sentDate.diff(createDate, 'days')
}

export const toOrdinal = (index, total) => {
  if (index === total) return i18next.t('final')
  let pos
  switch(index.toString().slice(-1)) {
    case '1': pos = index === 11 ? 'other' : 'first'
      break
    case '2': pos = index === 12 ? 'other' : 'second'
      break
    case '3': pos = index === 13 ? 'other' : 'third'
      break
    default: pos = 'other'
  }
  return i18next.t(`ordinal.${pos}`, { count: index })
}
