import React, { useCallback, useRef, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { useLocation, useHistory } from 'react-router'
import { session, permissions } from '@concrete/one-redux'
import { Flex, SearchBar, SvgIcon } from '@concrete/web-components'
import { RESOURCE_TYPES } from '@concrete/resource'
import { useGridSize, useResourceNavigator, useQuery, useGoBack } from 'hooks'
import BackButton from './BackButton'
import SettingsButton from './SettingsButton'
import NavbarBrandAndLogo from './NavbarBrandAndLogo'
import NotificationsBell from './NotificationsBell'
import { NavbarWrapper } from './style'
import CreateButton from '../CreateButton'
import { notifyWebView } from 'bootstrap/events'
import { HUB_PAGES } from 'helpers/constants'
import { useTranslation } from 'react-i18next'

const { ALL } = RESOURCE_TYPES

const AppHeader = ({ compact, resetScroll }) => {
  const inputRef = useRef(null)
  const { t } = useTranslation()
  const { pathname, search, state } = useLocation() || {}
  const query = useQuery()
  const { q } = query || {}
  const history = useHistory()
  const navigator = useResourceNavigator(ALL, '/search', state)
  const gridWidth = useGridSize()
  const { brandColor } = useSelector(session.selectCurrentUser) || {}
  const [isViewer] = useSelector(state => permissions.userHasRoles(state, [permissions.ROLES.VIEWER]))
  const isHubPage = HUB_PAGES.includes(pathname)
  const isSearchPage = pathname.includes('search')
  const [isSearchOpen, setIsSearchOpen] = useState(isSearchPage)
  const goBack = useGoBack()

  useEffect(() => {
    notifyWebView('headerLoaded')
  }, [])

  const handleSearch = value => {
    !isSearchPage && history.push(`/search?q=${value}`, { from: `${pathname}${search}` })
    isSearchPage && navigator.replace({ ...query, q: value })
  }

  const closeSearch = useCallback(() => {
    inputRef.current?.close()
    isSearchPage ? goBack() : inputRef.current?.clear()
  }, [isSearchPage, goBack])

  const openSearch = useCallback(() => {
    inputRef.current?.open()
  }, [])

  useEffect(() => {
    isSearchPage ? openSearch() : closeSearch()
  }, [isSearchPage, closeSearch, openSearch])

  return (
    <NavbarWrapper role="banner" data-testid="nav-header" brandColor={brandColor}>
      <Flex width="100%" maxWidth={gridWidth} alignItems="center" m="auto" px={2} py={3}>
        <BackButton canGoBack={!isHubPage} />
        {isHubPage && <SettingsButton />}
        {!compact && !isViewer && !isSearchOpen && <CreateButton />}

        {!isSearchOpen && <NavbarBrandAndLogo />}

        {!compact &&
          <SearchBar
            key={isSearchPage && q}
            data-testid="global-search-bar"
            aria-label="Global search"
            placeholder={t('search.all')}
            ref={inputRef}
            onSubmit={handleSearch}
            onOpen={() => setIsSearchOpen(true)}
            onClose={() => setTimeout(() => setIsSearchOpen(false), isSearchPage ? 0 : 500 )}
            fixedOpen={isSearchPage}
            defaultValue={isSearchPage ? q : undefined}
            ml="auto"
          />
        }
        {!compact && !isSearchOpen && <NotificationsBell />}
        {!compact && isSearchOpen && (
          <Flex data-testid="search-close-button" onClick={closeSearch} backgroundColor="component.fill" borderRadius="100%" ml={2} cursor="pointer">
            <SvgIcon name="x" padding="medium" />
          </Flex>
        )}
        {compact &&
          <SvgIcon
            name="hamburger"
            size="medium"
            clickable
            onClick={resetScroll}
            data-testid="show-header"
            ml="auto"
          />
        }
      </Flex>
    </NavbarWrapper>
  )
}

AppHeader.propTypes = {
  compact: PropTypes.bool,
  resetScroll: PropTypes.func,
}

AppHeader.defaultProps = {
  compact: false,
  resetScroll: () => {},
}

export default AppHeader
