import { call, takeEvery } from 'redux-saga/effects'
import { documents, session } from '@concrete/one-redux'

const goToRoute = route => window.location.replace(route)

const actionTypes = {
  COLLAPSE_FIELDS: 'COLLAPSE_FIELDS',
  EXPAND_FIELDS: 'EXPAND_FIELDS',
}

const collapseFields = (documentId, userId) => ({
  type: actionTypes.COLLAPSE_FIELDS,
  documentId,
  userId,
})

const expandFields = (documentId, userId) => ({
  type: actionTypes.EXPAND_FIELDS,
  documentId,
  userId,
})

const reducer = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.COLLAPSE_FIELDS: {
      const userState = state[action.userId] || {}
      return {
        ...state,
        [action.userId]: {
          ...userState,
          [action.documentId]: 'collapsed',
        },
      }
    }

    case actionTypes.EXPAND_FIELDS: {
      const userState = state[action.userId] || {}
      return {
        ...state,
        [action.userId]: {
          ...userState,
          [action.documentId]: 'expanded',
        },
      }
    }

    default:
      return state
  }
}

const areFieldsCollapsed = (state, id, defaultValue = false) => {
  const currentUserId = session.selectCurrentUserId(state)
  const expandedState = state.documentsUi?.[currentUserId]?.[id] || localStorage.getItem(
    `document-${id}-state-${currentUserId}`
  )
  if (expandedState === 'collapsed') return true
  if (expandedState === 'expanded') return false
  return defaultValue
}

function* collapseFieldsSaga({ type, documentId, userId }) {
  localStorage.setItem(
    `document-${documentId}-state-${userId}`,
    type === actionTypes.COLLAPSE_FIELDS ? 'collapsed' : 'expanded',
  )
}

function* loadingFailedSaga({ error, resourceType }) {
  if (error?.response?.status === 404) {
    // document not found, redirect to the main resource page
    yield call(goToRoute, `/${resourceType}`)
    // TODO: maybe display an error "X not found" toast?
  }
}

function* saga() {
  yield takeEvery([actionTypes.COLLAPSE_FIELDS, actionTypes.EXPAND_FIELDS], collapseFieldsSaga)
  yield takeEvery(documents.actionTypes.DOCUMENT_REQUEST_FAILED, loadingFailedSaga)
}

const documentsUi = {
  actionTypes,
  collapseFields,
  expandFields,
  goToRoute,
  reducer,
  areFieldsCollapsed,
  saga,
  collapseFieldsSaga,
  loadingFailedSaga,
}

export default documentsUi