/*
  Please note internal context implementation from ReactRouter is likely to change
  these hooks will be replaced by react-router own hooks when ready
*/
import { useContext, useMemo } from 'react'
import { __RouterContext as RouterContext, useLocation, matchPath, useHistory } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { compliance, helpers, permissions } from '@concrete/one-redux'
import { RESOURCE_TYPES, RESOURCE_STATUSES } from '@concrete/resource'
import { getQueryParamsFromLocation } from 'helpers'
import { searchUi, toast } from 'state'
import { HOME_PAGE } from 'helpers/constants'

const { ANNOUNCEMENTS, FORMS, TASKS } = RESOURCE_TYPES
const { IN_PROGRESS } = RESOURCE_STATUSES
const { PERMISSIONS } = permissions

export const useRouter = () => useContext(RouterContext)

export const useParams = ()=> {
  const { match } = useRouter()
  return match.params
}

export const useQuery = (defaultParams, options) => {
  const { search } = useLocation()
  const query = useMemo(
    () => getQueryParamsFromLocation(search, defaultParams, options),
    [defaultParams, options, search]
  )
  return query
}

export const useHash = (defaultParams) => {
  const { hash } = useLocation()
  const query = useMemo(
    () => getQueryParamsFromLocation(hash, defaultParams),
    [defaultParams, hash]
  )
  return query
}

const getMatch = pathname => matchPath(pathname, {
  path: `/:resourceType/:resourceId?/:childType?/:childId?/:subType?/:subTypeId?`,
})?.params || {}

export const useGoBack = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()
  const { pathname, state } = useLocation() || {}
  const { resourceType, resourceId, childType, childId, subType, subTypeId } = getMatch(pathname)
  const search = useSelector(searchUi.selectLastSearch)

  const [canUpdate] = useSelector(state => permissions.userHasPermissions(
    state,
    [PERMISSIONS.DOCUMENT_UPDATE],
    helpers.generateUrn(resourceType, resourceId),
  ))

  const { status } = useSelector(state => compliance.selectCompletion(state, childId)) || {}
  const isStartedForm = resourceType === FORMS && status === IN_PROGRESS
  const hasSubRoute = subType && subTypeId
  const hasChildRoute = childType && childId

  return useMemo(() => {
    const submissionsReminder = () => isStartedForm && !hasSubRoute && dispatch(toast.show(t('forms:submissionsReminder')))
    const navigate = to => {
      submissionsReminder()
      history.replace(to)
      !hasChildRoute && dispatch(searchUi.clearFilters())
    }

    // if the return route has been explicitly set when navigating to current location
    // we go back to it
    if (state?.from) return () => navigate(state.from)

    if (resourceType === 'search') return () => navigate(HOME_PAGE)

    // if the page has a referrer, and the referrer url is another internal route
    // we simply go back
    if (document.referrer) {
      const { pathname } = new URL(document.referrer)
      const referrerParams = getMatch(pathname)
      if (
        referrerParams?.resourceType &&
        referrerParams?.resourceId &&
        referrerParams?.resourceType !== resourceType &&
        referrerParams?.resourceId !== resourceId
      ) {
        return () => history.goBack()
      }
    }

    let type
    switch (resourceType) {
      case ANNOUNCEMENTS:
        type = TASKS
        break
      case 'folders':
        type = 'files'
        break
      default:
        type = resourceType
    }

    const pathStack = []
    if (resourceType && resourceType !== 'community') pathStack.push(`/${type}`)
    if (hasChildRoute && (canUpdate || hasSubRoute)) pathStack.push(`/${resourceId}`)
    if (hasSubRoute) pathStack.push(`/${childType}/${childId}`)
    if (search && !hasSubRoute) pathStack.push(search)

    return () => navigate(pathStack?.length ? pathStack.join('') : HOME_PAGE)
  }, [canUpdate, childId, childType, dispatch, hasChildRoute, hasSubRoute, history, isStartedForm, resourceId, resourceType, search, state, t])
}
