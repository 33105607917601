import React, { useEffect, useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { resource } from '@concrete/one-redux'
import { Button } from '@concrete/web-components'
import { modals, tracker } from 'state'
import { useResourceState } from 'hooks'
import { keyBy } from 'lodash'
import { useTranslation } from 'react-i18next'

const FilterPickerButton = ({ resourceType, facet }) => {
  const { t } = useTranslation()
  const [selectedFilters, setSelectedFilters] = useState([])
  const dispatch = useDispatch()
  const resourceState = useResourceState(resourceType)
  const selectedFacet = useSelector(state => resource.selectFacet(state, resourceType, facet, resourceState.group))

  const getSelectedFilters = useCallback(() => {
    const filtersMap = keyBy(selectedFacet?.filterOptions, 'name')
    return Object.keys(resourceState).reduce((acc, key) => {
      if (filtersMap[key] && resourceState[key]) {
        return [...acc, ...resourceState[key].toString().split(',')]
      }
      return acc
    }, [])
  }, [resourceState, selectedFacet])

  useEffect(() => {
    setSelectedFilters(getSelectedFilters())
  }, [getSelectedFilters])

  const onClick = () => {
    dispatch(modals.open('ResourceFiltersSidebar'))
    dispatch(tracker.event.newDashboard.clickedSelectFilter(resourceType, facet))
  }

  let title
  const selectedFilterCount = selectedFilters.length
  switch (selectedFilterCount) {
    case 1:
      title = `1 ${t('filters.selectedFilter', {count: 1})}`
      break
    case 0:
      title = t('filters.selectedFilter', {count: 0})
      break
    default:
      title = `${selectedFilterCount} ${t('filters.selectedFilter', {count: selectedFilterCount})}`
      break
  }
  if (!selectedFacet?.filterOptions?.length) return null
  return (
    <Button
      onClick={onClick}
      title={title}
      type={selectedFilterCount ? 'roundActive' : 'round'}
      iconName="streamlineFilter"
      iconProps={{ size: 'medium', color: selectedFilterCount ? 'white' : 'black'}}
      data-testid="filter-changer"
      ml={2}
    />
  )
}

FilterPickerButton.propTypes = {
  resourceType: PropTypes.string,
  facet: PropTypes.string,
}

export default FilterPickerButton
