import styled from 'styled-components'

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin 20px;
`

export const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export const LogoutMessage = styled.div`
  text-align: center;
  padding: 2rem;
`
