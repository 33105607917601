import { isDateInTheFuture, getCurrentWeek } from './dates'
import { get as getProp } from 'lodash'
import { DASHBOARD_PAGE_SIZE, LAYOUTS } from 'helpers/constants'
import { resourceTypes } from '@concrete/one-redux'

const { CALENDAR } = LAYOUTS
const { STORIES, TASKS } = resourceTypes

export const isTaskCompleted = (task) => !!task && ['done', 'cancelled'].includes(task.status)

export const isScheduledToBeSent = sentAt => !!sentAt && isDateInTheFuture(sentAt)

export const isRecipient = (audienceItems, currentUserId, currentGroupId) => {
  return audienceItems && !!audienceItems.find(i => i.users.includes(currentUserId) || i.group.includes(currentGroupId))
}

export const expandTaskPropsForSaving = (values = {}) => {
  return {
    ...values,
    priority: values.isUrgent
      ? 'urgent'
      : values.priority,
    categories: values.categoryId
      ? [{ _id: values.categoryId }]
      : values.categories,
  }
}

export const isValidAudience = (audience) =>
  !!audience
  && (
    (!!audience.groups && !!audience.groups.length)
    || (!!audience.users && !!audience.users.length)
  )

export const taskHasAudience = (task) => !!task && isValidAudience(task.audience)

export const taskIsDraft = task => !!task && task.status === 'draft'
export const taskIsSketch = task => !!task && task.status === 'sketch'

export const showField = (task, fieldName, canEdit) => {
  if (Array.isArray(task[fieldName]) && !task[fieldName].length && !canEdit) return false
  return task[fieldName] || canEdit
}

export const getNumberOfAssignees = (audience) =>
  getProp(audience, 'groups.length', 0) || getProp(audience, 'users.length', 0)

export const extractFiltersFromQuery = (resourceType, query) => {
  const { ...extracted } = query
  const { layout } = query
  const removePrefix = str => str.replace(/^(\w+:)/, '')
  if (layout === CALENDAR && resourceType === TASKS && !query.dueDate) {
    extracted.dueDate = getCurrentWeek()
  }
  if (layout === CALENDAR && resourceType === STORIES && !query.startEnd) {
    extracted.startEnd = getCurrentWeek()
  }
  if (query.status) {
    const statuses = Array.isArray(query.status) ? query.status : query.status.split(',')
    extracted.status = statuses.map(removePrefix)
  }
  return extracted
}

export const DEFAULT_QUERY_PARAMS = {
  page: 1,
  pageSize: DASHBOARD_PAGE_SIZE,
  expand: 'rejectedBy',
}
