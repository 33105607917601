import React, { useCallback, useEffect, useState, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { tracker } from 'state'
import auth from 'auth'
import { VisuallyHiddenIframe } from './style'

const LOGOUT_STATES = {
  INITIAL: 'INITIAL',
  IN_PROGRESS: 'IN_PROGRESS',
  COMPLETE: 'COMPLETE',
}

const Logout = ({ federated, setLogoutState, remoteLogoutUrl }) => {
  const [logoutState, setInternalLogoutState] = useState(LOGOUT_STATES.INITIAL)
  const dispatch = useDispatch()

  const iframeRef = useRef()
  let logoutProgress = 1 // always required to logout at least out of one system

  if (remoteLogoutUrl) {
    logoutProgress++
  }

  const loadInIframe = url => new Promise(resolve => {
    iframeRef.current.onload = resolve
    iframeRef.current.src = url
  })

  const onLogoutStateChange = useCallback(newState => {
    setInternalLogoutState(newState)
    setLogoutState && setLogoutState(newState)
  }, [setLogoutState])

  const loggedOut = useCallback(() => {
    logoutProgress--
    if (logoutProgress === 0) {
      onLogoutStateChange(LOGOUT_STATES.COMPLETE)
    }
  }, [logoutProgress, onLogoutStateChange])

  // log out CP3 :)
  const logoutRemote = useCallback(() => loadInIframe(remoteLogoutUrl)
  .finally(() => loggedOut()), [loggedOut, remoteLogoutUrl])

  const logoutProvider = useCallback(isFederated => loadInIframe(auth.getSsoLogoutUrl(isFederated)), [])

  const startLogout = useCallback((allSystems = false) => {
    onLogoutStateChange(LOGOUT_STATES.IN_PROGRESS)
    // log out from ONE
    // log out from provider with 'federated' param (force logout of identity provider)
    // log out from remote if applicable
    // display logout success page
    logoutProvider(allSystems)
      .finally(() => remoteLogoutUrl && logoutRemote())
      .finally(() => loggedOut())
  }, [loggedOut, logoutProvider, logoutRemote, onLogoutStateChange, remoteLogoutUrl])

  useEffect(() => {
    dispatch(tracker.event.site.logout())
  }, [dispatch])

  useEffect(() => {
    if (logoutState === LOGOUT_STATES.INITIAL) {
      federated ? startLogout(true) : startLogout()
    }
  }, [federated, iframeRef, logoutState, startLogout])

  return <VisuallyHiddenIframe ref={iframeRef} title="remote_logout" sandbox="" />
}

export default Logout
