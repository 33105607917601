import { tasks } from '@concrete/one-redux'
import { get as getProp } from 'lodash'

const actionTypes = {
  LOAD_NOTE: 'LOAD_NOTE',
  NOTE_EDIT_REQUESTED: 'NOTE_EDIT_REQUESTED',
  NOTE_EDITED: 'NOTE_EDITED',
  NOTE_REMOVED: 'NOTE_REMOVED',
  ADD_NOTE_FAILED: 'ADD_NOTE_FAILED',
  EDIT_NOTE_FAILED: 'EDIT_NOTE_FAILED',
  REMOVE_NOTE_FAILED: 'REMOVE_NOTE_FAILED',
  NOTE_ADD_REQUESTED: 'NOTE_ADD_REQUESTED',
  NOTE_ADDED: 'NOTE_ADDED',
  ...tasks.actionTypes,
}

const reducer = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.TASK_REMINDER_REQUESTED: {
      return {
        ...state,
        reminders: {
          ...state.reminders,
          [action.taskId]: true,
        },
      }
    }

    case actionTypes.TASK_REMINDER_SENT:
    case actionTypes.TASK_REMINDER_FAILED: {
      return {
        ...state,
        reminders: {
          [action.taskId]: undefined,
        },
        sendTaskReminder: {
          ...state.sendTaskReminder,
          isRequested: false,
        }
      }
    }
    case actionTypes.LOAD_NOTE: {
      return {
        ...state,
        selectedNote: {
          taskId: action.taskId,
          note: action.note,
          isRequested: false,
        },
      }
    }
    case actionTypes.NOTE_ADDED:
    case actionTypes.NOTE_EDITED: {
      return {
        ...state,
        selectedNote: undefined,
      }
    }
    case actionTypes.NOTE_ADD_REQUESTED:
    case actionTypes.NOTE_EDIT_REQUESTED: {
      return {
        ...state,
        selectedNote: {
          taskId: action.taskId,
          note: action.note,
          isRequested: true,
        },
      }
    }
    default:
      return state
  }
}

const loadNote = (taskId, note = '') => ({
  type: actionTypes.LOAD_NOTE,
  taskId,
  note,
})

const isTaskBeingReminded = (state, taskId) =>
  getProp(state, `tasksUi.reminders.${taskId}`, false)

const selectNote = (state) => getProp(state, 'tasksUi.selectedNote')

const tasksUi = {
  reducer,
  loadNote,
  actionTypes,
  isTaskBeingReminded,
  selectNote,
}

export default tasksUi
