import React from 'react'
import PropTypes from 'prop-types'
import { Alert, Flex } from '@concrete/web-components'
import { Trans } from 'react-i18next'
import i18n from 'translate/i18n'

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error) {
    return { hasError: true }
  }

  componentDidCatch(error, errorInfo) {
    // TODO: send error to Rollbar
    console.log(error, errorInfo)
  }

  render() {
    const {
      text,
      size,
      variant,
      children,
      ...otherProps
    } = this.props
    if (this.state.hasError) {
      return (
        <Flex data-testid="error-alert" height="100%" justifyContent="center" alignItems="center">
          <Alert size={size} variant={variant} width="100%" {...otherProps}>
            <Trans>
              {text || i18n.t('errors.generic')}
            </Trans>
          </Alert>
        </Flex>
      )
    }

    return children
  }
}

ErrorBoundary.propTypes = {
  size: PropTypes.oneOf(['default', 'small', 'large']),
  variant: PropTypes.oneOf(['default', 'error', 'success']),
  text: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]),
  children: PropTypes.node.isRequired,
}

ErrorBoundary.defaultProps = {
  size: 'default',
  variant: 'default',
}

export default ErrorBoundary
