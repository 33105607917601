import 'core-js'
import 'react-app-polyfill/ie11' // For IE 11 support
import 'regenerator-runtime/runtime'
import './translate/i18n'
import elementClosest from 'element-closest'
import bootstrap from './bootstrap'
import injectGtm from 'analytics/ga'

elementClosest(window)

// eslint-disable-next-line no-restricted-globals
if (top.location !== self.location) {
  // eslint-disable-next-line no-restricted-globals
  top.location = self.location.href
}

injectGtm(process.env.REACT_APP_GA_ID)
bootstrap()
