import React from 'react'
import PropTypes from 'prop-types'
import { Loader } from '@concrete/web-components'
import styled from 'styled-components'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
`

export const AppLoader = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  & > div {
    flex: 1;
  }
`

const Loading = ({ error, pastDelay, timedOut }) => (
  <Wrapper>
    {pastDelay && <Loader />}
  </Wrapper>
)

Loading.propTypes = {
  error: PropTypes.instanceOf(Error),
  pastDelay: PropTypes.bool,
  timedOut: PropTypes.bool,
}

Loading.defaultProps = {
  error: undefined,
  pastDelay: false,
  timedOut: false,
}

export default Loading