import React from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { Button } from '@concrete/web-components'
import { modals } from 'state'
import { useTranslation } from 'react-i18next'
import { getViewModes } from 'helpers/resourceFilters'

const ViewPickerButton = ({ layout }) => {
  const { t } = useTranslation('tasks')
  const dispatch = useDispatch()

  const getProp = prop => {
    const viewModes = getViewModes() 
    const activeItem = viewModes.filter(i => i.id === layout)
    return activeItem[0][prop]
  }

  return (
    <Button
      onClick={() => dispatch(modals.open('ViewSidebar'))}
      title={t(`dashboard.views.${getProp('id')}`)}
      type="roundActive"
      iconName={getProp('icon')}
      iconProps={{ size: 'medium', color: 'white'}}
      data-testid="view-changer"
      ml={2}
    />
  )
}

ViewPickerButton.propTypes = {
  layout: PropTypes.string,
}

ViewPickerButton.defaultProps = {
  layout: 'grid',
}

export default ViewPickerButton
