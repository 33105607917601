import React from 'react'
import { useDispatch } from 'react-redux'
import { Button } from '@concrete/web-components'
import { modals, tracker } from 'state'

const CreateButton = () => {
  const dispatch = useDispatch()

  const clickedPlusBtn = () => {
    dispatch(modals.open('Create'))
    dispatch(tracker.event.header.clickedPlusBtn())
  }

  return (
    <Button
      data-testid="create-task-button"
      type="plain"
      onClick={clickedPlusBtn}
      iconName="streamlineAdd"
      iconPosition="center"
      iconProps={{ size: 'medium', padding: 'none' }}
      minWidth="24px"
      mr={4}
    />
  )
}

export default CreateButton

