import { call, put, select, take, takeEvery } from 'redux-saga/effects'
import { get as getProp } from 'lodash'
import { connect, session } from '@concrete/one-redux'
import { toast } from 'state'
import i18n from 'translate/i18n'

const notifyWebView = message => window.ReactNativeWebView?.postMessage(message)

const {
  REQUEST_CONNECTED_USERS,
  CONNECTED_USERS_LOADED,
  CONNECTED_USERS_REQUEST_FAILED,
  REQUEST_CONNECTED_GROUPS,
  CONNECTED_GROUPS_REQUEST_FAILED,
  CONNECTED_GROUPS_LOADED,
  REQUEST_CONNECT_CODE,
  CONNECT_CODE_REQUEST_FAILED,
  CONNECT_CODE_GENERATED,
  REQUEST_GROUP_CHECK_IN,
  GROUP_CHECK_IN_FAILED,
  GROUP_CHECKED_IN,
  USER_CONNECTED,
  CONNECT_CODE_EXPIRED,
} = connect.actionTypes

const reducer = (state = {}, action) => {
  switch (action.type) {
    case REQUEST_CONNECT_CODE: {
      return {
        ...state,
        isCodeBeingGenerated: true,
        error: undefined,
      }
    }

    case CONNECT_CODE_GENERATED: {
      return {
        ...state,
        isCodeBeingGenerated: false,
        error: undefined,
      }
    }

    case CONNECT_CODE_REQUEST_FAILED: {
      return {
        ...state,
        isCodeBeingGenerated: false,
        error: action.error,
      }
    }

    case REQUEST_GROUP_CHECK_IN: {
      return {
        ...state,
        isCheckInInProgress: true,
        hasCheckInFailed: false,
      }
    }

    case GROUP_CHECK_IN_FAILED: {
      return {
        ...state,
        isCheckInInProgress: false,
        hasCheckInFailed: true,
      }
    }

    case GROUP_CHECKED_IN: {
      return {
        ...state,
        isCheckInInProgress: false,
        hasCheckInFailed: false,
      }
    }

    case USER_CONNECTED: {
      const { userId, code } = action
      return {
        ...state,
        lastConnectedUser: { userId, code },
      }
    }

    case REQUEST_CONNECTED_USERS: {
      return {
        ...state,
        areConnectedUsersLoading: true,
      }
    }

    case CONNECTED_USERS_REQUEST_FAILED:
    case CONNECTED_USERS_LOADED: {
      return {
        ...state,
        areConnectedUsersLoading: false,
      }
    }

    case REQUEST_CONNECTED_GROUPS: {
      return {
        ...state,
        areConnectedGroupsLoading: true,
      }
    }

    case CONNECTED_GROUPS_REQUEST_FAILED:
    case CONNECTED_GROUPS_LOADED: {
      return {
        ...state,
        areConnectedGroupsLoading: false,
      }
    }
    default:
      return state
  }
}

const isCodeBeingGenerated = state =>
  getProp(state, 'connectUi.isCodeBeingGenerated', false)
const selectLastError = state =>
  getProp(state, 'connectUi.error')
const areConnectedUsersLoading = state =>
  getProp(state, 'connectUi.areConnectedUsersLoading', false)
const areConnectedGroupsLoading = state =>
  getProp(state, 'connectUi.areConnectedGroupsLoading', false)
const isCheckInInProgress = state =>
  getProp(state, 'connectUi.isCheckInInProgress', false)
const hasCheckInFailed = state =>
  getProp(state, 'connectUi.hasCheckInFailed', false)
const selectLastConnectedUserCode = state =>
  getProp(state, 'connectUi.lastConnectedUser.code')

function* userConnectedSaga({ groupId, userId }) {
  const currentUserId = yield select(session.selectCurrentUserId)
  if(userId !== currentUserId) {
    yield put(connect.requestConnectedUsers())
    const action = yield take([CONNECTED_USERS_LOADED, CONNECTED_USERS_REQUEST_FAILED])
    if (action.type === CONNECTED_USERS_LOADED) {
      const currentGroupId = yield select(session.selectCurrentGroupId)
      yield put(toast.show(i18n.t(currentGroupId === groupId ? 'connect:userConnectedCurrentGroup' : 'connect:userConnectedGroup')))
    }
  }
}

function* groupCheckInSaga({ code, group }) {
  // Notify the WebView so that the mobile app can refresh accordingly
  yield put(connect.requestConnectedGroups())
  yield call(notifyWebView, `team:checkedIn:${JSON.stringify({ code, group })}`)
}

function* groupConnectionExpiredSaga({ code, groupId, userId }) {
  // Notify the WebView so that the mobile app can refresh accordingly
  yield call(notifyWebView, `team:connectionExpired:${JSON.stringify({ code, groupId, userId })}`)
}

function* saga() {
  yield takeEvery(USER_CONNECTED, userConnectedSaga)
  yield takeEvery(GROUP_CHECKED_IN, groupCheckInSaga)
  yield takeEvery(CONNECT_CODE_EXPIRED, groupConnectionExpiredSaga)
}

const connectUi = {
  reducer,
  isCodeBeingGenerated,
  isCheckInInProgress,
  areConnectedUsersLoading,
  areConnectedGroupsLoading,
  hasCheckInFailed,
  selectLastConnectedUserCode,
  selectLastError,
  saga,
  userConnectedSaga,
  groupCheckInSaga,
  groupConnectionExpiredSaga,
  notifyWebView,
}

export default connectUi