import { get as getProp } from 'lodash'
import { takeEvery, select, put, call } from 'redux-saga/effects'
import { config, session, documents, distribution } from '@concrete/one-redux'
import { daysInFuture } from 'helpers'
import { tasksUi } from './'
import { FIELD_TYPES } from '@concrete/resource'

const { SHORT_ANSWER, MULTIPLE_CHOICE_MANY, MULTIPLE_CHOICE_SINGLE, LONG_ANSWER } = FIELD_TYPES

// actions -----
const ANALYTICS_EVENT = 'ANALYTICS_EVENT'
export const actionTypes = {
  ANALYTICS_EVENT,
}

// reducer -----

// action creators -----
export const analyticsEvent = (
  category = '',
  label = '',
  value = '',
  eventAction = 'click',
  ) => ({
  category,
  label,
  type: actionTypes.ANALYTICS_EVENT,
  value,
  eventAction,
})

const headerItemsToType = {
  copy: 'copyTask',
  remind: 'batchSendReminder',
  edit: 'editTask',
  assignTo: 'editAssignees',
  cancel: 'batchCancel',
  download: 'downloadTask',
  downloadCsv: 'downloadCsv',
  downloadXlsx: 'downloadXlsx',
  downloadAll: 'downloadAll',
}
export const event = {
  site: {
    login: () => analyticsEvent('site','loggedIn'),
    logout: () => analyticsEvent('site','loggedOut')
  },
  breakdown: {
    quickActionClicked: (type, hasNewUpdates, isAnnouncement) => analyticsEvent(
      'breakdown',
      `${isAnnouncement ? 'announcement' : 'task'}`,
      hasNewUpdates ? 1:0,
      `quickAction.${type}`
      ),
    filesCarouselDownloadSelected: () => analyticsEvent('breakdown', 'carousel', undefined, 'downloadZip'),
    filesPreview: () => analyticsEvent('breakdown', 'task', undefined, 'filesPreviewed'),
    approved: isAnnouncement => analyticsEvent('breakdown', `${isAnnouncement ? 'announcement' : 'task'}`, undefined, 'quick.approve'),
    rejected: isAnnouncement => analyticsEvent('breakdown', `${isAnnouncement ? 'announcement' : 'task'}`, undefined, 'quick.changesNeeded'),
  },
  contextMenu: {
    itemClicked: (item, viewType, isAnnouncement) => analyticsEvent(`${viewType}`, `${isAnnouncement ? 'announcement' : 'task'}`, undefined, `contextMenu.${headerItemsToType[item]}`),
    downloadCSV: () => analyticsEvent('download', 'form', undefined, 'csv'),
    downloadXLSX: () => analyticsEvent('download', 'form', undefined, 'xlsx')
  },
  navigatedTo: page => analyticsEvent('navigation', page),
  dashboard: (eventName, value) => analyticsEvent('taskDashboard', eventName, value),
  newDashboard: {
    clickedSidebarItem: (facet, resourceType) => analyticsEvent('dashboard', resourceType, undefined, `${facet}Selected`),
    clickedSidebarFacet: (type = 'dashboard', facet) => analyticsEvent(type, `facet.${facet}`),
    clickedSelectFilter: (type='dashboard', facet) => analyticsEvent(type, 'filter.opened', undefined, facet),
    clickedApplyFilter: () => analyticsEvent('dashboard', 'filter'),
    clickedResource: (type = 'dashboard', resourceType, facet) => analyticsEvent(
      type, `${resourceType}Card.opened`, undefined,
      facet
      ),
    trackFilterType: (isAnnouncement, facet) => analyticsEvent(
      'dashboard', `filter.${isAnnouncement ? 'announcement' : 'task'}`, undefined,
      facet
      ),
    clickedHierarchyFilter: (type = 'dashboard', facet) => analyticsEvent(type, 'hierarchyFilter.opened', undefined, facet),
    appliedHierarchyFilter: (type = 'dashboard', facet) => analyticsEvent(type, 'hierarchyFilter.applied', undefined, facet),
    cancelHierarchyFilter: (type = 'dashboard', facet) => analyticsEvent(type, 'hierarchyFilter.cancelled', undefined, facet),
    clickedTaskOption: (actionName, facet, isAnnouncement, layout) => analyticsEvent(`${layout === 'list' ? 'list' : 'card'}`,
    `${isAnnouncement ? 'announcement' : 'task'}.menu.${actionName}`, undefined, facet),
    submittedFilters: (type = 'dashboard', filterName, action) => analyticsEvent(type, `filter.${filterName}`, undefined, action),
    submittedCategories: (type = 'dashboard', categoryName, action) => analyticsEvent(type, `filter.${categoryName}`, undefined, action),
    clickedClearAll: (type = 'dashboard', facet) => analyticsEvent(type, 'filter.allCleared', undefined, facet),
    listViewColumnFilter: item => analyticsEvent('dashboard', `control.${item}`, undefined, 'selected')
  },
  modals: {
    cancelTask: (layout, isAnnouncement) => analyticsEvent(`${layout}`, `${isAnnouncement ? 'announcement' : 'task'}.menu.confirm`),
    abort: (layout, isAnnouncement) => analyticsEvent(`${layout}`, `${isAnnouncement ? 'announcement' : 'task'}.menu.abort`),
  },
  taskCreate: {
    approversOpened: () => analyticsEvent('create', 'task', undefined, 'approversButton'),
    approversSelected: total => analyticsEvent('create', 'task', total, 'addedApprovers'),
    assigneesSelected: total => analyticsEvent('create', 'task', total, 'addedAssignees'),
    formFieldTypeSubmitted: (questionType, count) => analyticsEvent('create', 'task', count, `question.${questionType}`),
    attachmentsSelected: (isAnnouncement, total) => analyticsEvent('create', `${isAnnouncement ? 'announcement' : 'task'}`, total, 'attachments'),
    created: isAnnouncement => analyticsEvent('create', `${isAnnouncement ? 'announcement' : 'task'}`, undefined, 'savedAndSent'),
    descriptionCount: (value, isAnnouncement) => analyticsEvent('create', `${isAnnouncement ? 'announcement' : 'task'}`, value, 'description'),
    categoriesSelected: isAnnouncement => analyticsEvent('create', `${isAnnouncement ? 'announcement' : 'task'}`, undefined, 'categories'),
    publishDateSelected: (daysInFuture, isAnnouncement) => analyticsEvent('create', `${isAnnouncement ? 'announcement' : 'task'}`, daysInFuture, 'publishDate'),
    prioritySelected: isAnnouncement => analyticsEvent('create', `${isAnnouncement ? 'announcement' : 'task'}`, undefined, 'priority'),
    sizeSelected: taskSize => analyticsEvent('create', 'task', undefined, `size.${taskSize}`),
    clickedSelect: isAnnouncement => analyticsEvent(`${isAnnouncement ? 'announcement' : 'task'}`, 'opened.assigneesGroupingType'),
    clickedSelectOption: (isAnnouncement, selectOption) => analyticsEvent(`${isAnnouncement ? 'announcement' : 'task'}`, `selected.${selectOption}.assigneesGroupingType`),
  },
  notifications: {
    modalClose: () => analyticsEvent('notificationsSummary', 'Close', undefined, 'click'),
    openFormSubmission: () => analyticsEvent('open', 'form', undefined, 'viaNewFormSubmissionNotification'),
    openFormPublished: () => analyticsEvent('open', 'form', undefined, 'viaNewFormNotification')
  },
  taskRecipient: {
    openAnnouncement: () => analyticsEvent('announcement', 'detail.opened'),
    openedAddNote: () => analyticsEvent('taskRecipient', 'task.addNote'),
  },
  taskDescription: {
    openNoteEditor: () => analyticsEvent('taskDescription', 'note.edit'),
    clickedNoteCancel: () => analyticsEvent('taskDescription', 'note.cancel'),
  },
  assigneeList: {
    createList: (isAnnouncement, selectionType) => analyticsEvent(`${isAnnouncement ? 'announcement' : 'task'}`, 'create.assigneeList', undefined, selectionType),
    closeList: (isAnnouncement, selectionType) => analyticsEvent(`${isAnnouncement ? 'announcement' : 'task'}`, 'close.assigneeList', undefined, selectionType),
    renameList: (isAnnouncement) => analyticsEvent(`${isAnnouncement ? 'announcement' : 'task'}`, 'renaming.assigneeList'),
    saveList: (isAnnouncement, selectionType, recipients) => analyticsEvent(`${isAnnouncement ? 'announcement' : 'task'}`, 'save.assigneeList', recipients, selectionType),
    clearList: (isAnnouncement, selectionType) => analyticsEvent(`${isAnnouncement ? 'announcement' : 'task'}`, 'clear.assigneeList', undefined, selectionType),
    deleteList: (isAnnouncement) => analyticsEvent(`${isAnnouncement ? 'announcement' : 'task'}`, 'deleting.assigneeList'),
    addList: isAnnouncement => analyticsEvent(`${isAnnouncement ? 'announcement' : 'task'}`, 'adding.assigneeList'),
  },
  comments: {
    addedComment: () => analyticsEvent('comments', 'comment.added'),
    addedReply: () => analyticsEvent('comments', 'comment.replied'),
    addedCommentWithMention: () => analyticsEvent('comments', 'comment.added.withMention'),
    addedReplyWithMention: () => analyticsEvent('comments', 'comment.replied.withMention'),
  },
  reactions: {
    addReaction: (resourceType, reactionType) => analyticsEvent(resourceType, 'reaction', reactionType, 'react'),
    removeReaction: (resourceType, reactionType) => analyticsEvent(resourceType, 'reaction', reactionType, 'unReact'),
  },
  approvals: {
    quickSelectedMyself: () => analyticsEvent('approvals', 'task', undefined, 'chooseSelf'),
    openedFilters: isAnnouncement => analyticsEvent(`${isAnnouncement ? 'announcement' : 'approvals'}`, `${isAnnouncement ? 'created.audiencePicker.filter' : 'task.approvals.people.filter'}`),
    cancelledFilters: isAnnouncement => analyticsEvent(`${isAnnouncement ? 'announcement' : 'approvals'}`, `${isAnnouncement ? 'created.audiencePicker.filter.cancelled' : 'task.approvals.people.apply.cancel'}`),
    appliedFilter: (type, isAnnouncement)=> analyticsEvent(`${isAnnouncement ? 'announcement' : 'approvals'}`, `${isAnnouncement ? `created.audiencePicker.filter.${type}` : `task.approvals.people.apply.${type}`}`),
    clickedSearch: isAnnouncement => analyticsEvent(`${isAnnouncement ? 'announcement' : 'approvals'}`, `${isAnnouncement ? 'created.audiencePicker.search' : 'task.approvals.people.search'}`),
    switchedTo: approvalType => analyticsEvent('approvals', `task.approvals.type.${approvalType}`),
    savedSelection: (approvalType, count) => analyticsEvent('approvals', `task.approvals.save.${approvalType}`, count),
    cancelledSelection: () => analyticsEvent('approvals', 'task.approvals.cancel'),
    clickedMarkupCircle: () => analyticsEvent('approvals', 'task.approvals.review.hotspot'),
    clickedFreshSpot: () => analyticsEvent('approvals', 'task.approvals.review.image'),
    // the user is reviewing a particular document as part of the approval process but now interacting with the comments
    clickedReply: () => analyticsEvent('approvals', 'task.approvals.review.message.reply'),
    clickedViewMessage: () => analyticsEvent('approvals', 'task.approvals.review.message.view'),
    clickedDeleteMessage: () => analyticsEvent('approvals', 'task.approvals.review.message.delete'),
    clickedSubmitMessage: resourceType => analyticsEvent('chat', resourceType, undefined, 'post'),
  },
  taskSummary: {
    addedNote: isAnnouncement => analyticsEvent(
      `${isAnnouncement ? 'announcement' : 'task'}Detail`,
      `${isAnnouncement ? 'announcement' : 'task'}.detail.note.added`
      ),
    finishedAttachmentUploadSuccess: (count, isAnnouncement) => analyticsEvent(
      `${isAnnouncement ? 'announcement' : 'task'}Detail`,
      `${isAnnouncement ? 'announcement' : 'task'}.detail.attachment.upload.success`, count
      ),
    finishedAttachmentUploadError: (count) => analyticsEvent('taskDetail', 'task.detail.attachment.upload.error', count),
  },
  taskExecution: {
    completed: () => analyticsEvent('execution', 'task', undefined, 'completed'),
    clickedSubmitForReview: () => analyticsEvent('execution', 'task', undefined, 'reviewSubmitted'),
  },
  header: {
    clickedBack: location => analyticsEvent(location, 'back', undefined, 'click'),
    clickedLogo: () => analyticsEvent('header', 'logo'),
    clickedPlusBtn: () => analyticsEvent('header', 'createMenu'),
    clickedNotificationIcon: isUnread => analyticsEvent('header', `${isUnread ? 'activeNotificationIcon' : 'inactiveNotificationIcon'}`),
  },
  create: {
    clickedNewItem: resourceType => analyticsEvent('create', resourceType, undefined, 'click'),
    clickedCreateFolder: () => analyticsEvent('create', 'folder', undefined, 'pick'),
    clickedUploadFile: () => analyticsEvent('create', 'file', undefined, 'pick'),
    clickedCreateItem: isAnnouncement => analyticsEvent('create', `${isAnnouncement ? 'announcement' : 'task'}`, undefined, 'create'),
    selectTemplates: () => analyticsEvent('create', 'template', undefined, 'pick'),
    clickedFormTemplate: () => analyticsEvent('create', 'form', undefined, 'fromTemplate'),
    clickedCopy: eventAction => analyticsEvent('create', 'form', undefined, eventAction)
  },
  navigationTasks: {
    clickedCardToggle: (tasksCount, facet) => analyticsEvent('viewMode', 'grid', tasksCount, facet),
  },
  profile: {
    // bug with uploadedAvatar - dispatched all file upload actions
    uploadedAvatar: () => analyticsEvent('profile', 'avatar', undefined, 'uploaded'),
    updatedProfileField: () => analyticsEvent('profile', 'name', undefined, 'saved'),
    savedProfile: () => analyticsEvent('profile', 'update', undefined, 'saved'),
    clickedCancel: () => analyticsEvent('profile', 'update', undefined, 'cancelled'),
  },
  templates: {
    createTemplate: isAnnouncement => analyticsEvent(`${isAnnouncement ? 'ann' : 'task'}Created`, 'saveTemplate'),
  },
  files: {
    fileAction: (fileId, action) => analyticsEvent(action.category || 'Files', action.id || action, fileId, action.actionType),
    onSuccessUpload: () => analyticsEvent('Files', 'upload'),
    openFilePreview: () => analyticsEvent('files', 'thumbnail.view'),
    openFolder: () => analyticsEvent('folder', 'opened'),
    fileNavigation: (direction) => analyticsEvent('files', `thumbnail.view.review.${direction}`),
  },
  sketch: {
    sketchSubmit: (isAnnouncement) => analyticsEvent('create', `${isAnnouncement ? 'announcement' : 'task'}`, undefined, 'draftSubmitted')
  },
  forms: {
    questionType: (type, count) => analyticsEvent('create', 'task', count, `question.${type}`)
  },
  settings: {
    settingsOpened: () => analyticsEvent('Header', 'Settings', undefined, 'click'),
    pickedOption: option => analyticsEvent('Settings', option, undefined, 'pick'),
    modalClose: () => analyticsEvent('Settings', 'Close', undefined, 'click'),
  },
  appNavigation: {
    clickedAppButton: app => analyticsEvent('navBar', app, undefined, 'open')
  },
  formPage: {
    startForm: () => analyticsEvent('start', 'form', undefined, 'click'),
    submitForm: () => analyticsEvent('publish', 'form', undefined, 'click'),
    viewSubmissions: () => analyticsEvent('open', 'form', undefined, 'viewSubmissions'),
    openFormSubmission: () => analyticsEvent('open', 'form', undefined, 'viaFormsBreakdown')
  },
  formsDashboard: {
    openFormFromFacet: eventAction => analyticsEvent('open', 'form', undefined, eventAction)
  },
  home: {
    selectSeeAll: facet => analyticsEvent('home', facet, undefined, 'seeAll'),
    openItemFromCarousel: facet => analyticsEvent('home', facet, undefined, 'openItem'),
  },
  language: {
    pick: language => analyticsEvent('language', language, undefined, 'pick')
  }
}

export const pushEvent = ({ action, currentUser, appVersion }) => {
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push({
    appEventAction: action.eventAction,
    appEventCategory: action.category,
    appEventLabel: action.label,
    appEventValue: action.value,
    av: appVersion,
    clientID: currentUser._id,
    event: 'appEvent',
    role: getProp(currentUser, 'relations[0].role.name', ''),
    tenantID: currentUser.tenantId,
    userID: currentUser._id,
    screenPath: `${window.location.pathname}${window.location.search}`.replace(/^\//, ''),
  })
}

// side effects -----
export function* registerEvent(action) {
  const currentUser = yield select(session.selectCurrentUser)
  const appVersion = yield select(config.selectAppVersion)
  yield call(pushEvent, { action, currentUser, appVersion })
}

// saga -----
export function* trackFileUpload(action) {
  if (action && action.queueName && action.queueName.startsWith('task/new/')) {
    yield put(event.taskCreate.attachmentsSelected(false, action.processed))
  }
  if (action && action.queueName && action.queueName.includes('/attachments')) {
    yield put(event.taskSummary.finishedAttachmentUploadSuccess(action.processed))
    yield put(event.taskSummary.finishedAttachmentUploadError(
      action.failed || 0
    ))
  }
  if (action && action.queueName && action.queueName === 'files/images') {
    yield put(event.files.onSuccessUpload())
  }
}

export function* trackOpenNoteEditor() {
  yield put(event.taskDescription.openNoteEditor())
}

export function* trackNewTaskSaving(task, recipients) {
  yield put(event.taskCreate.created())
  yield put(event.taskCreate.publishDateSelected(daysInFuture(task.sentAt, task.createdAt)))
    if (recipients && recipients > 0)
      yield put(event.taskCreate.assigneesSelected(recipients))
    if (task.attachments && task.attachments.length) {
      yield put(event.taskCreate.attachmentsSelected(false, task.attachments.length))
    }
    if (task.categories) {
      yield put(event.taskCreate.categoriesSelected())
    }
    if (task.priority) {
      yield put(event.taskCreate.prioritySelected())
    }
    if (task.size) {
      yield put(event.taskCreate.sizeSelected(task.size))
    }
    if (task.description && task.description.length > 1) {
      yield put(event.taskCreate.descriptionCount(task.description.length))
    }
}

export function* trackTaskSaving(action) {
  try {
    const { resourceType, resourceId } = action
    const { audience = {} } = yield select(distribution.selectResourceDistribution, resourceType, resourceId) || {}
    const { users = [], groups = [] } = audience
    const document = yield select(documents.selectDocument, resourceType, resourceId) || {}
    const recipients = [...users, ...groups].length
    yield* trackNewTaskSaving(document, recipients)
    if (document.fields) {
      const counts = document.fields.map(field => {
        switch(field.type) {
          case SHORT_ANSWER:
            return 'shortAnswer'
          case LONG_ANSWER:
            return 'paragraph'
          case MULTIPLE_CHOICE_MANY:
            return 'checkboxes'
          case MULTIPLE_CHOICE_SINGLE:
            return 'multipleChoice'
          default: return null
        }
      }).reduce((acc, curr) => {
        if (curr) {
          acc[curr] = (acc[curr] || 0) + 1
        }
        return acc
      }, {})

      const questionTypeCountPairs = Object.entries(counts)
      for (const [questionType, count] of questionTypeCountPairs) {
        yield put(event.taskCreate.formFieldTypeSubmitted(questionType, count))
      }
    }
  } catch (error) {
    console.error('trackTaskSaving error: ', error)
  }
}

export function* saga() {
  yield takeEvery(actionTypes.ANALYTICS_EVENT, registerEvent)
  yield takeEvery('FILE_QUEUE_DRAINED', trackFileUpload)
  yield takeEvery(tasksUi.actionTypes.LOAD_NOTE, trackOpenNoteEditor)
  yield takeEvery(distribution.actionTypes.RESOURCE_DISTRIBUTED, trackTaskSaving)
}

const tracker = { analyticsEvent, event, saga }

export default tracker
