import styled from 'styled-components'
import { media, Flex } from '@concrete/web-components'
import themeGet from '@styled-system/theme-get'

export const NavbarWrapper = styled.header`
  background-color: ${themeGet('palette.background.paper')};
  z-index: 1;
  ${({ brandColor }) => (brandColor ? `border-bottom: 4px solid ${brandColor}` : null)};
`

export const NavbarBrand = styled(Flex)`
  cursor: pointer;

  img {
    width: 315px;
    height: 40px;
    object-fit: contain;

    ${media.xs`
      width: 110px;
    `}
  }

`
