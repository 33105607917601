import { getQueryParamsFromLocation } from 'helpers'

const auth = {
  getSsoLogoutUrl(federated = false) {
    return process.env.REACT_APP_QUINYX_LOGOUT
  },
  authorize(redirectUrl) {
    // TODO: add redirect url?
    window.location.assign(process.env.REACT_APP_QUINYX_LOGIN)
  },
  getSsoToken(callback) {
    callback('placeholder-token', false)
  },
  handleSsoAuth(callback) {
    const { id_token } = getQueryParamsFromLocation(window.location.hash)
    callback(id_token)
  },
  logout() {
    // TODO: add return url?
    window.location.href = process.env.REACT_APP_QUINYX_LOGOUT
  },
}

function init() {
  return auth
}

const quinyx = {
  init
}

export default quinyx
