import React, { Suspense } from 'react'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import { ThemeProvider } from 'styled-components'
import firebase from 'firebase/app'
import 'firebase/performance'
import { merge } from 'lodash'
import { api, bootstrap } from '@concrete/one-redux'
import { theme as wcTheme } from '@concrete/web-components'
import AuthMessagePage from 'pages/AuthMessage'
import AuthError from 'pages/AuthError'
import auth from 'auth'
import store from 'store'
import { createBrowserHistory } from 'history'
import { getQueryParamsFromLocation, getQueryStringFromParams } from 'helpers'
import { tracker } from 'state'
import registerEventListeners from './events'
import { getAuthProvider, getReturnUrl } from 'auth/helpers'
import Loading from 'pages/Loading'
import { theme as muiTheme } from 'style/theme'
import App from 'App'

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
}

const sanitiseUrl = (url) => {
  if (!url) return '/'
  const [path, search] = url.split('?')
  // URLs with "group" param break deep linking, as sharing a URL
  // with a user that doesn't have access to that group prevents the user
  // from loading the page data even if they have permission to see it
  const params = getQueryParamsFromLocation(search)
  delete params.group
  const sanitisedSearch = getQueryStringFromParams(params)
  return `${path}${sanitisedSearch ? '?' + sanitisedSearch : ''}`
}

const bootstrapApplication = () => {
  const theme = merge(wcTheme, muiTheme)

  api.setBaseUrl(process.env.REACT_APP_API_URL)

  registerEventListeners()

  const urlParams = getQueryParamsFromLocation(window.location.search)
  const isCallbackUrl = () => /access_token|id_token/.test(window.location.hash)
  const isErrorCallbackUrl = () => /error/.test(window.location.hash)

  const root = createRoot(document.getElementById('root'))

  const renderApp = () => {
    root.render(
      <Suspense fallback={<Loading />}>
        <Provider store={store}>
          <ThemeProvider theme={theme}>
            <App />
          </ThemeProvider>
        </Provider>
      </Suspense>
    )
  }

  const renderAuthMessage = () => {
    root.render(
      <Suspense fallback={<Loading />}>
        <ThemeProvider theme={theme}>
          <AuthMessagePage />
        </ThemeProvider>
      </Suspense>
    )
  }

  const renderAuthError = () => {
    root.render(
      <Suspense fallback={<Loading />}>
        <Provider store={store}>
          <ThemeProvider theme={theme}>
            <AuthError />
          </ThemeProvider>
        </Provider>
      </Suspense>
    )
  }

  const initApp = (token, isSso, replaceUrl) => {

    // ONE-12001 Clear user location and state from local storage so users get the latest persistence changes
    // TODO: Should remove in a couple of sprints
    Object.keys(localStorage).forEach(item => {
      if (item.includes('current-location') || item.includes('state')) localStorage.removeItem(item)
    })
    const history = createBrowserHistory()
    store.dispatch(bootstrap.requestBootstrap(token, isSso, false, getAuthProvider()))
    if (isCallbackUrl() || replaceUrl) history.replace(sanitiseUrl(replaceUrl))
    firebase.initializeApp(firebaseConfig)
    firebase.performance()
    renderApp()
  }

  const authenticate = () => {
    if (isErrorCallbackUrl()) {
      renderAuthMessage()
    } else if (isCallbackUrl()) {
      localStorage.setItem('authProvider', urlParams.authprovider)
      store.dispatch(tracker.event.site.login())
      auth.handleSsoAuth(idToken => {
        const initPath = localStorage.getItem('initPath')
        initApp(idToken, true, initPath)
      })
    } else {
      const { pathname, search } = window.location
      if (pathname !== '/logout') {
        localStorage.setItem('initPath', `${pathname}${search ? search : ''}`)
      }
      auth.getSsoToken((idToken, isSsoToken) => {
        if (idToken) {
          initApp(idToken, isSsoToken, '')
        } else {
          auth.ssoLogin(getReturnUrl())
        }
      })
    }
  }

  api.registerUnauthorizedCallback(() => {
    // if the api returns an unauthorized error
    // the session token is likely expired
    // try to refresh the session before redirecting to login page
    auth.getSsoToken((idToken, isSsoToken) => {
      if (idToken && getAuthProvider() !== 'quinyx') {
        store.dispatch(bootstrap.requestBootstrap(idToken, isSsoToken, false, getAuthProvider()))
      } else {
        auth.ssoLogin(getReturnUrl())
      }
    })
  })

  window.addEventListener?.('message', e => {
    if (e.data?.startsWith?.('auth:')) {
      const idToken = e.data.replace('auth:', '')
      initApp(idToken, true)
    }
  })

  if (window.location.pathname.endsWith('authError')) {
    renderAuthError()
  } else if (window.location.pathname.endsWith('authenticate')) {
    if (urlParams.token) {
      const destinationPath = urlParams.redirect_to || '/'
      localStorage.setItem('authProvider', urlParams.authprovider)
      localStorage.setItem('initPath', destinationPath)
      initApp(urlParams.token, true, destinationPath)
    } else {
      renderAuthMessage()
    }
  } else {
    authenticate()
  }
}

export default bootstrapApplication
