import { useCallback } from 'react'
import { useIdleTimer } from 'react-idle-timer'
import { camelCase, get as getProp } from 'lodash'
import { RESOURCE_TYPES } from '@concrete/resource'
import analyticsEventsBlacklist from './analyticsEventsBlacklist.json'

const USER_EVENT_NAME = 'user_event'
const CAPTURED_EVENTS = [
  'input',
  'mousedown',
  'touchend',
  'wheel',
]
const EVENT_NAMES = {
  input: 'input',
  mousedown: 'click',
  touchend: 'click',
  wheel: 'scroll',
}

const RESOURCE_ID_REGEX = new RegExp(`^(card|completion|${Object.values(RESOURCE_TYPES).join('|')})-([a-f0-9]{24})$`, 'i')
const DATA_ATTRIBUTES_REGEX = /^data-((?!testid)[\w-]+)/

// Extracts element ids from the event nodes hierarchy
const getComponentIds = (nodes = []) => nodes.reduce((acc, node) => {
  const id = node?.getAttribute?.('id')
  const testId = node?.getAttribute?.('data-testid')
  const analyticsId = id || testId
  if (analyticsId && !analyticsEventsBlacklist[analyticsId]) acc.push(analyticsId)
  return acc
}, [])

// Extracts resourceType, resourceId and completionId when present in the event elements hierarchy
const getResourceAttributes = components => components?.reduce((acc, component, i) => {
  // TODO: extract groups as well?
  const matches = component.match(RESOURCE_ID_REGEX)
  if (matches?.length) {
    if (matches[1] === 'completion') acc.completionId = matches[2]
    else acc.resourceId = matches[2]
    acc.resourceType = !['card', 'completion'].includes(matches[1]) && matches[1]
  }
  if (
    acc.resourceId
    && !acc.resourceType
    && (RESOURCE_TYPES[component.toUpperCase()])
  ) acc.resourceType = component
  return acc
}, {
  resourceId: undefined,
  resourceType: undefined,
  completionId: undefined,
})

// Extracts data-* attributes from the nodes, excluding testids
const getDataAttributes = nodes => {
  return nodes?.reduce((acc, node) => {
    // eslint-disable-next-line no-unused-expressions
    if (node.attributes) {
      for (let attr of node.attributes) {
        const matches = attr.name.match(DATA_ATTRIBUTES_REGEX)
        if (matches?.length) {
          acc[camelCase(matches[1])] = attr.value
        }
      }
    }
    return acc
  }, {})
}

// Extracts any relevant attributes from the event target
const getTargetAttributes = target => {
  const value = target?.getAttribute?.('contenteditable') ? target?.innerHTML : target?.value
  return {
    value,
  }
}

const pushEvent = (event, user) => {
  const { _id: userID, tenantId: tenantID } = user
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push({
    _clear: true,
    av: process.env.REACT_APP_VERSION,
    clientID: userID,
    event: USER_EVENT_NAME,
    role: getProp(user, 'relations[0].role.name', ''),
    tenantID,
    userID,
    screenPath: `${window.location.pathname}${window.location.search}`.replace(/^\//, ''),
    ...event,
    // have to reset legacy event variables, otherwise GA will add them to this event (_clear doesn't seem to do the trick in all cases)
    appEventAction:  undefined,
    appEventCategory: undefined,
    appEventLabel: undefined,
    appEventValue: undefined,
    eventAction:  undefined,
    eventCategory: undefined,
    eventLabel: undefined,
    eventValue: undefined,
  })
}

export const useAnalytics = (user) => {
  const handleOnAction = useCallback(event => {
    const { path, target, type } = event
    const components = getComponentIds(path)
    const data = getDataAttributes(path)
    const resourceAttributes = getResourceAttributes(components)
    const targetAttributes = getTargetAttributes(target)
    const resourceType = resourceAttributes?.resourceType || data?.resourceType
    const analyticsEvent = {
      action: EVENT_NAMES[type],
      components,
      data,
      ...resourceAttributes,
      ...targetAttributes,
      resourceType,
    }
    pushEvent(analyticsEvent, user)
  }, [user])

  useIdleTimer({
    events: CAPTURED_EVENTS,
    onAction: handleOnAction,
    debounce: 500
  })
}
