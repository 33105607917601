import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/database'
import { notifications } from '@concrete/one-redux'
import { usersUi } from 'state'

export const NOTIFICATIONS_LIMIT = Number(process.env.REACT_APP_NOTIFICATIONS_LIMIT) || 50

/**
 * Registers a listener to user's tenant presence channel in order to update
 * the users online status. I also registers a (server) onDisconnect callback
 * that will flag this user as offline in case the clients disconnects.
 *
 * @param {Object} user
 * @param {Function} dispatch
 */
export const registerPresence = (user, dispatch) => {
  try {
    const isOfflineForDatabase = {
      state: 'offline',
      last_changed: firebase.database.ServerValue.TIMESTAMP,
    }

    const isOnlineForDatabase = {
      state: 'online',
      last_changed: firebase.database.ServerValue.TIMESTAMP,
    }

    const userPresenceRef = firebase.database().ref(`presence/${user.tenantId}/${user._id}`)
    const tenantPresenceRef = firebase.database().ref(`presence/${user.tenantId}`)

    firebase.database().ref('.info/connected').on('value', async snapshot => {
      // If we're not currently connected, don't do anything.
      if (snapshot.val() === false) {
          return
      }

      // If we are currently connected, then use the 'onDisconnect()'
      // method to add a set which will only trigger once this
      // client has disconnected by closing the app,
      // losing internet, or any other means.
      await userPresenceRef.onDisconnect().set(isOfflineForDatabase)
      // The promise returned from .onDisconnect().set() will
      // resolve as soon as the server acknowledges the onDisconnect()
      // request, NOT once we've actually disconnected:
      // https://firebase.google.com/docs/reference/js/firebase.database.OnDisconnect

      // We can now safely set ourselves as 'online' knowing that the
      // server will mark us as offline once we lose connection.
      userPresenceRef.set(isOnlineForDatabase)
    })

    tenantPresenceRef.on('child_changed', snapshot => {
      // { status: online/offline, last_changed: timestamp }, key = user id
      const presenceData = snapshot.val()
      // NOTE: the callback might fire multiple times, we might want to debounce it
      if(presenceData) {
        dispatch(usersUi.changeUsersOnlineStatus({ [snapshot.key]: presenceData }))
      }
    })
  } catch(error) {
    console.error("Couldn't initialize presence channel: ", error)
  }
}

/**
 * Sign-in Firebase and initializes connections for notifications
 * and realtime updates.
 *
 * @param {Object} user
 * @param {Function} dispatch
 */
export const initStreams = async (user, dispatch) => {
  await firebase.auth().signInWithCustomToken(user.firebaseToken)

  dispatch(notifications.requestNotifications())

  let init = false
  firebase.database().ref(`updates/${user.channel}`).on('value', snapshot => {
    const value = snapshot.val()
    if (init && value) {
      const { timestamp } = value
      dispatch(notifications.notificationsUpdate(timestamp))
    }
    init = true
  })

  registerPresence(user, dispatch)
}
