import i18n from 'i18next'
import Backend from 'i18next-http-backend'
import { initReactI18next } from 'react-i18next'

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    backend: {
      queryStringParams: { v: process.env.REACT_APP_LOCALES_HASH },
    },
    ns: ['announcements', 'connect', 'files', 'forms', 'general', 'notifications', 'tasks', 'chat', 'templates', 'stories', 'links'],
    defaultNS: 'general',
    lng: 'en',
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false
    },
  })

export default i18n
