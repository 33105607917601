import getProp from 'lodash/get'
import { helpers, templates } from '@concrete/one-redux'

const actionTypes = {
  ...templates.actionTypes,
}

const reducer = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.TEMPLATES_REQUESTED: {
      return {
        ...state,
        [helpers.createQueryKey(action.query)]: true,
      }
    }

    case actionTypes.TEMPLATES_REQUEST_FAILED:
    case actionTypes.TEMPLATES_LOADED: {
      return {
        ...state,
        [helpers.createQueryKey(action.query)]: false,
      }
    }

    case actionTypes.TEMPLATE_REQUESTED: {
      return {
        ...state,
        loading: {
          ...state.loading,
          [action.templateId]: true,
        }
      }
    }
    case actionTypes.TEMPLATE_LOADED:
    case actionTypes.TEMPLATE_REQUEST_FAILED: {
      return {
        ...state,
        loading: {
          [action.templateId]: false,
        }
      }
    }
    default:
      return state
  }
}

const selectTemplatesIsLoading = (state, query) => getProp(state, `templateUi.${helpers.createQueryKey(query)}`)

const isTemplateLoading = (state, id) => getProp(state, `templateUi.loading.${id}`, false)

const templateUi = {
  reducer,
  selectTemplatesIsLoading,
  isTemplateLoading,
}

export default templateUi
