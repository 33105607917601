import { useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { permissions, documents, distribution, compliance } from '@concrete/one-redux'
import { generateUrn, RESOURCE_TYPES, RESOURCE_STATUSES } from '@concrete/resource'
import { useTranslation } from 'react-i18next'
import { modals } from 'state'
import { useGoBack } from 'hooks'
import { useHistory, useParams } from 'react-router'

const { PERMISSIONS } = permissions
const { EVENTS, FORMS, LINKS, STORIES, TASKS, TEMPLATES } = RESOURCE_TYPES
const { SCHEDULED } = RESOURCE_STATUSES

const DOCUMENT_FIELDS = {
  [STORIES]: {
    coverImage: 1,
    priority: 2,
    description: 3,
    attachments: 4,
    categories: 5,
    customTags: 6,
    startEndDate: 7,
    pin: 8,
    chat: 9,
    recipients: 10,
    sendOut: 11,
  },
  [EVENTS]: {
    startEndDate: 1,
    recipients: 2,
    categories: 3,
    customTags: 4,
    priority: 5,
    coverImage: 6,
    description: 7,
    attachments: 8,
    chat: 9,
  },
  [LINKS]: {
    coverImage: 1,
    link: 2,
    categories: 3,
    customTags: 4,
    recipients: 5,
  }
}

const DOCUMENT_REQUIRED_FIELDS = {
  [EVENTS]: ['startDate', 'endDate'],
  [LINKS]: ['link'],
}

const QUICK_ADD_FIELDS = {
  [EVENTS]: {
    startEndDate: 1,
    recipients: 2,
    categories: 3,
    customTags: 4,
    priority: 5,
  },
  [STORIES]: {
    coverImage: 1,
    description: 2,
    categories: 3,
    customTags: 4,
  },
}

const QUICK_ADD_REQUIRED_FIELDS = {
  [EVENTS]: ['startDate', 'endDate'],
  [STORIES]: ['audience'],
}

const CREATE_FIELDS = {
  [STORIES]: {
    oversightView: 1,
  },
  [TASKS]: {
    oversightView: 1,
  },
}

const CREATE_REQUIRED_FIELDS = {
  // Add any required fields here
}

export const useDocumentFields = documentType => DOCUMENT_FIELDS[documentType] || {}
export const useDocumentRequiredFields = documentType => DOCUMENT_REQUIRED_FIELDS[documentType] || []
export const useCreateFields = (documentType, distribute) => (distribute ? QUICK_ADD_FIELDS[documentType] : CREATE_FIELDS[documentType]) || {}
export const useCreateRequiredFields = (documentType, distribute) => (distribute ? QUICK_ADD_REQUIRED_FIELDS[documentType] : CREATE_REQUIRED_FIELDS[documentType]) || []

export const useDocumentMenuItems = (documentType, id) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const goBack = useGoBack()
  const history = useHistory()
  const { edit: isEditPage, id: isDocumentPage } = useParams()
  const { distributed, status } = useSelector(state => distribution.selectResourceDistribution(state, documentType, id)) || {}
  const isDistributed = useSelector(state => distribution.isDistributed(state, documentType, id))
  const isScheduled = status === SCHEDULED
  const { type } = useSelector(state => documents.selectDocument(state, documentType, id)) || {}
  const isFormSubmission = type === FORMS && isDistributed
  const [
    documentUpdate,
    documentCopy,
    documentDelete,
    documentCancel,
    distributionDelete,
    canMoveToDone,
  ] = useSelector(state => permissions.userHasPermissions(
    state,
    [
      PERMISSIONS.DOCUMENT_UPDATE,
      PERMISSIONS.DOCUMENT_COPY,
      PERMISSIONS.DOCUMENT_DELETE,
      PERMISSIONS.COMPLIANCE_CANCEL,
      PERMISSIONS.DISTRIBUTION_DELETE,
      PERMISSIONS.COMPLIANCE_DONE,
    ],
    generateUrn(documentType, id),
  ))

  const [
    canCreateEvent,
    canDeleteEvent,
    canCreateForm,
    canDeleteForm,
    canCreateLink,
    canDeleteLink,
    canCreateStory,
    canDeleteStory,
    canCreateTask,
    canDeleteTask
  ] = useSelector(state => permissions.userHasPermissions(
    state,
    [
      PERMISSIONS.EVENT_CREATE,
      PERMISSIONS.EVENT_DELETE,
      PERMISSIONS.FORM_CREATE,
      PERMISSIONS.FORM_DELETE,
      PERMISSIONS.LINK_CREATE,
      PERMISSIONS.LINK_DELETE,
      PERMISSIONS.STORY_CREATE,
      PERMISSIONS.STORY_DELETE,
      PERMISSIONS.TASK_CREATE,
      PERMISSIONS.TASK_DELETE,
    ],
    permissions.SESSION_URN,
  ))
  // TODO: to be removed in upcoming work as part of ONE-11993
  const sessionPerms = {
    create: {
      events: canCreateEvent,
      forms: canCreateForm,
      links: canCreateLink,
      stories: canCreateStory,
      tasks: canCreateTask,
    },
    delete: {
      events: canDeleteEvent,
      forms: canDeleteForm,
      links: canDeleteLink,
      stories: canDeleteStory,
      tasks: canDeleteTask,
    }
  }
  const canCopy = documentCopy && !!sessionPerms.create[documentType]
  const canDeleteDistribution = distributionDelete && sessionPerms.delete[documentType]
  const canDeleteDocument = documentDelete && sessionPerms.delete[documentType]
  // TODO: Remove with mapPermissions work in back-end
  const canDelete = distributed?.total > 0 || isScheduled ? canDeleteDistribution : canDeleteDocument
  const menuItems = useMemo(() => {
    const deleteDocument = () => {
      dispatch(documents.deleteDocument(documentType, id))
      !!isDocumentPage && goBack()
    }

    const onDocumentCopied = ({ resourceType, id }) => history.push(`/${resourceType}/${id}`)
    const copyDocument = () => dispatch(documents.copyDocument(documentType, id, !isFormSubmission && t('copy.prefix'), onDocumentCopied))
    const cancelDocument = () => dispatch(compliance.cancelCompliance(documentType, id))
    const completeCompliance = () => dispatch(compliance.completeCompliance(documentType, id))

    return [
      {
        id: 'edit',
        label: t('edit'),
        action: () => history.push(`/${documentType}/${id}/edit`),
        hidden: !documentUpdate || ![LINKS, EVENTS].includes(documentType) || !!isEditPage
      },
      {
        id: 'view',
        label: t('view'),
        action: () => history.push(`/${documentType}/${id}`),
        hidden: !documentUpdate || ![EVENTS].includes(documentType) || !isEditPage
      },
      {
        id: 'copy',
        label: t(`copy.create`),
        action: copyDocument,
        hidden: !canCopy,
      },
      {
        id: 'delete',
        label: t('delete'),
        action: () => dispatch(modals.open('ModalConfirm', {
          onConfirm: deleteDocument,
        })),
        hidden: !canDelete,
      },
      {
        id: 'cancel',
        label:  t(`${documentType}:${isFormSubmission ? 'cancel.void' : 'cancel.label'}`),
        action: () => {
          if (documentType === TASKS) {
            dispatch(modals.open('CancelTask', { taskId: id, resourceType: TASKS } ))
          } else {
            dispatch(modals.open('ModalConfirm', {
              onConfirm: cancelDocument,
              header: t(`${documentType}:${isFormSubmission ? 'confirmVoid' : 'confirmArchive'}`),
              body: t(`irreversibleActionWarning`),
              confirmLabel:  t(`${documentType}:${isFormSubmission ? 'cancel.void' : 'cancel.label'}`),
            }))
          }
        },
        hidden: !documentCancel || !isDistributed,
      },
      {
        id: 'done',
        label: t('general:moveToDone'),
        action: completeCompliance,
        hidden: !canMoveToDone || documentType !== TASKS
      },
      {
        id: 'notify',
        label: t('general:notify'),
        action: () => dispatch(modals.open('NotifySidebar', {
          id,
          resourceType: documentType,
        })),
        hidden: !documentUpdate || [LINKS, FORMS, TEMPLATES].includes(documentType) || !isDistributed,
      }
    ]
  }, [t, documentUpdate, documentType, isEditPage, canCopy, canDelete, canMoveToDone, isFormSubmission, documentCancel, isDistributed, dispatch, id, goBack, history])
  return menuItems.filter(item => !item.hidden)
}
