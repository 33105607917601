import auth0 from 'auth0-js'

let authClient
const auth = {
  authorize() {
    authClient.authorize()
  },
  getSsoLogoutUrl(federated = false) {
    return authClient.client.buildLogoutUrl({
      clientID: process.env.REACT_APP_AUTH0_CLIENT_ID,
      returnTo: process.env.REACT_APP_AUTH0_BASE_PATH || process.env.REACT_APP_BASE_PATH,
      federated,
    })
  },
  getSsoToken(callback) {
    authClient.checkSession({}, (error, response) => {
      if (error) {
        console.error(error)
        callback()
      } else {
        callback(response.idToken, true)
      }
    })
  },
  createSsoHandler(callback) {
    return (err, authResult) => {
      if (authResult && authResult.idToken) {
        callback(authResult.idToken)
      } else if (err) {
        console.log(err)
      }
    }
  },
  handleSsoAuth(callback) {
    authClient.parseHash(this.createSsoHandler(callback))
  },
  logout() {
    window.location.href = '/logout'
  },
}

function init() {
  authClient = new auth0.WebAuth({
    domain: process.env.REACT_APP_AUTH0_DOMAIN,
    clientID: process.env.REACT_APP_AUTH0_CLIENT_ID,
    redirectUri: `${window.location.origin}/callback`,
    responseType: 'id_token',
    scope: 'openid email',
  })
  return auth
}

const auth0Module = {
  init
}

export default auth0Module
