import React from 'react'
import { Heading, Paragraph, FlexColumn } from '@concrete/web-components'

const AuthMessagePage = () => {
  return (
    <FlexColumn height="100%" width="100%" alignItems="center" justifyContent="center">
      <Heading>Error: User Authentication Failed.</Heading>
      <Paragraph>
        We’re sorry - there’s been a problem accessing this part of the Concrete
        platform.
      </Paragraph>
      <Paragraph>
        Please get in touch with your manager or Concrete contact for assistance.
      </Paragraph>
    </FlexColumn>
  )
}

export default AuthMessagePage
