import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { session, resourceTypes } from '@concrete/one-redux'
import { tracker } from 'state'
import { NavbarBrand } from './style'
import concreteLogo from 'images/concrete-logo.png'
import { useResourceState } from 'hooks'
import { getQueryStringFromParams } from 'helpers'
import { HOME_PAGE } from 'helpers/constants'

const NavbarBrandAndLogo = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { brandLogo } = useSelector(session.selectCurrentUser)
  const resourceState = useResourceState(resourceTypes.TASKS)
  const queryString = getQueryStringFromParams(resourceState)

  const navigateHome = useCallback(() => {
    dispatch(tracker.event.header.clickedLogo())
    history.push(`${HOME_PAGE}${queryString ? `?${queryString}` : ''}`)
  }, [dispatch, history, queryString])

  const logo = brandLogo ? `${process.env.REACT_APP_API_URL}files/${brandLogo}` : concreteLogo

  return (
    <NavbarBrand onClick={navigateHome} data-testid="navbar-brand" alignItems="center" ml="auto" mr={3}>
      <img src={logo} alt="Logo" width="315px" height="60px" />
    </NavbarBrand>
  )
}

export default NavbarBrandAndLogo
