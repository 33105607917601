import styled from 'styled-components'
import themeGet from '@styled-system/theme-get'

const getColor = (action) => {
  if (action && action !== 'close') return themeGet(`palette.component.${action}`)
  return themeGet('palette.component.border')
}

export const Wrapper = styled.div`
  border: ${themeGet('borders.toast')};
  border-color: ${props => getColor(props.action)};
  background-color: ${themeGet('palette.component.background')};
  bottom: ${props => props.isShown ? '20%' : '-60px'};
  color: ${themeGet('palette..primary')};
  cursor: pointer;
  left: 50%;
  transform: translateX(-50%);
  opacity: ${props => props.isShown ? 1 : 0};
  visibility: ${props => props.isShown ? 'visible' : 'hidden'};
  padding: 19px 15px 15px 15px;
  position: fixed;
  text-align: center;
  transition: all ${themeGet('transition.ease')} ${themeGet('transition.speed')};
  max-width: 500px;
  width: calc(100% - ${themeGet('space.6')}px);
`
export const CloseStyle = styled.div`
  float: right;

  button {
    background-color: transparent;
    border: 0;
    color: ${themeGet('palette.text.primary')};
  }
`

export const MessageStyle = styled.div`
  display: inline-block;
`
