import { files } from '@concrete/one-redux'
import { filesUi, modals } from 'state'
import i18n from '../translate/i18n'

/**
 * Saves an archive or single file.
 * If a single file is present,
 * @param {Array<string>} files Array of file ids
 * @param {string} archiveName Name of the archive
 */
export const saveFiles = (files, name, mimeType) => {
  let url = `assets/files/download?archiveName=${name}&ids=${files.join(',')}`
  let type = 'application/zip'
  if (files.length === 1) {
    // it's a single file
    url = `assets/files/${files[0]}/download`
    type = mimeType
  }
  const filename = (type === 'application/zip' && !name.includes('.zip')) ? `${name}.zip` : name
  if (window.embeddingApplication === 'ConcreteOne') {
    // if the webapp is embedded in the ConcreteOne app
    window.ReactNativeWebView?.postMessage?.(`download:${JSON.stringify({ url, name: filename, type })}`)
  } else {
    let iframe = document.querySelector('#downloader-iframe')
    if (!iframe) {
      iframe = document.createElement('iframe')
      iframe.id = 'downloader-iframe'
      iframe.style.display = 'none'
      document.body.appendChild(iframe)
    }
    iframe.src = `${process.env.REACT_APP_API_URL}${url}`
  }
}

/**
 * Displays dialog box to allow user to download an archive or single file.
 * @param {Array<string>} folders Array of folder ids
 * @param {Array<string>} assets Array of assets ids
 * @param {Function} dispatch function
 * @param {Function} function to fire after download
 */
export const downloadSelectedAssets = (dispatch, foldersSelected, selected, onDownload = () => {}) => {
  const downloadFiles = () => {
    const downloadName = `concrete_download_${new Date().toISOString()}`
    if (foldersSelected?.length) {
      // folders will be compressed offline
      dispatch(
        files.queueFilesDownload(
          selected,
          downloadName,
        ),
      )
    } else {
      saveFiles(selected, downloadName)
    }
    dispatch(filesUi.clearSelection())
    onDownload()
  }

  dispatch(modals.open('DialogBox', {
    onConfirm: downloadFiles,
    iconName: 'dialogDownload',
    iconColor: 'text.main',
    header: i18n.t('prepareDownload'),
    body: i18n.t('prepareDownloadBody'),
    confirmLabel: i18n.t('ok'),
  }))
}

/**
 * Displays dialog box to allow user to clear selected assets.
 * @param {Function} dispatch function
 * @param {Function} function to fire after clear
 */
export const clearSelectedAssets = (dispatch, onClear = () => {}) => {
  dispatch(modals.open('DialogBox', {
    onConfirm: () => {
      dispatch(filesUi.clearSelection())
      onClear()
    },
    iconName: 'dialogWarning',
    iconColor: 'warning.main',
    header: i18n.t('confirmClearAll'),
    body: i18n.t('clearAllBody'),
    confirmLabel: i18n.t('clearAll'),
    closeLabel: i18n.t('close'),
  }))
}
