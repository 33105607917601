import React, { useState } from 'react'
import { Helmet } from 'react-helmet'
import { Button, Paragraph } from '@concrete/web-components'
import LogoutComp from 'components/Logout'
import { useTranslation } from 'react-i18next'
import { Wrapper, LogoutMessage, ButtonContainer } from './style'

const LOGOUT_STATES = {
  INITIAL: 'INITIAL',
  IN_PROGRESS: 'IN_PROGRESS',
  COMPLETE: 'COMPLETE',
}

const AuthError = () => {
  const { t } = useTranslation()
  const [logoutState, setLogoutState] = useState(LOGOUT_STATES.INITIAL)

  return (
    <Wrapper>
      <Helmet>
        <title>{t('pages.error')}</title>
      </Helmet>
      <LogoutComp setLogoutState={setLogoutState} />
      <LogoutMessage>
        <div>
          <Paragraph mb={3}>{t('login.fail')}</Paragraph>
          <Paragraph mb={3}>{t('login.tryAgainOrContactSupport')}</Paragraph>
        </div>
        {
          logoutState === LOGOUT_STATES.COMPLETE &&
          <ButtonContainer>
            <Button type='primary' name='login' onClick={() => window.location.replace('/')}>{t('login.login')}</Button>
          </ButtonContainer>
        }
      </LogoutMessage>
    </Wrapper>
  )
}

export default AuthError
