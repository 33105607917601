import React, { lazy, useCallback, Suspense } from 'react'
import { Route, Switch, Redirect, useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { bootstrap, features, reports } from '@concrete/one-redux'
import { Loader } from '@concrete/web-components'
import Loading from 'pages/Loading'
import { useGoBack } from 'hooks'
import AppHeader from 'components/AppHeader'
import AppNavigation from 'components/AppNavigation'
import ResourceFilters from 'components/ResourceFilters'
import HomeFilters from 'components/ResourceFilters/HomeFilters'
import SearchFilters from 'components/ResourceFilters/SearchFilters'

export const Home = lazy(() => import('pages/Home'))
export const Create = lazy(() => import('pages/Create'))
export const TaskDashboard = lazy(() => import('pages/TaskDashboard'))
export const DocumentFilePreview = lazy(() => import('pages/DocumentFilePreview'))
export const TaskView = lazy(() => import('pages/TaskView'))
export const AssetEdit = lazy(() => import('pages/AssetEdit'))
export const FileView = lazy(() => import('pages/FileView'))
export const FilesDashboard = lazy(() => import('pages/FilesDashboard'))
export const Logout = lazy(() => import('pages/Logout'))
export const FormsDashboard = lazy(() => import('pages/FormsDashboard'))
export const FormView = lazy(() => import('pages/FormView'))
export const PublishedForm = lazy(() => import('pages/PublishedFormView'))
export const Reports = lazy(() => import('pages/Reports'))
export const StoriesDashboard = lazy(() => import('pages/StoriesDashboard'))
export const Story = lazy(() => import('pages/StoryView'))
export const LinkView = lazy(() => import('pages/LinkView'))
export const Event = lazy(() => import('pages/EventView'))
export const CommunityView = lazy(() => import('pages/CommunityView'))

export const DocumentActions = lazy(() => import('components/DocumentActions'))
export const FormActions = lazy(() => import('pages/FormView/FormActions'))
export const TaskActions = lazy(() => import('pages/TaskView/TaskActions'))
export const FolderActions = lazy(() => import('pages/AssetEdit/FolderActions'))

export const Search = lazy(() => import('pages/Search'))

export const RouteHeader = ({ compact, resetScroll }) => {
  const isBootstrapped = useSelector(bootstrap.isComplete)
  const renderHeader = useCallback(props => <AppHeader {...props} compact={compact} resetScroll={resetScroll} />, [compact, resetScroll])
  if (!isBootstrapped) return null
  return (
    <Switch>
      <Route path="/logout" component={null} />
      <Route path="*" render={renderHeader} />
    </Switch>
  )
}

export const RouteHeaderNavigation = () => {
  const isBootstrapped = useSelector(bootstrap.isComplete)
  if (!isBootstrapped) return null
  return (
    <Switch>
      <Route path="/" component={AppNavigation} exact />
      <Route path="/tasks" component={AppNavigation} exact />
      <Route path="/files" component={AppNavigation} exact />
      <Route path="/forms" component={AppNavigation} exact />
      <Route path="/reports" component={AppNavigation} exact />
      <Route path="/stories" component={AppNavigation} exact />
      <Route path="/community" component={AppNavigation} exact />
    </Switch>
  )
}
export const RouteFooter = () => {
  const history = useHistory()
  const goBack = useGoBack()
  const isBootstrapped = useSelector(bootstrap.isComplete)
  const redirectToFormsDashboard = useCallback(() => {
    return history.replace('/forms')
  }, [history])
  if (!isBootstrapped) return null
  // TODO: tasks will have to use the same pattern (and ideally all documents should just render DocumentActions)
  return (
    <Suspense fallback={<div />}>
      <Switch>
        <Route
          path="/:type(events|stories|links)/:id/:edit?"
          render={({ match }) => (
            <DocumentActions
              documentType={match.params.type}
              documentId={match.params.id}
              fieldsCollapsed={false}
              onBack={goBack}
            />
          )}
          exact
        />
        <Route
          path="/:type(forms|templates)/:id/:completions?/:completionId?"
          render={({ match }) => (
            <FormActions
              id={match.params.id}
              onBack={goBack}
              redirectToDashboard={redirectToFormsDashboard}
              resourceType={match.params.type}
              completionId={match.params.completionId}
            />
          )}
          exact
        />
        <Route
          path="/:type(announcements|tasks)/:id/:completions?/:completionId?"
          render={({ match }) => (
            <TaskActions
              taskId={match.params.id}
              resourceType={match.params.type}
              onBack={goBack}
              completionId={match.params.completionId}
            />
          )}
          exact
        />
        <Route
          path="/folders/:assetId/edit"
          render={({ match }) => (
            <FolderActions
              id={match.params.assetId}
            />
          )}
          exact
        />
      </Switch>
    </Suspense>
  )
}

export const RouteFilters = () => {
  const isBootstrapped = useSelector(bootstrap.isComplete)
  if (!isBootstrapped) return null
  return (
    <Switch>
      <Route path="/tasks" component={ResourceFilters} exact />
      <Route path="/files" component={ResourceFilters} exact />
      <Route path="/forms" component={ResourceFilters} exact />
      <Route path="/stories" component={ResourceFilters} exact />
      <Route path="/" component={HomeFilters} exact />
      <Route path="/search" component={SearchFilters} exact />
    </Switch>
  )
}

const Routes = () => {
  // Note: using the bootstrap timestamp as key, instead of a simple flag
  // in order to cause the current route to re-render if redux is bootstrapped again
  // (because the api returned a 401 due to an expired session for instance)
  const bootstrapTime = useSelector(bootstrap.selectBootstrapTime)
  const isReportingEnabled = useSelector(state => features.selectIsEnabled(state, 'reporting'))
  const isCommunityEnabled = useSelector(state => features.selectIsEnabled(state, 'community'))
  const { dashboard } = useSelector(reports.selectDashboard)

  if (!bootstrapTime) return <Loading />

  return (
    <Suspense fallback={<Loader flex="1 1 auto" />}>
      <Switch key={bootstrapTime}>
        <Route path="/logout" component={Logout} />
        <Route path="/create" component={Create} />
        <Route path="/:documentType(stories|tasks|forms)/:documentId/completions/:completionId/:previewType(uploads)/:id" component={DocumentFilePreview} />
        <Route path="/:documentType(stories|tasks|forms)/:documentId/completions/:completionId/fields/:fieldId/:previewType(uploads)/:id" component={DocumentFilePreview} />
        <Route path="/:documentType(stories|tasks|forms|events)/:documentId/:previewType(attachments)/:id" component={DocumentFilePreview} />
        <Route path="/:documentType(announcements|tasks)/:documentId/:completions?/:completionId?" component={TaskView} />
        <Route path="/:documentType(tasks)" component={TaskDashboard} exact />
        <Route path="/:assetType(folders|files)/:assetId/edit" component={AssetEdit} />
        <Route path="/files/:id" component={FileView} />
        <Route path="/files" component={FilesDashboard} />
        <Route path="/:documentType(forms)/:documentId/:completions?/:completionId?" component={FormView} />
        <Redirect from="/templates" to="/forms" exact />
        <Route path="/:documentType(forms)" component={FormsDashboard} />
        <Route path="/:documentType(templates)/:documentId" component={PublishedForm} />
        <Route path="/:documentType(stories)/:documentId" component={Story} />
        <Route path="/:documentType(stories)" component={StoriesDashboard} />
        <Route path="/:documentType(links)/:documentId" component={LinkView} />
        <Route path="/:documentType(events)/:documentId/:edit?" component={Event} />
        <Route path="/search" component={Search} />
        {isReportingEnabled && dashboard && <Route path="/reports" component={Reports} />}
        {isCommunityEnabled && <Route path="/community" component={CommunityView} />}
        <Route path="/" component={Home} exact />
        <Redirect from="*" to="/" exact />
      </Switch>
    </Suspense>
  )
}

export default Routes
