import queryString from 'query-string'
import { RESOURCE_TYPES, isValidResource } from '@concrete/resource'

const { ALL, ASSETS, STORIES } = RESOURCE_TYPES

// Check available parse options at: https://github.com/sindresorhus/query-string
export const getQueryParamsFromLocation = (search, defaults = {}, parseOptions = {}) => {
  const params = queryString.parse(search, {
    parseNumbers: false,
    parseBooleans: true,
    sort: false,
    ...parseOptions,
  })
  Object.keys(defaults).forEach(k => {
    if (!params[k]) params[k] = defaults[k]
  })
  return params
}

export const getQueryStringFromParams = params => {
  return Object.keys(params).reduce((urlSearchParams, key) => {
    let value = params[key]
    if (Array.isArray(value)) value = value.join(',')
    if (value) urlSearchParams.set(key, value)
    return urlSearchParams
  }, new URLSearchParams()).toString()
}

export const getResource = path => {
  const resourceString = path.split("/")[1]
  switch (resourceString) {
    case 'files':
      return ASSETS
    case 'community':
      return STORIES
    case 'search':
    case 'home':
      return ALL
    default:
      return isValidResource(resourceString) ? resourceString : undefined
  }
}
