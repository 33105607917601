import { createStore } from '@concrete/one-redux'
import {
  authenticationError,
  checkinsUi,
  commentsUi,
  complianceUi,
  forwardingUi,
  connectUi,
  documentsUi,
  filesUi,
  modals,
  notificationsUi,
  resourceLocation,
  searchUi,
  tasksUi,
  templateUi,
  toast,
  tracker,
  usersUi,
  uploadsUi,
} from 'state'

export default createStore(
  {
    checkinsUi: checkinsUi.reducer,
    commentsUi: commentsUi.reducer,
    complianceUi: complianceUi.reducer,
    documentsUi: documentsUi.reducer,
    forwardingUi: forwardingUi.reducer,
    connectUi: connectUi.reducer,
    filesUi: filesUi.reducer,
    modals: modals.reducer,
    notificationsUi: notificationsUi.reducer,
    searchUi: searchUi.reducer,
    tasksUi: tasksUi.reducer,
    templateUi: templateUi.reducer,
    toast: toast.reducer,
    usersUi: usersUi.reducer,
    uploadsUi: uploadsUi.reducer,
  },
  [
    authenticationError.saga,
    connectUi.saga,
    documentsUi.saga,
    filesUi.saga,
    resourceLocation.saga,
    toast.saga,
    tracker.saga,
    usersUi.saga,
    uploadsUi.saga,
  ],
)
