import React from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { groups } from '@concrete/one-redux'
import { Button } from '@concrete/web-components'
import { FACET_NAMES, RESOURCE_TYPES } from '@concrete/resource'
import { modals, resourceLocation, tracker } from 'state'
import { useResourceState } from 'hooks'

const { ALL, COMPLETED, OVERVIEW, FEED, PUBLISHED } = FACET_NAMES
const { ALL: ALL_RESOURCES, ASSETS, TASKS, STORIES, FORMS } = RESOURCE_TYPES

const LocationPickerButton = ({ facet, layout, navigator, resourceType, persistGroup, ...otherProps }) => {
  const dispatch = useDispatch()
  const resourceState = useResourceState(resourceType)
  const currentGroupId = useSelector(state => persistGroup ? resourceLocation.selectCurrentLocation(state, resourceType) : resourceState.group)
  const root = useSelector(groups.selectRootGroup) || {}
  const currentGroup = useSelector(state => groups.selectGroup(state, currentGroupId))
  const rootGroup = useSelector(state => groups.selectGroup(state, root._id))

  if (
    !currentGroup ||
    rootGroup?.isStore ||
    ![ASSETS, TASKS, ALL_RESOURCES, STORIES, FORMS].includes(resourceType) ||
    ![ALL, COMPLETED, OVERVIEW, FEED, PUBLISHED].includes(facet)
  ) return null

  const isDefaultGroup = currentGroup?._id === rootGroup?._id

  const onChange = group => {
    persistGroup && dispatch(resourceLocation.changeLocation(resourceType, group))
    navigator.replace({ ...resourceState, group, page: 1 })
  }
  const handleClick = () => {
    dispatch(modals.open('SelectPlace', { resourceType, onChange, persistedGroup: persistGroup }))
    dispatch(tracker.event.newDashboard.clickedHierarchyFilter(layout === 'grid' ? 'dashboard' : layout, facet))
  }

  return (
    <Button
      onClick={handleClick}
      title={currentGroup.name}
      type={isDefaultGroup ? 'round' : 'roundActive'}
      iconName="streamlineLocation"
      iconProps={{ size: 'medium', color: isDefaultGroup ? 'black' : 'white'}}
      data-testid="group-changer"
      {...otherProps}
    />
  )
}

LocationPickerButton.propTypes = {
  facet: PropTypes.string,
  layout: PropTypes.string,
  navigator: PropTypes.object,
  resourceType: PropTypes.string,
  persistGroup: PropTypes.bool,
}

LocationPickerButton.defaultProps = {
  persistGroup: true,
}

export default LocationPickerButton
