import React from 'react'
import { useDispatch } from 'react-redux'
import { Button } from '@concrete/web-components'
import { modals, tracker } from 'state'

const SettingsButton = () => {
  const dispatch = useDispatch()

  return (
    <Button
      data-testid="settings-button"
      type="plain"
      onClick={() => {
        dispatch(modals.open('Settings'))
        dispatch(tracker.event.settings.settingsOpened())
      }}
      iconName="streamlineSettings"
      iconPosition="center"
      iconProps={{ size: 'medium', padding: 'none' }}
      minWidth="24px"
      ml={2}
      mr={4}
    />
  )
}

export default SettingsButton
