import { getQueryParamsFromLocation } from 'helpers/location'

const isCnDomain = () => window.location.hostname.match(/^[a-z0-9-]+-cn\.retailcloud\.net$/)
const isValidAuthProvider = (authProvider) => ['auth0', 'firebase', 'quinyx'].includes(authProvider)
const ALLOWED_ORIGINS_REGEX = /^(https?:\/\/localhost:8000|https:\/\/(\w*\d*\.)?(dev)?concretelogin\.com)/
export const isAllowedOrigin = origin => !!origin && !!origin.match(ALLOWED_ORIGINS_REGEX)

/**
 * Returns the url the app should navigate to after a successful login
 */
export const getReturnUrl = () => {
  if (!window.location.pathname.match(/(callback|authenticate|logout)/g)) {
    return encodeURIComponent(window.location.pathname)
  }
}

/**
 * Returns the client subdomain, either from the query string or from the referrer.
 * It caches the subdomain in localStorage for future retrieval
 */
export const getSubdomain = () => {
  const domainParts = window.location.hostname.split('.')
  // we get the tenant from the host
  let brand
  if (domainParts.length > 2 && !domainParts[0].match(/^(one(-\w+)?)$/)) {
    brand = domainParts[0]
  }
  const { tenant = brand } = getQueryParamsFromLocation(window.location.search)
  const referrerUrl = document.referrer ? new URL(document.referrer) : null
  let subDomain = tenant || localStorage.getItem('tenant')
  if (!subDomain && referrerUrl?.hostname?.includes?.('retailcloud.net')) {
    const domainParts = referrerUrl.hostname.split('.')
    subDomain = domainParts.length > 2 ? domainParts[0] : null
  }
  if (subDomain) {
    localStorage.setItem('tenant', subDomain)
  }
  return subDomain
}

/**
 * Returns the Concrete Universal Login page url, taking into account
 * the client subdomain
 */
export const getUniversalLoginUrl = () => {
  const subDomain = getSubdomain()
  const ulUrl = process.env.REACT_APP_UNIVERSAL_LOGIN // TODO: we could get rid of this param since we hardcode thee UL url
  if (ulUrl.match(/(localhost|127.0.0.1)/g)) return ulUrl
  return `https://${subDomain && !subDomain.match(/^one-?\w*$/) ? subDomain + '.' : ''}${ulUrl.includes('dev') ? 'dev' : ''}concretelogin.com`
}

export const getAuthProvider = () => {
  if (isCnDomain()) return 'auth0'

  const urlParams = getQueryParamsFromLocation(window.location.search)
  const authProvider = urlParams.authprovider || localStorage.getItem('authProvider')

  return isValidAuthProvider(authProvider) ?
    authProvider :
    process.env.REACT_APP_AUTH_CLIENT
}

export const getLoginErrorUrl = () => {
  if (getAuthProvider() === 'auth0') {
    return `${window.location.protocol}//${window.location.host}/authError`
  }

  return `${getUniversalLoginUrl()}/errorPage`
}
