/* eslint-disable no-unused-expressions */
import { connect, forms } from '@concrete/one-redux'
import store from 'store'
import { toast } from 'state'

export const notifyWebView = message => window.ReactNativeWebView?.postMessage?.(message)

const registerEventListeners = () => {
  window.addEventListener?.('message', e => {
    if (e.data?.startsWith?.('toast_info:')) {
      store.dispatch(toast.show(e.data.replace('toast_info:', '')))
    }
    if (e.data?.startsWith?.('toast_error:')) {
      store.dispatch(toast.show(e.data.replace('toast_error:', ''), 'error'))
    }
    if (e.data?.startsWith?.('team:code:')) {
      const code = e.data.replace('team:code:', '')
      store.dispatch(connect.checkIn(code))
    }
    if (e.data?.startsWith?.('codeScan:')) {
      const { resourceType, resourceId, completionId, fieldId, itemId, code } = JSON.parse(e.data.replace('codeScan:', ''))
      store.dispatch(forms.updateAnswer(resourceType, resourceId, completionId, fieldId, itemId, code))
    }
  })
}

export default registerEventListeners