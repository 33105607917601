import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { SvgIcon, Flex } from '@concrete/web-components'
import { notifications, checkins, session } from '@concrete/one-redux'
import styled from 'styled-components'
import themeGet from '@styled-system/theme-get'
import { modals, tracker } from 'state'

const NotificationsBellWrapper = styled(Flex)`
  position: relative;
`

const NotificationsBadge = styled.div`
  background-color: ${themeGet('palette.notification')};
  color: ${themeGet('palette.white')};
  border-radius: 50px;
  font-size: 10px;
  padding: ${themeGet('space.1')}px;
  min-width: 20px;
  height: 20px;
  line-height: 12px;
  text-align: center;
  position: absolute;
  right: -3px;
  top: 4px;
  pointer-events: none;
`

const NotificationsBell = () => {
  const dispatch = useDispatch()
  const currentUserId = useSelector(session.selectCurrentUserId)
  const notificationsCount = useSelector(notifications.selectNotificationsCount)
  const [showBadge, setShowBadge] = useState(notificationsCount > 0)

  useEffect(() => {
    setShowBadge(notificationsCount > 0)
    const data = { name: 'notificationsCountChange', count: notificationsCount }

    return window.ReactNativeWebView?.postMessage(JSON.stringify(data))
  }, [notificationsCount])

  const handleClick = () => {
    dispatch(modals.open('Notifications'))
    dispatch(checkins.checkInForResource(currentUserId, 'notifications', 'viewed'))
    dispatch(tracker.event.header.clickedNotificationIcon(showBadge))
  }

  return (
    <NotificationsBellWrapper ml={2}>
      <SvgIcon
        name="streamlineBell"
        size="medium"
        onClick={handleClick}
        clickable
        data-testid="notifications-bell-icon"
        padding="none"
        mx={2}
      />
      {showBadge &&
        <NotificationsBadge data-testid="notifications-count-badge">
          {notificationsCount > 10 ? '10+' : notificationsCount}
        </NotificationsBadge>
      }
    </NotificationsBellWrapper>
  )
}

export default NotificationsBell
