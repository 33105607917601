import React from 'react'
import { useSelector } from 'react-redux'
import { resource } from '@concrete/one-redux'
import { RESOURCE_TYPES } from '@concrete/resource'
import { Flex, InlineBox } from '@concrete/web-components'
import LocationPickerButton from './LocationPickerButton'
import FilterPickerButton from './FilterPickerButton'
import SortPickerButton from './SortPickerButton'
import { useResourceState, useResourceNavigator, useGridSize } from 'hooks'
import { Trans } from 'react-i18next'

const { ALL } = RESOURCE_TYPES

const ResourceFilters = () => {
  const gridWidth = useGridSize()
  const resourceState = useResourceState(ALL)
  const navigator = useResourceNavigator(ALL)
  const { facet, sort, layout } = resourceState
  const count = useSelector(state => resource.selectResourceCount(state, resourceState))

  return (
    <Flex
      data-testid="search-filters"
      mx="auto"
      my={4}
      px={2}
      width="100%"
      maxWidth={gridWidth}
      alignItems="center"
      flexShrink="0"
    >
      {!!count &&
        <Flex mr={2} data-testid="search-count">
          <Trans
            i18nKey="search.found"
            values={{ count }}
            components={{ bold: <InlineBox fontWeight={600} mr={1} /> }}
          />
        </Flex>
      }
      <Flex ml="auto">
        <LocationPickerButton navigator={navigator} resourceType={ALL} facet={facet} layout={layout} persistGroup={false} />
        <FilterPickerButton navigator={navigator} resourceType={ALL} facet={facet} layout={layout} />
        <SortPickerButton navigator={navigator} resourceType={ALL} facet={facet} sort={sort?.toString()} />
      </Flex>
    </Flex>
  )
}

export default ResourceFilters
