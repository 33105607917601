import { RESOURCE_TYPES, FACET_NAMES } from '@concrete/resource'

const { ALL, OVERVIEW, PUBLISHED, FEED } = FACET_NAMES
const { TASKS, FORMS, STORIES } = RESOURCE_TYPES

export const getDefaultFacet = resource => {
  switch (resource) {
    case TASKS:
      return OVERVIEW
    case FORMS:
      return PUBLISHED
    case STORIES:
      return FEED
    default:
      return ALL
  }
}
