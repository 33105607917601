import React, { useCallback, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useRouteMatch } from 'react-router'
import { features, reports, session, groups } from '@concrete/one-redux'
import { AppNavBar } from '@concrete/web-components'
import { AppNavWrapper, AppNavSlider } from './style'
import { tracker } from 'state'
import { useGridSize } from 'hooks'
import { useTranslation } from 'react-i18next'

const AppNavigation = ({ history }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const gridWidth = useGridSize()
  const { path } = useRouteMatch()
  const { homeLink } = useSelector(state => session.selectCurrentUser(state))
  const currentGroupId = useSelector(state =>  session.selectCurrentGroupId(state))
  const currentGroup = useSelector(state => groups.selectGroup(state, currentGroupId))
  const filesEnabled = useSelector(state => features.selectIsEnabled(state, 'files_overview'))
  const formsEnabled = useSelector(state => features.selectIsEnabled(state, 'forms'))
  const publishEnabled = useSelector(state => features.selectIsEnabled(state, 'publish'))
  const reportingEnabled = useSelector(state => features.selectIsEnabled(state, 'reporting'))
  const storiesEnabled = useSelector(state => features.selectIsEnabled(state, 'stories'))
  const communityEnabled = useSelector(state => features.selectIsEnabled(state, 'community'))
  const { dashboard } = useSelector(reports.selectDashboard)

  const handleClick = useCallback((url, app, isExternal) => {
    dispatch(tracker.event.appNavigation.clickedAppButton(app))
    isExternal ? window.open(url, '_self') : history.push(url)
  }, [dispatch, history])

  const items = useMemo(() => ([
    {
      id: 'home',
      label: t('header.home'),
      onClick: () => handleClick('/', 'home'),
      active: path === '/',
      disabled: path === '/',
    },
    {
      id: 'stories',
      label: t('resource.stories'),
      onClick: () => handleClick('/stories', 'stories'),
      active: path === '/stories',
      hidden: !storiesEnabled,
      disabled: path === '/stories',
    },
    {
      id: 'community',
      label: t('resource.community'),
      onClick: () => handleClick('/community', 'community'),
      active: path === '/community',
      hidden: !communityEnabled,
      disabled: path === '/community',
    },
    {
      id: 'tasks',
      label: t('resource.tasks'),
      onClick: () => handleClick('/tasks', 'todo'),
      active: path === '/tasks',
      disabled: path === '/tasks',
    },
    {
      id: 'files',
      label: t('resource.files'),
      onClick: () => handleClick('/files', 'files'),
      active: path === '/files',
      hidden: !filesEnabled,
      disabled: path === '/files',
    },
    {
      id: 'forms',
      label: t('resource.forms'),
      onClick: () => handleClick('/forms', 'forms'),
      active: path === '/forms',
      hidden: !formsEnabled,
      disabled: path === '/forms',
    },
    {
      id: 'reports',
      label: t('header.reports'),
      onClick: () => handleClick('/reports', 'reports'),
      active: path === '/reports',
      hidden: !reportingEnabled || !dashboard,
      disabled: path === '/reports'
    },
    {
      id: 'publish',
      label: t('header.publish'),
      onClick: () => handleClick(homeLink, 'publish', true),
      hidden: !homeLink || !publishEnabled,
    },
  ]), [communityEnabled, dashboard, filesEnabled, formsEnabled, handleClick, homeLink, path, publishEnabled, reportingEnabled, storiesEnabled, t])

  useEffect(() => {
    currentGroup?.reportsBoardId && dispatch(reports.requestDashboard())
  }, [currentGroup, dispatch])

  return (
    <AppNavWrapper data-testid="app-nav-wrapper" px={2} maxWidth={gridWidth}>
      <AppNavSlider>
        <AppNavBar data-testid="app-nav-bar" items={items} />
      </AppNavSlider>
    </AppNavWrapper>
  )
}

export default AppNavigation
