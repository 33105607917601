import { useCallback, useEffect, useMemo, useRef } from 'react'
import { useHistory, useRouteMatch } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import { resource, users, distribution, compliance, session } from '@concrete/one-redux'
import { decodeUrn, RESOURCE_TYPES } from '@concrete/resource'
import { resourceLocation } from 'state'
import { useQuery } from './router'
import { getDefaultFacet, getQueryStringFromParams, extractFiltersFromQuery } from '../helpers'
import { DASHBOARD_PAGE_SIZE, CALENDAR_PAGE_SIZE, LAYOUTS } from 'helpers/constants'

const { CALENDAR } = LAYOUTS
const { ALL, STORIES } = RESOURCE_TYPES

export const useResourceState = (resource, defaults) => {
  const count = useRef(0)
  const query = useQuery()
  const dispatch = useDispatch()
  const currentLocationGroupId = useSelector(state => resourceLocation.selectCurrentLocation(state, resource))
  const { facet = defaults?.facet || getDefaultFacet(resource), page = 1, layout, group } = query
  const currentGroupId = useSelector(session.selectCurrentGroupId)
  const pageSize = layout === CALENDAR ? CALENDAR_PAGE_SIZE : DASHBOARD_PAGE_SIZE

  useEffect(() => {
    if (resource !== ALL && !!group && group !== currentLocationGroupId) {
      dispatch(resourceLocation.changeLocation(resource, group))
    }
  }, [currentLocationGroupId, dispatch, group, resource])

  return useMemo(
    () => {
      // bit of a hack to reset pagination when reloading the page
      const currentPage = count.current === 0 ? 1 : page
      count.current++
      return extractFiltersFromQuery(
        resource,
        {
          ...query,
          facet,
          group: group || currentGroupId,
          layout,
          page: currentPage,
          pageSize,
          resourceType: resource,
        }
      )
    },
    [facet, group, layout, page, pageSize, query, resource, currentGroupId],
  )
}

export const useResourceNavigator = (resource, location, locationState = {}) => {
  const history = useHistory()
  const match = useRouteMatch()
  const navigate = useCallback((method, query, reset) => {
    history[method](`${location || match.url}?${getQueryStringFromParams(query)}`, locationState)
  }, [history, location, match.url, locationState])
  return useMemo(() => ({
    push: (query, reset) => navigate('push', query, reset),
    replace: (query, reset) => navigate('replace', query, reset),
  }), [navigate])
}

export const useResourceSelector = (resourceType, resourceId) =>
  useSelector(state => resource.selectResource(state, resourceType, resourceId))

export const useResourceSearchGroups = urn => {
  const { id, resourceType } = decodeUrn(urn)
  const completion = useSelector(state => compliance.selectCompletion(state, id))
  const resource = useResourceSelector(resourceType, id)
  const creator = useSelector(state => users.selectUser(state, resource?.createdBy)) || {}
  const dist = useSelector(state => distribution.selectResourceDistribution(state, resourceType, id))

  if (resourceType === STORIES) {
    return dist?.audience?.groups.length ? dist.audience.groups : [creator?.group]
  }

  return [completion?.group]
}
