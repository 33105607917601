import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router'
import { useDispatch } from 'react-redux'
import { Button } from '@concrete/web-components'
import { tracker } from 'state'
import { useGoBack } from 'hooks'

const BackButton = ({ canGoBack, toast, backLocation }) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const goBack = useGoBack()

  const handleClick = useCallback(() => {
    dispatch(tracker.event.header.clickedBack(backLocation || history.location.pathname))
    toast && toast()
    if (backLocation) return history.replace(backLocation)
    return goBack()
  }, [dispatch, backLocation, history, toast, goBack])

  if (!canGoBack) return null
  return (
    <Button
      data-testid="back-button"
      type="plain"
      onClick={handleClick}
      iconName="streamlineBack"
      iconPosition="center"
      iconProps={{ size: 'medium', padding: 'none' }}
      minWidth="24px"
      ml={2}
      mr={4}
    />
  )
}

BackButton.propTypes = {
  canGoBack: PropTypes.bool,
  toast: PropTypes.func,
  backLocation: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
  ]),
}

BackButton.defaultProps = {
  canGoBack: false,
}

export default BackButton
