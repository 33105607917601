import { resourceTypes } from '@concrete/one-redux'
import { theme } from '@concrete/web-components'

const { colors } = theme
const { TASKS, ANNOUNCEMENTS, FORMS } = resourceTypes

const STATUS_COLOURS = {
  [TASKS]: {
    blocked: colors.warning,
    awaitingApproval: colors.label,
    review: colors.label,
    todo: colors.label,
    draft: colors.label,
    sketch: colors.label,
    notScheduled: colors.label,
    inprogress: colors.highlight,
    rejected: colors.warning,
    paused: colors.label,
    cancelled: colors.warning,
    done: colors.success,
  },
  [ANNOUNCEMENTS]: {
  },
  [FORMS]: {
    cancelled: colors.label,
    review: colors.orange,
  },
}

const BACKGROUND_COLOURS = {
  [TASKS]: {
  },
  [ANNOUNCEMENTS]: {
  },
  [FORMS]: {
    cancelled: colors.component.fill,
  },
}

const ICON_COLOURS = {
  [TASKS]: {
  },
  [ANNOUNCEMENTS]: {
  },
  [FORMS]: {
    cancelled: colors.label,
  },
}

export const getStatusColor = (status, resourceType = TASKS) => STATUS_COLOURS?.[resourceType]?.[status]

export const getBackgroundColor = (status, resourceType = TASKS) => BACKGROUND_COLOURS?.[resourceType]?.[status]

export const getIconColor = (status, resourceType = TASKS) => ICON_COLOURS?.[resourceType]?.[status]
