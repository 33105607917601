import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { resource } from '@concrete/one-redux'
import { Button } from '@concrete/web-components'
import { modals } from 'state'
import { useTranslation } from 'react-i18next'
import { useResourceState } from 'hooks'

const SortPickerButton = ({ facet, resourceType, sort }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { group } = useResourceState(resourceType)
  const selectedFacet = useSelector(state => resource.selectFacet(state, resourceType, facet, group))
  const defaultSort = sort || selectedFacet?.defaultSortOption
  const reverse = defaultSort?.startsWith('-')
  const id = reverse ? defaultSort.slice(1) : sort

  const sortTitle = t(`sort.${id}`)
  const sortTitleDirection = t(`sort.sortDirection.${reverse ? 'desc' : 'asc'}`)

  if (!selectedFacet?.sortOptions) return null
  return (
    <Button
      onClick={() => dispatch(modals.open('SortSidebar'))}
      title={`${sortTitle} - ${sortTitleDirection}`}
      type="roundActive"
      iconName="streamlineSort"
      iconProps={{ size: 'medium', color: 'white'}}
      data-testid="sort-picker-button"
      ml={2}
    />
  )
}

export default SortPickerButton
