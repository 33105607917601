export const HOME_PAGE = '/'
export const HUB_PAGES = ['/', '/tasks', '/files', '/forms', '/reports', '/stories']
export const DASHBOARD_PAGE_SIZE = 20
export const CALENDAR_PAGE_SIZE = 100
export const TEMPLATES_PAGE_SIZE = 100
export const ASSETS_PAGE_SIZE = 40
export const COMPLETIONS_PAGE_SIZE = 10
export const LAYOUTS = {
  CALENDAR: 'calendar',
  GRID: 'grid',
  LIST: 'list',
}
export const THUMBNAIL_SIZES = {
  small: 90,
  medium: 320,
  large: 640,
  huge: 1024
}
