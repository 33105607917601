const open = (modalType, props, push) => ({
  type: 'OPEN_MODAL',
  modalType,
  props,
  push,
})

const close = pop => ({
  type: 'CLOSE_MODAL',
  pop,
})

const reducer = (state = { opened: [] }, action) => {
  switch (action.type) {
    case 'OPEN_MODAL': {
      const { modalType, props, push } = action
      const currentOpened = {
        modalType,
        props,
      }
      const opened = push ? [...state.opened, currentOpened] : [currentOpened]
      return {
        ...state,
        opened,
      }
    }

    case 'CLOSE_MODAL': {
      const opened = action.pop ? state.opened.slice(0, state.opened.length - 1) : []
      return {
        ...state,
        opened,
      }
    }

    default:
      return state
  }
}

const isModalOpen = (state, modalType) => {
  const openModals = state.modals.opened
  return !!openModals.find(item => item.modalType === modalType)
} 

const modals = {
  reducer,
  open,
  close,
  isModalOpen,
}

export default modals