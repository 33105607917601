import { get as getProp } from 'lodash'
import { documents } from '@concrete/one-redux'

const actionTypes = {
  FORWARDING_AUDIENCE_ADDED: 'FORWARDING_AUDIENCE_ADDED',
  FORWARDING_AUDIENCE_REMOVED: 'FORWARDING_AUDIENCE_REMOVED'
}

// Action creators ---

const addForwardingAudience = (id, audience) => ({
  type: actionTypes.FORWARDING_AUDIENCE_ADDED,
  id,
  audience
})

const removeForwardingAudience = id => ({
  type: actionTypes.FORWARDING_AUDIENCE_REMOVED,
  id
})

const reducer = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.FORWARDING_AUDIENCE_ADDED: {
      return {
        ...state,
        [action.id]: {
          audience: action.audience,
          isForwarding: true,
        }
      }
    }

    case documents.actionTypes.DOCUMENT_FORWARDED:
    case actionTypes.FORWARDING_AUDIENCE_REMOVED: {
      return {
        ...state,
        [action.id]: {}
      }
    }

    default:
      return state
  }
}

// Selectors
const selectForwardingAudience = (state, id) =>
  getProp(state, `forwardingUi.${id}.audience`)

const selectIsForwarding = (state, id) =>
  getProp(state, `forwardingUi.${id}.isForwarding`)

const forwardingUi = {
  actionTypes,
  selectForwardingAudience,
  addForwardingAudience,
  selectIsForwarding,
  removeForwardingAudience,
  reducer
}

export default forwardingUi
