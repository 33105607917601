import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router'
import {
  helpers,
  permissions,
  groups,
  resourceTypes,
  compliance,
  documents,
  distribution,
} from '@concrete/one-redux'
import { generateUrn } from '@concrete/resource'
import { hooks, Loader } from '@concrete/web-components'
import { modals, filesUi, tracker } from 'state'
import { isValidAudience, saveFiles } from 'helpers'
import { useGoBack } from 'hooks'

const { PERMISSIONS } = permissions
const { FORMS, TEMPLATES, TASKS, ANNOUNCEMENTS } = resourceTypes

export const useMenuItems = (id, itemOptions = {}, resourceType, completionId) => {
  const { t } = useTranslation(['general', 'tasks', 'announcements', 'forms'])
  const dispatch = useDispatch()
  const goBack = useGoBack()
  const history = useHistory()
  const { name, group, type } = useSelector(state => documents.selectDocument(state, resourceType, id)) || {}
  const urn = generateUrn(type ?? TASKS, id)
  const groupName = useSelector(state => {
    const completion = compliance.selectCompletion(state, completionId)
    return groups.selectGroup(state, completion?.group || group)?.name
  })
  const uploads = useSelector(state => compliance.selectAllBreakdownUploads(state, resourceType, id))
  const areFilesDownloading = useSelector(state => filesUi.areFilesDownloading(state, uploads))
  const isMobile = hooks.useMediaSize('xs')
  const isForm = [FORMS, TEMPLATES].includes(type)
  const isPublishedForm = type === TEMPLATES
  const isDistributed = useSelector(state => distribution.isDistributed(state, resourceType, id))
  const isFormSubmission = isDistributed && type === FORMS
  const [
    canCancel,
    canViewBreakdown,
    canDeleteDistribution,
    canCompleteCompliance,
  ] = useSelector(state => permissions.userHasPermissions(
    state,
    [
      PERMISSIONS.COMPLIANCE_CANCEL,
      PERMISSIONS.COMPLIANCE_LIST,
      PERMISSIONS.DISTRIBUTION_DELETE,
      PERMISSIONS.COMPLIANCE_DONE,
    ],
    helpers.generateUrn(resourceType, id),
    completionId
  ))
  const [ canRemind, canCopyDocument, canDeleteDocument, canEdit ] =  useSelector(state => permissions.userHasPermissions(
    state,
    [
      PERMISSIONS.AUDIENCE_REMIND,
      PERMISSIONS.DOCUMENT_COPY,
      PERMISSIONS.DOCUMENT_DELETE,
      PERMISSIONS.DOCUMENT_UPDATE,
    ],
    helpers.generateUrn(resourceType, id)
  ))
  const [
    canCreateForm,
    canCreateTask,
  ] = useSelector(state => permissions.userHasPermissions(
    state,
    [
      PERMISSIONS.FORM_CREATE,
      PERMISSIONS.TASK_CREATE,
    ],
    permissions.SESSION_URN,
  ))
  // TODO: to be removed in upcoming work as part of ONE-11993
  const canCreate = {
    forms: canCreateForm,
    tasks: canCreateTask,
  }
  const { audience } = useSelector(state => distribution.selectResourceDistribution(state, resourceType, id)) || {}
  const canDelete = isValidAudience(audience) ? canDeleteDistribution : canDeleteDocument
  const canCopy = canCopyDocument && !!canCreate[resourceType]
  const hasCompletions = useSelector(state => !!compliance.selectCompletions(state, resourceType, id)?.length)
  const menuItems = useMemo(() => {
    const deleteTask = () => {
      dispatch(documents.deleteDocument(resourceType, id))
      goBack()
    }
    const onCancelForm = () => {
      completionId ? dispatch(compliance.cancelCompletion(resourceType, id, completionId)) :
        dispatch(compliance.cancelCompliance(resourceType, id))
      goBack()
    }
    const completeCompliance = () => {
      dispatch(compliance.completeCompliance(resourceType, id))
    }
    const onDocumentCopied = ({ resourceType, id }) => history.push(`/${resourceType}/${id}`)

    return [
      {
        id: 'remind',
        label: completionId ? t('tasks:breakdown.sendReminder') : t('tasks:remindAll'),
        action: () => dispatch(
          modals.open('SendTaskReminder', {
            resourceId: id,
            completionId,
            resourceType,
          }),
        ),
        hidden: !canRemind,
      },
      {
        id: 'cancel',
        label: t('general:cancel'),
        action: () => dispatch(
          modals.open('CancelTask', {
            taskId: id,
            resourceType,
            completionId,
          }),
        ),
        hidden: !canCancel || isForm,
      },
      {
        id: 'cancel',
        label: isPublishedForm ? t('general:archive') : t('general:void'),
        action: () => dispatch(
          modals.open('ModalConfirm', {
            onConfirm: onCancelForm,
            header: isPublishedForm ? t('forms:confirmArchive') : t('forms:confirmVoid'),
            body: t('general:irreversibleActionWarning'),
            confirmLabel: isPublishedForm ? t('general:archive') : t('general:void'),
          }),
        ),
        hidden: !canCancel || !isForm,
      },
      {
        id: 'copy',
        label: t('copy.create'),
        action: () => dispatch(documents.copyDocument(resourceType, id, !isFormSubmission && t('copy.prefix'), onDocumentCopied)),
        hidden: !canCopy || resourceType === ANNOUNCEMENTS
      },
      {
        id: 'downloadCsv',
        label: t('tasks:downloadCsv'),
        action: () => isForm && dispatch(tracker.event.contextMenu.downloadCSV()),
        href: `${process.env.REACT_APP_API_URL}forms/${urn}/report?format=csv`,
        hidden: isMobile || !canViewBreakdown || !hasCompletions,
        download: true,
      },
      {
        id: 'downloadXlsx',
        label: t('tasks:downloadXlsx'),
        action: () => isForm && dispatch(tracker.event.contextMenu.downloadXLSX()),
        href: `${process.env.REACT_APP_API_URL}forms/${urn}/report?format=xlsx`,
        hidden: isMobile || !canViewBreakdown || !hasCompletions,
        download: true,
      },
      {
        id: 'downloadAll',
        label: t('tasks:downloadAll'),
        action: () => {
          if (canViewBreakdown)
            dispatch(compliance.requestBreakdownUploads(resourceType, id, name))
          else
            saveFiles(
              uploads,
              groupName,
            )
        },
        render: areFilesDownloading ? () => <Loader small /> : undefined,
        disabled: areFilesDownloading,
        hidden: [FORMS, TEMPLATES].includes(resourceType),
      },
      {
        id: 'delete',
        label: t('general:delete'),
        action: () => dispatch(modals.open('ModalConfirm', {
          id,
          onCancel: itemOptions?.['delete']?.onCancel,
          onConfirm: deleteTask,
        })),
        hidden: !canDelete,
      },
      {
        id: 'done',
        label: t('general:moveToDone'),
        action: completeCompliance,
        hidden: !canCompleteCompliance || resourceType !== TASKS
      },
      {
        id: 'notify',
        label: t('general:notify'),
        action: () => dispatch(modals.open('NotifySidebar', {
          id,
          resourceType,
        })),
        hidden: !canEdit || [FORMS, TEMPLATES].includes(resourceType) || !!completionId || !isDistributed,
      }
    ].filter(item => !item.hidden)
  }, [completionId, t, canRemind, canCancel, isForm, isPublishedForm, canCopy, resourceType, urn, isMobile, canViewBreakdown, hasCompletions, areFilesDownloading, canDelete, dispatch, id, goBack, history, isFormSubmission, name, uploads, groupName, itemOptions, canEdit, isDistributed, canCompleteCompliance])
  return menuItems
}
