import getProp from 'lodash/get'
import { users } from '@concrete/one-redux'
import { takeEvery, select, put, takeLatest, call, delay } from 'redux-saga/effects'

const CHANGE_ROLE_FILTER = 'CHANGE_ROLE_FILTER'
const CHANGE_GROUP_FILTER = 'CHANGE_GROUP_FILTER'
const CHANGE_FREETEXT_FILTER = 'CHANGE_FREETEXT_FILTER'
const CHANGE_AVAILABLE_AUDIENCE = 'CHANGE_AVAILABLE_AUDIENCE'
const SELECT_ALL_USERS = 'SELECT_ALL_USERS'
const REQUEST_MORE_USERS = 'REQUEST_MORE_USERS'
const RESET_USER_FILTER = 'RESET_USER_FILTER'
const RESET_ALL_SELECTED = 'RESET_ALL_SELECTED'
const USERS_ONLINE_STATUS = 'USERS_ONLINE_STATUS'

const actionTypes = {
  CHANGE_ROLE_FILTER,
  CHANGE_GROUP_FILTER,
  CHANGE_FREETEXT_FILTER,
  CHANGE_AVAILABLE_AUDIENCE,
  SELECT_ALL_USERS,
  REQUEST_MORE_USERS,
  RESET_USER_FILTER,
  RESET_ALL_SELECTED,
  USERS_ONLINE_STATUS,
}

const reducer = (state = {}, action) => {
  switch (action.type) {
    case CHANGE_ROLE_FILTER: {
      const { roles, pageSize } = action
      return {
        ...state,
        selectedRoles: roles,
        currentPage: 1,
        currentPageSize: pageSize,
      }
    }

    case CHANGE_GROUP_FILTER: {
      const { groups, includeSuperiors, includeSubordinates, pageSize } = action
      return {
        ...state,
        selectedGroups: groups,
        currentPage: 1,
        currentPageSize: pageSize,
        includeSuperiors,
        includeSubordinates,
      }
    }

    case CHANGE_FREETEXT_FILTER: {
      const { text, includeSuperiors, pageSize } = action
      return {
        ...state,
        freeTextSearch: text,
        currentPage: 1,
        currentPageSize: pageSize,
        includeSuperiors,
      }
    }

    case CHANGE_AVAILABLE_AUDIENCE: {
      const { availableAudience } = action

      return {
        ...state,
        availableAudience,
      }
    }

    case REQUEST_MORE_USERS: {
      return {
        ...state,
        currentPage: state.currentPage ? state.currentPage + 1 : 1,
      }
    }

    case RESET_USER_FILTER: {
      return {}
    }

    case USERS_ONLINE_STATUS: {
      const { statuses } = action

      return {
        ...state,
        ...Object.keys(statuses).reduce((acc, userId) => {
          acc[`${userId}/status`] = statuses[userId]
          return acc
        }, {})
      }
    }

    case 'USERS_REQUESTED': {
      return {
        ...state,
        isLoading: true,
      }
    }

    case 'USERS_LOADED': {
      return {
        ...state,
        isLoading: undefined,
      }
    }

    default:
      return state
  }
}

const changeUsersOnlineStatus = (statuses) => ({
  type: USERS_ONLINE_STATUS,
  statuses,
})

const changeRoleFilter = (newRoles, pageSize = 20) => ({
  type: CHANGE_ROLE_FILTER,
  roles: newRoles && newRoles.length ? newRoles : null,
  pageSize,
})

const changeGroupFilter = (newGroups = [], includeSuperiors = false, includeSubordinates = false, pageSize = 20) => ({
  type: CHANGE_GROUP_FILTER,
  groups: newGroups,
  includeSuperiors,
  includeSubordinates,
  pageSize,
})

const changeAvailableAudience = (availableAudience = []) => ({
  type: CHANGE_AVAILABLE_AUDIENCE,
  availableAudience,
})

const changeFreeTextFilter = (newText, includeSuperiors = false, pageSize = 20) => ({
  type: CHANGE_FREETEXT_FILTER,
  text: newText !== '' ? newText : undefined,
  includeSuperiors,
  pageSize,
})

const loadMoreUsers = () => ({
  type: REQUEST_MORE_USERS,
})

const loadAllSelectedUsers = () => ({
  type: SELECT_ALL_USERS,
})

const resetAllSelected = () => ({
  type: RESET_ALL_SELECTED,
})

const resetUserFilter = () => ({
  type: RESET_USER_FILTER,
})

const areAllUsersSelected = state => getProp(state, 'usersUi.allSelected')
const selectUsersIsLoading = state => getProp(state, 'usersUi.isLoading')
const selectCurrentPage = state => getProp(state, 'usersUi.currentPage')
const selectCurrentPageSize = state => getProp(state, 'usersUi.currentPageSize')
const selectRoleFilter = state => getProp(state, 'usersUi.selectedRoles')
const selectGroupFilter = state => getProp(state, 'usersUi.selectedGroups')
const selectAvailableAudience = state => getProp(state, 'usersUi.availableAudience')
const selectFreeTextFilter = state => getProp(state, 'usersUi.freeTextSearch')
const selectIncludeSuperiors = state => getProp(state, 'usersUi.includeSuperiors')
const selectIncludeSubordinates = state => getProp(state, 'usersUi.includeSubordinates')
const selectUserOnlineStatus = (state, id) => getProp(state, `usersUi.${id}/status`)
const selectAllUsers = state => {
  const selectedRoles = selectRoleFilter(state)
  const q = selectFreeTextFilter(state)
  const selectedGroups = selectGroupFilter(state)
  const includeSuperiors = selectIncludeSuperiors(state)
  const includeSubordinates = selectIncludeSubordinates(state)
  return users.selectUsersByQuery(state, {
    jobDescription: selectedRoles,
    group: selectedGroups,
    q,
    includeSuperiors,
    includeSubordinates,
    expand: ['profileImg'],
    key: 'selectAll',
  })
}
const selectAllUsersCount = state => {
  const selectedRoles = selectRoleFilter(state)
  const q = selectFreeTextFilter(state)
  const selectedGroups = selectGroupFilter(state)
  const includeSuperiors = selectIncludeSuperiors(state)
  const includeSubordinates = selectIncludeSubordinates(state)
  return users.selectUsersCountByQuery(state, {
    jobDescription: selectedRoles,
    group: selectedGroups,
    q,
    includeSuperiors,
    includeSubordinates,
    expand: ['profileImg'],
    key: 'selectAll',
  })
}

function* processFilterChange() {
  const selectedRoles = yield select(selectRoleFilter)
  const freeTextSearch = yield select(selectFreeTextFilter)
  const selectedGroups = yield select(selectGroupFilter)
  const selectedUsers = yield select(selectAvailableAudience)
  const page = yield select(selectCurrentPage)
  const pageSize = yield select(selectCurrentPageSize)
  const includeSuperiors = yield select(selectIncludeSuperiors)
  const includeSubordinates = yield select(selectIncludeSubordinates)
  yield put(users.requestUsers({
    userIds: selectedUsers,
    jobDescription: selectedRoles,
    group: selectedGroups,
    q: freeTextSearch,
    includeSuperiors,
    includeSubordinates,
    expand: ['profileImg'],
    page,
    pageSize,
  }))
}

// hack hack hack
function* selectAllUsersSaga() {
  const selectedRoles = yield select(selectRoleFilter)
  const freeTextSearch = yield select(selectFreeTextFilter)
  const selectedGroups = yield select(selectGroupFilter)
  const includeSuperiors = yield select(selectIncludeSuperiors)
  const includeSubordinates = yield select(selectIncludeSubordinates)
  yield put(users.requestUsers({
    jobDescription: selectedRoles,
    group: selectedGroups,
    q: freeTextSearch,
    includeSuperiors,
    includeSubordinates,
    expand: ['profileImg'],
    key: 'selectAll',
    pageSize: 10000, // hacky hack
  }))

  yield put(resetAllSelected())
}

function* debounceInput() {
  yield delay(300)
  yield call(processFilterChange)
}

function* resetUserFilterSaga() {
  const selectedRoles = yield select(selectRoleFilter)
  const freeTextSearch = yield select(selectFreeTextFilter)
  const selectedGroups = yield select(selectGroupFilter)
  const includeSuperiors = yield select(selectIncludeSuperiors)
  const includeSubordinates = yield select(selectIncludeSubordinates)
  yield put(users.resetQuery({
    jobDescription: selectedRoles,
    group: selectedGroups,
    q: freeTextSearch,
    includeSuperiors,
    includeSubordinates,
    expand: ['profileImg'],
    key: 'selectAll',
  }))
}

function* saga() {
  yield takeEvery([
    actionTypes.CHANGE_ROLE_FILTER,
    actionTypes.CHANGE_GROUP_FILTER,
    actionTypes.REQUEST_MORE_USERS,
  ], processFilterChange)
  // debounce search text input to avoid having too many requests in flight
  yield takeLatest(actionTypes.CHANGE_FREETEXT_FILTER, debounceInput)
  yield takeLatest(actionTypes.SELECT_ALL_USERS, selectAllUsersSaga)
  yield takeLatest([actionTypes.RESET_USER_FILTER, actionTypes.RESET_ALL_SELECTED], resetUserFilterSaga)
}

const usersUi = {
  actionTypes,
  changeRoleFilter,
  changeGroupFilter,
  changeFreeTextFilter,
  changeUsersOnlineStatus,
  changeAvailableAudience,
  debounceInput,
  loadMoreUsers,
  loadAllSelectedUsers,
  resetAllSelected,
  reducer,
  resetUserFilter,
  areAllUsersSelected,
  selectUsersIsLoading,
  selectRoleFilter,
  selectFreeTextFilter,
  selectGroupFilter,
  selectAvailableAudience,
  selectIncludeSuperiors,
  selectIncludeSubordinates,
  selectCurrentPage,
  selectCurrentPageSize,
  selectUserOnlineStatus,
  selectAllUsers,
  selectAllUsersCount,
  saga,
  processFilterChange,
  selectAllUsersSaga,
}

export default usersUi
