import React, { useCallback, useState, useEffect, useRef } from 'react'
import { useLocation } from 'react-router'
import { ToggleButton, FlexColumn, SearchBar, hooks, Flex } from '@concrete/web-components'
import { useResourceState, useResourceNavigator, useGridSize } from 'hooks'
import { getViewModes } from 'helpers/resourceFilters'
import { useDebounce } from 'use-debounce'
import { DEBOUNCE_DELAY } from '.'
import { useTranslation } from 'react-i18next'
import { LAYOUTS } from 'helpers/constants'
import LocationPickerButton from './LocationPickerButton'
import { RESOURCE_TYPES } from '@concrete/resource'

const { CALENDAR } = LAYOUTS
const { ALL } = RESOURCE_TYPES

const HomeFilters = () => {
  const { pathname } = useLocation()
  const stateKey = 'home'
  const viewModes = getViewModes(ALL)
  const resourceState = useResourceState(stateKey)
  const { facet, layout, q } = resourceState
  const navigator = useResourceNavigator(stateKey, pathname)
  const view = layout || viewModes[0].id
  const gridWidth = useGridSize()
  const { t } = useTranslation()
  const isMobile = hooks.useMediaSize('xs')

  const qRef = useRef('')
  const [searchText, setSearchText] = useState(q)
  const [searchVal] = useDebounce(searchText, DEBOUNCE_DELAY)

  const handleSearch = useCallback(value => {
    const newQuery = {
      ...resourceState,
      page: 1,
      q: value,
      facet,
    }
    navigator.replace(newQuery, !value)
  }, [facet, navigator, resourceState])

  useEffect(() => {
    if (qRef.current !== searchVal) {
      handleSearch(searchVal)
      qRef.current = searchVal
    }
  }, [handleSearch, searchVal])

  const handleClick = useCallback(
    layout => navigator.replace({ ...resourceState, layout, page: 1 }),
    [navigator, resourceState],
  )
  const renderSearchBar = () => (
    <SearchBar
      key={`${stateKey}-${isMobile}`}
      data-testid="home-search-bar"
      aria-label="home search"
      onChange={setSearchText}
      onClear={setSearchText}
      defaultValue={q}
      placeholder={t("search.calendar")}
      maxWidth={["none", 360]}
      fixedOpen={!!q || isMobile}
      ml={[0, 2]}
      mt={[2, 0]}
      order={[1, 0]}
    />
  )

  return (
    <FlexColumn
      data-testid="home-filters-container"
      justifyContent="space-between"
      alignContent="center"
      mx="auto"
      mb={4}
      px={2}
      width="100%"
      maxWidth={gridWidth}
      flexShrink={0}
    >
      <Flex>
        {!isMobile && layout === CALENDAR && renderSearchBar()}
        <Flex ml="auto">
          {layout === CALENDAR ? (
            <LocationPickerButton
              data-testid="group-changer"
              navigator={navigator}
              facet={facet}
              layout={layout}
              resourceType={ALL}
              mr={2}
            />
          ) : null}
          <ToggleButton
            onClick={handleClick}
            iconLeft={viewModes[0]}
            iconRight={viewModes[1]}
            defaultActive={view}
            data-testid="toggle-button"
          />
        </Flex>
      </Flex>
      {isMobile && layout === CALENDAR && renderSearchBar()}
    </FlexColumn>
  )
}

export default HomeFilters
