/* eslint-disable no-unused-expressions */
import { files, tasks, session, compliance } from '@concrete/one-redux'
import { all, takeLatest, takeEvery, call, select, put } from 'redux-saga/effects'
import { get as getProp } from 'lodash'
import JSZip from 'jszip'
import { saveAs } from 'file-saver'
import { show } from './toast'
import i18n from '../translate/i18n'

const MAX_SELECTION = 40

const actionTypes = {
  CANCEL_FILE_EDITING: 'CANCEL_FILE_EDITING',
  START_FILE_EDITING: 'START_FILE_EDITING',
  START_SELECTION: 'START_SELECTION',
  SELECT_FILES: 'SELECT_FILES',
  DESELECT_FILES: 'DESELECT_FILES',
  CLEAR_SELECTED_FILES: 'CLEAR_SELECTED_FILES',
}

/**
* Deprecated
*
* @param {*} data
* @param {*} file
*/
const sendMessageToMobileView = (data, file) => {
  const { name, originalName, mimeType } = file
  const reader = new FileReader()
  reader.onload = function () {
    const b64 = reader.result.replace(/^data:.+;base64,/, '')
    const message = { data: b64, name, originalName, mimeType }
    window.ReactNativeWebView?.postMessage?.(`download:${JSON.stringify(message)}`)
  }
  reader.readAsDataURL(data)
}

/**
* Deprecated
*
* @param {*} blob
* @param {*} file
*/
const save = (blob, file) => {
  const { originalName, name } = file
  if (window.isApp) {
    sendMessageToMobileView(blob, file)
  } else {
    saveAs(blob,  originalName || name)
  }
}

/**
 * Deprecated
 *
 * @param {*} data
 * @param {*} name
 * @param {*} mimeType
 */
const createDownload = (data, file) => {
  if (window.isApp) {
    return sendMessageToMobileView(data, file)
  }
  const { originalName, name, mimeType } = file
  const blob = new window.Blob([data], { type: mimeType })
  const a = document.createElement('a')
  const url = window.URL.createObjectURL(blob)
  a.href = url
  a.download = originalName || name
  a.style.display = 'none'
  document.body.appendChild(a)
  a.click()
  window.URL.revokeObjectURL(url)
  document.body.removeChild(a)
}

/**
* Deprecated
*
* @param {*} data
* @param {*} files
* @param {String} archiveName
*/
const createArchive = async (data, files, archiveName) => {
  const zip = new JSZip()
  if (Array.isArray(files)) {
    if (files.length === 1) {
      const blob = new window.Blob([data[0]], { type: files[0].mimeType })
      return save(blob, files[0])
    }
    data.forEach((fileData, i) => {
      const { name, originalName } = files[i]
      zip.file(name || originalName, fileData)
    })
  } else {
    Object.keys(files).forEach(name => {
      if (files[name] && files[name].length) {
        const folder = zip.folder(name)
        files[name].forEach((file, idx) => {
          folder.file(file.originalName || file.name, data[name][idx])
        })
      }
    })
  }
  const content = await zip.generateAsync({ type: 'blob' })
  const name = `${archiveName}.zip`
  save(content, { name, mimeType: 'application/zip' })
}

// Actions

const cancelFileEditing = id => ({
  type: actionTypes.CANCEL_FILE_EDITING,
  id,
})

const editFile = id => ({
  type: actionTypes.START_FILE_EDITING,
  id,
})

const addToSelection = (ids, isGroup) => ({
  type: actionTypes.START_SELECTION,
  ids,
  isGroup,
})

const removeFromSelection = ids => ({
  type: actionTypes.DESELECT_FILES,
  ids,
})

const clearSelection = () => ({
  type: actionTypes.CLEAR_SELECTED_FILES,
})

const changeFilesState = (key, value) => ({
  type: files.actionTypes.FILES_STATE_CHANGE_REQUESTED,
  key,
  value,
})

const reducer = (state = { selected: { all: [], folders: [] } }, action) => {
  switch (action.type) {
    case files.actionTypes.FILE_DOWNLOAD_REQUESTED: {
      return {
        ...state,
        [action.id]: {
          isDownloading: true,
        },
      }
    }

    case files.actionTypes.FILE_DOWNLOAD_SUCCESS:
    case files.actionTypes.FILE_DOWNLOAD_FAILED: {
      return {
        ...state,
        [action.id]: {
          isDownloading: false,
        },
      }
    }

    case compliance.actionTypes.RESOURCE_BREAKDOWN_UPLOADS_REQUESTED:
    case files.actionTypes.FILES_DOWNLOAD_REQUESTED: {
      return {
        ...state,
        filesDownloading: true,
      }
    }

    case files.actionTypes.FILES_DOWNLOAD_SUCCESS:
    case files.actionTypes.FILES_DOWNLOAD_FAILED: {
      return {
        ...state,
        filesDownloading: false,
      }
    }

    case files.actionTypes.FILES_DETAILS_REQUESTED:
    case files.actionTypes.USER_ASSETS_REQUESTED: {
      return {
        ...state,
        areAssetsLoading: true,
      }
    }

    case files.actionTypes.FILES_DETAILS_LOADED:
    case files.actionTypes.USER_ASSETS_LOADED:
    case files.actionTypes.USER_ASSETS_REQUEST_FAILED: {
      return {
        ...state,
        areAssetsLoading: false,
      }
    }

    case tasks.actionTypes.REMOVE_ATTACHMENT_FROM_TASK_REQUESTED: {
      return {
        ...state,
        [action.attachmentId]: {
          ...state[action.attachmentId],
          isRemoving: true,
        },
      }
    }

    case tasks.actionTypes.REMOVE_ATTACHMENT_FROM_TASK_FAILED: {
      return {
        ...state,
        [action.attachmentId]: {
          ...state[action.attachmentId],
          isRemoving: false,
        },
      }
    }

    case actionTypes.CANCEL_FILE_EDITING: {
      return {
        ...state,
        [action.id]: {
          ...state[action.id],
          isEditing: false,
        },
      }
    }

    case actionTypes.START_FILE_EDITING: {
      return {
        ...state,
        [action.id]: {
          ...state[action.id],
          isEditing: true,
        },
      }
    }

    case tasks.actionTypes.TASK_ATTACHMENT_REMOVED: {
      return {
        ...state,
        [action.attachmentId]: undefined,
      }
    }

    case actionTypes.START_SELECTION: {
      const { ids, isGroup } = action
      if (!ids?.length) return state
      const { selected = { all: [] } } = state
      let selectionStart = isGroup ? selected.selectionStart || ids[0] : undefined
      return {
        ...state,
        selected: {
          ...selected,
          selectionStart,
        },
      }
    }

    case actionTypes.SELECT_FILES: {
      const { ids, folders = [], resetSelection } = action
      if (!ids?.length) return state
      const { selected = { all: [], folders: [] } } = state
      const selectionStart = resetSelection ? undefined : selected.selectionStart
      return {
        ...state,
        selected: {
          ...selected,
          ...ids.reduce((acc, id) => {
            acc[id] = id
            return acc
          },{}),
          selectionStart,
          all: [...(selected.all || []), ...ids],
          folders: [...(selected.folders || []), ...folders],
        },
      }
    }

    case actionTypes.DESELECT_FILES: {
      if (!action.ids?.length) return state
      const { selected = { all: [], folders: [] } } = state
      return {
        ...state,
        selected: {
          ...selected,
          ...action.ids.reduce((acc, id) => {
            acc[id] = undefined
            acc.all = acc.all.filter(fileId => id !== fileId)
            acc.folders = acc.folders?.filter(folderId => id !== folderId)
            return acc
          }, { ...selected }),
        },
      }
    }

    case actionTypes.CLEAR_SELECTED_FILES: {
      return {
        ...state,
        selected: { all: [], folders: [] },
      }
    }
    default:
      return state
  }
}

const areAssetsLoading = state => getProp(state, 'filesUi.areAssetsLoading')

const isFileDownloading = (state, fileId) =>
  getProp(state, `filesUi.${fileId}.isDownloading`, false)

const areFilesDownloading = state =>
  getProp(state, `filesUi.filesDownloading`, false)

const isFileBeingRemoved = (state, fileId) =>
  getProp(state, `filesUi.${fileId}.isRemoving`, false)

const isFileBeingEdited = (state, fileId) =>
  getProp(state, `filesUi.${fileId}.isEditing`, false)

const isFileSelected = (state, fileId) =>
    !!getProp(state, `filesUi.selected.${fileId}`)

const selectAssetsSelected = (state) =>
    getProp(state, 'filesUi.selected.all')

const selectFoldersSelected = (state) =>
    getProp(state, 'filesUi.selected.folders')

const selectFilesSelectionCount = (state) =>
    getProp(state, 'filesUi.selected.all.length', 0)

const selectSelectionStart = (state) =>
    getProp(state, 'filesUi.selected.selectionStart')

/**
 * Deprecated, use downloadFiles even to download a single file
 *
 * @param {*} param0
 */
function* download({ data, file }) {
  yield call(createDownload, data, file)
}

/**
* Deprecated
*/
function* downloads({ data, files, archiveName }) {
  yield call(createArchive, data, files, archiveName)
}

function* changeFilesStateSaga({ key, value }) {
  const userId = yield select(session.selectCurrentUserId)
  let filesState = JSON.parse(localStorage.getItem(`files-state-${userId}`) || '{}')

  const facet = filesState.facet ? filesState.facet : 'all'
  const addedValue = key === 'filters' ? value : { sort: value }

  if (['filters', 'sort'].includes(key)) {
    filesState = {
      ...filesState,
      filters: {
        ...filesState.filters,
        [facet]: {
          ...(filesState.filters && filesState.filters[facet]),
          ...addedValue
        }
      }
    }
  } else {
    filesState = {
      ...filesState,
      [key]: value
    }
  }
  localStorage.setItem(`files-state-${userId}`, JSON.stringify(filesState))

  yield put({
    type: files.actionTypes.FILES_STATE_CHANGED,
    key,
    value,
  })
}

function* loadingFailedSaga({ error, errorCallback }) {
  if (error?.response?.status === 404) {
    yield call(errorCallback, '/files')
    yield put(show(i18n.t('files:notFound'), 'error'))
  }
}

function* selectFilesSaga({ ids, isGroup }) {
  let selectedFiles = ids
  let resetSelection = false
  const currentSelection = yield select(selectAssetsSelected)

  if (isGroup) {
    const selectionStart = yield select(selectSelectionStart)
    if (selectionStart) {
      selectedFiles = [selectionStart]
      const selectionEnd = ids[ids.length - 1]
      const facet = yield select(state => state.files.facet)
      const lastQuery = yield select(files.selectLastQuery)
      const assets = yield select(files.selectAssetsByFacetQuery, lastQuery, facet)
      if (assets?.length && selectionStart !== selectionEnd) {
        let startIndex = -1
        for (let i = 0; i < assets.length; i++) {
          const currentFile = assets[i]
          if (currentFile === selectionStart) startIndex = i
          if (startIndex !== -1 && i > startIndex) selectedFiles.push(currentFile)
          if (currentFile === selectionEnd) break
        }
        resetSelection = true
      }
    }
  }

  if ((currentSelection.length + selectedFiles.length) > MAX_SELECTION) {
    selectedFiles.splice(MAX_SELECTION - (currentSelection.length + selectedFiles.length))
  }

  const selectedFilesObjects = yield all(selectedFiles.map(id => select(files.selectFile, id)))
  const folders = selectedFilesObjects.reduce((acc, file) => {
    if (file.isFolder) acc.push(file._id)
    return acc
  }, [])

  yield put({
    type: actionTypes.SELECT_FILES,
    ids: selectedFiles,
    folders,
    resetSelection,
  })
}

function* saga() {
  yield takeEvery(files.actionTypes.FILE_DOWNLOAD_SUCCESS, download)
  yield takeEvery(files.actionTypes.FILES_DOWNLOAD_SUCCESS, downloads)
  yield takeLatest(files.actionTypes.FILES_STATE_CHANGE_REQUESTED, changeFilesStateSaga)
  yield takeEvery(files.actionTypes.USER_ASSETS_REQUEST_FAILED, loadingFailedSaga)
  yield takeLatest(actionTypes.START_SELECTION, selectFilesSaga)
}

const filesUi = {
  actionTypes,
  cancelFileEditing,
  editFile,
  addToSelection,
  removeFromSelection,
  clearSelection,
  reducer,
  saga,
  download,
  downloads,
  createDownload,
  createArchive,
  isFileDownloading,
  isFileBeingEdited,
  isFileBeingRemoved,
  isFileSelected,
  selectAssetsSelected,
  selectFilesSelectionCount,
  selectFoldersSelected,
  areAssetsLoading,
  areFilesDownloading,
  changeFilesState,
  changeFilesStateSaga,
  loadingFailedSaga,
  selectFilesSaga,
}

export default filesUi
