import React, { useCallback, useRef, useState } from 'react'
import { FlexColumn, hooks } from '@concrete/web-components'
import PropTypes from 'prop-types'
import { useScroll } from '@use-gesture/react'
import { useSpring, animated } from 'react-spring'
import { useLocation } from 'react-router'
import { HUB_PAGES } from 'helpers/constants'

export const AnimatedWrapper = animated(FlexColumn)

const SPRING_CONFIG = {
  friction: 30,
  tension: 150,
  mass: 1,
}

const NavigationView = ({ children, renderTop }) => {
  const containerHeight = 178
  const location = useLocation()
  const isHubPage = HUB_PAGES.includes(location?.pathname)
  const isMobile = hooks.useMediaSize('xs')
  const isAndroid = window.OS?.name === 'android' || /Android/.test(window.navigator.userAgent)
  const canCollapse = isHubPage && isMobile && !isAndroid
  const prevScrollTop = useRef(0)

  const [{ y, opacity }, set] = useSpring(() => ({
    y: 0,
    opacity: 1,
    config: SPRING_CONFIG
  }))
  const [ headerCollapsed, setHeaderCollapsed ] = useState(false)

  const animateCollapse = useCallback(isCollapsed => {
    setHeaderCollapsed(isCollapsed)
    set({
      y: isCollapsed ? containerHeight : 0,
      opacity: isCollapsed ? 0 : 1,
    })
  }, [containerHeight, set])

  const resetScroll = useCallback(() => animateCollapse(false), [animateCollapse])

  useScroll(({ event }) => {
    if (isAndroid) return
    const { scrollTop } = event.target
    // if the element is not allowed to scroll vertically, discard the event
    const computedStyle = window.getComputedStyle(event.target)
    if (computedStyle['overflow-y'] === 'hidden') return
    const scrollDistance = scrollTop - prevScrollTop.current
    const threshold = 20

    if (scrollTop === 0 || (scrollDistance < -threshold && headerCollapsed)) {
      animateCollapse(false)
    } else if (scrollDistance > threshold && !headerCollapsed) {
      animateCollapse(true)
    }
    prevScrollTop.current = scrollTop
  }, {
    target: document,
    eventOptions: { capture: true, passive: true },
  })

  const collapsibleStyle = {
    transform: y.to(y => `translateY(-${y}px)`),
    opacity,
    height: headerCollapsed ? 0 : containerHeight,
    position: headerCollapsed ? 'absolute' : 'relative',
    top: headerCollapsed ? 63 : 0,
    background: 'white',
    flexShrink: 0,
  }

  return (
    <FlexColumn data-testid="navigation-view" flexShrink={0}>
      {renderTop(canCollapse && headerCollapsed, resetScroll)}
      <AnimatedWrapper style={canCollapse ? collapsibleStyle : {}} data-testid="navigation-view-collapsible-wrapper">
        {children}
      </AnimatedWrapper>
    </FlexColumn>
  )
}

NavigationView.propTypes = {
  renderTop: PropTypes.func,
  onViewHidden: PropTypes.func,
  onViewShown: PropTypes.func,
}

NavigationView.defaultProps = {
  renderTop: () => {},
  onViewHidden: () => {},
  onViewShown: () => {},
}

export default NavigationView
