import { get as getProp } from 'lodash'

const actionTypes = {
  CHECKIN_QUERY_REQUESTED: 'CHECKIN_QUERY_REQUESTED',
  CHECKIN_QUERY_LOADED: 'CHECKIN_QUERY_LOADED',
}

const reducer = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.CHECKIN_QUERY_REQUESTED: {
      return {
        ...state,
        areCheckinsLoading: true,
      }
    }
    case actionTypes.CHECKIN_QUERY_LOADED: {
      return {
        ...state,
        areCheckinsLoading: false,
      }
    }
    default:
      return state
  }
}

const areCheckinsLoading = (state) =>
  getProp(state, 'checkinsUi.areCheckinsLoading', false)

const checkinsUi = {
  reducer,
  actionTypes,
  areCheckinsLoading,
}

export default checkinsUi
