export default function injectGtm(id) {
  if (!id) {
    console.warn('GA_ID not set')
    return
  }

  window.dataLayer = window.dataLayer || []
  window.dataLayer.push({
    'gtm.start': Date.now(),
    screenPath: `${window.location.pathname}${window.location.search}`.replace(
      /^\//,
      '',
    ),
    event: 'gtm.js',
  })

  const URL = 'https://www.googletagmanager.com/gtm.js'
  const script = document.createElement('script')
  script.async = true
  script.src = `${URL}?id=${id}`
  document.head.appendChild(script)
}
