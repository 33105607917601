import { get } from 'lodash'
import { comments } from '@concrete/one-redux'

const actionTypes = {
  SHOW_REPLIES: 'SHOW_REPLIES',
  HIDE_REPLIES: 'HIDE_REPLIES',
  SHOW_EDITOR: 'SHOW_EDITOR',
  HIDE_EDITOR: 'HIDE_EDITOR',
  SHOW_ALL_REPLIES: 'SHOW_ALL_REPLIES',
  HIDE_ALL_REPLIES: 'HIDE_ALL_REPLIES',
  REPLY_TO: 'REPLY_TO',
}

const showReplies = commentId => ({
  type: actionTypes.SHOW_REPLIES,
  commentId,
})

const hideReplies = () => ({
  type: actionTypes.HIDE_REPLIES,
})

const showCommentEditBox = commentId => ({
  type: actionTypes.SHOW_EDITOR,
  commentId,
})

const hideCommentEditBox = () => ({
  type: actionTypes.HIDE_EDITOR,
})

const showAllReplies = commentId => ({
  type: actionTypes.SHOW_ALL_REPLIES,
  commentId,
})

const hideAllReplies = () => ({
  type: actionTypes.HIDE_ALL_REPLIES,
})

const replyTo = commentId => ({
  type: actionTypes.REPLY_TO,
  commentId,
})

const reducer = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.SHOW_REPLIES:
      return {
        ...state,
        showAllReplies: false,
        currentlyOpen: action.commentId,
      }
    case actionTypes.HIDE_REPLIES:
      return {
        ...state,
        currentlyOpen: undefined,
      }
    case actionTypes.SHOW_EDITOR:
      return {
        ...state,
        currentlyEditing: action.commentId,
      }
    case actionTypes.HIDE_EDITOR:
      return {
        ...state,
        currentlyEditing: undefined,
      }
    case actionTypes.SHOW_ALL_REPLIES:
      return {
        ...state,
        showAllReplies: true,
      }
    case actionTypes.HIDE_ALL_REPLIES:
      return {
        ...state,
        showAllReplies: false,
      }
    case actionTypes.REPLY_TO:
      return {
        ...state,
        replyingTo: action.commentId,
      }

    case comments.actionTypes.COMMENTS_REQUESTED: {
      return {
        ...state,
        [action.subject]: {
          isLoading: true,
        },
      }
    }

    case comments.actionTypes.COMMENTS_LOADED:
    case comments.actionTypes.COMMENTS_RETRIEVE_FAILED: {
      return {
        ...state,
        [action.subject]: {
          isLoading: false,
        },
      }
    }

    default:
      return state
  }
}

const selectCommentIsExpanded = (state, commentId) => get(state, 'commentsUi.currentlyOpen') === commentId
const selectCommentIsBeingEdited = (state, commentId) => get(state, 'commentsUi.currentlyEditing') === commentId

const selectCommentHasAllRepliesOpen = state => get(state, 'commentsUi.showAllReplies')

const selectIsAnyCommentExpanded = state => !!get(state, 'commentsUi.currentlyOpen', false)
const selectIsAnyCommentEditing = state => !!get(state, 'commentsUi.currentlyEditing', false)

const selectReplyingTo = state => get(state, 'commentsUi.replyingTo')

const areCommentsLoading = (state, subject) => get(state, `commentsUi.${subject}.isLoading`)

const commentsUi =  {
  actionTypes,
  hideAllReplies,
  hideCommentEditBox,
  hideReplies,
  reducer,
  replyTo,
  selectCommentHasAllRepliesOpen,
  selectCommentIsBeingEdited,
  selectCommentIsExpanded,
  selectIsAnyCommentEditing,
  selectIsAnyCommentExpanded,
  selectReplyingTo,
  areCommentsLoading,
  showAllReplies,
  showCommentEditBox,
  showReplies,
}

export default commentsUi
