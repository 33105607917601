import { get as getProp } from 'lodash'
import { notifications } from '@concrete/one-redux'

const reducer = (state = { notificationsLoading: true }, action) => {
  switch (action.type) {
    case notifications.actionTypes.INIT_EVENT_STREAM: {
      if (action.params.eventType !== 'notification') return state
      return {
        ...state,
        notificationsLoading: true,
      }
    }
    case notifications.actionTypes.LOAD_NOTIFICATIONS: {
      return {
        ...state,
        notificationsLoading: false,
      }
    }
    default:
      return state
  }
}

const areNotificationsLoading = (state) =>
  getProp(state, 'notificationsUi.notificationsLoading', false)

const notificationsUi = {
  reducer,
  areNotificationsLoading,
}

export default notificationsUi
