import { put, select, takeEvery } from 'redux-saga/effects'
import { difference, get as getProp } from 'lodash'
import { annotations, files } from '@concrete/one-redux'
import { decodeUrn, RESOURCE_TYPES } from '@concrete/resource'

const ANNOTATIONS_QUEUE_REGEX = new RegExp(`^documents/${Object.values(RESOURCE_TYPES).join('|')}/([a-f\\d]{24})/annotations$`, 'i')

const actionTypes = {
  UPLOADS_STARTED: 'UPLOADS_STARTED',
  UPLOADS_COMPLETED: 'UPLOADS_COMPLETED',
  UPLOAD_PROGRESS: 'UPLOAD_PROGRESS',
  UPLOADS_FAILED: 'UPLOADS_FAILED',
  UPLOAD_QUEUE_DRAINED: 'UPLOAD_QUEUE_DRAINED',
}

const uploadsStarted = (key, files) => ({
  type: actionTypes.UPLOADS_STARTED,
  key,
  files,
})

const uploadProgress = (key, file, progress) => ({
  type: actionTypes.UPLOAD_PROGRESS,
  key,
  file,
  progress,
})

const uploadsCompleted = (key, files) => ({
  type: actionTypes.UPLOADS_COMPLETED,
  key,
  files,
})

const uploadsFailed = (key, files) => ({
  type: actionTypes.UPLOADS_FAILED,
  key,
  files,
})

const reducer = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.UPLOADS_STARTED: {
      const { key, files } = action
      const uploadsState = state[key] || {}
      const overallProgress = state.progress || []
      const fileIds = files.map(({ uploadId }) => uploadId)

      return {
        ...state,
        uploads: files.reduce((acc, file) => {
          acc[file.uploadId] = { ...file, originalName: file.filename }
          return acc
        }, {}),
        [key]: {
          ...uploadsState,
          started: fileIds,
          progress: fileIds,
        },
        progress: [...overallProgress, ...fileIds],
      }
    }

    case actionTypes.UPLOADS_COMPLETED: {
      const { key, files } = action
      const uploadsState = state[key] || {}
      const completed = uploadsState.completed || []
      const overallCompleted = state.completed || []
      const fileIds = files.map(({ uploadId }) => uploadId)

      // it feels like we're rebuilding a database index here
      // but we need to keep track of these same files through the whole upload pipeline
      // and the files in questions have different identifiers at each step:
      // - started/progress (tracked by uploadId)
      // - imported (tracked by handle/urn)
      // - attached (tracked by file id)
      const handles = { ...(state.handles || {}) }
      const uploads = { ...state.uploads }
      files.forEach(file => {
        handles[file.handle] = {
          uploadId: file.uploadId,
        }
        uploads[file.uploadId] = {
          ...uploads[file.uploadId],
          ...file,
        }
      })

      return {
        ...state,
        handles,
        uploads,
        [key]: {
          ...uploadsState,
          completed: [...completed, ...fileIds],
        },
        completed: [...overallCompleted, ...fileIds],
      }
    }

    case actionTypes.UPLOAD_PROGRESS: {
      const { file, progress } = action
      const { totalBytes, totalPercent } = progress
      return {
        ...state,
        uploads: {
          ...state.uploads,
          [file.uploadId]: {
            ...state.uploads[file.uploadId],
            originalName: file.filename,
            totalBytes,
            totalPercent,
          },
        }
      }
    }

    case actionTypes.UPLOADS_FAILED: {
      const { key, files } = action
      const uploadsState = state[key] || {}
      const overallProgress = state.progress || []
      const queueProgress = uploadsState.progress || []
      const failed = uploadsState.failed || []
      const overallFailed = state.failed || []
      const uploadIds = files.map(({ uploadId }) => uploadId)

      return {
        ...state,
        [key]: {
          ...uploadsState,
          progress: difference(queueProgress, uploadIds),
          failed: [...failed, ...uploadIds],
        },
        progress: difference(overallProgress, uploadIds),
        failed: [...overallFailed, ...uploadIds],
      }
    }

    case files.actionTypes.ASSETS_IMPORTED: {
      const { key, files } = action
      const uploadsState = state[key] || {}
      const imported = uploadsState.imported || []
      const overallProgress = state.progress || []
      const progress = uploadsState.progress || []

      const attach = ANNOTATIONS_QUEUE_REGEX.test(key)
      const handles = { ...(state.handles || {}) }
      const filesMap = { ...(state.files || {}) }
      const fileIds = []
      const uploadIds = []
      Object.values(files).forEach(({ item }) => {
        const handle = decodeUrn(item.urn).id
        const uploadId = handles[handle].uploadId
        fileIds.push(item._id)
        uploadIds.push(uploadId)
        handles[handle] = {
          ...handles[handle],
          fileId: item._id,
        }
        // once we have files, we map their ids to upload ids and handles
        // so we can later match the files that have been attached with their uploads
        filesMap[item._id] = {
          handle,
          uploadId,
        }
      })

      return {
        ...state,
        handles,
        files: filesMap,
        [key]: {
          ...uploadsState,
          imported: [...imported, ...fileIds],
          progress: difference(progress, attach ? [] : uploadIds),
        },
        progress: difference(overallProgress, attach ? [] : uploadIds),
      }
    }

    case files.actionTypes.ASSETS_IMPORT_FAILED: {
      const { key, body } = action
      const uploadsState = state[key] || {}
      const importFailed = uploadsState.importFailed || []
      const overallFailed = state.failed || []
      const failed = uploadsState.failed || []
      const overallProgress = state.progress || []
      const progress = uploadsState.progress || []

      const newFailed = []
      const uploadIds = []
      body.urns.forEach(urn => {
        const handle = decodeUrn(urn).id
        newFailed.push(handle)
        uploadIds.push(state.handles[handle].uploadId)
      })
      return {
        ...state,
        [key]: {
          ...uploadsState,
          failed: [...failed, ...uploadIds],
          importFailed: [...importFailed, ...newFailed],
          progress: difference(progress, uploadIds),
        },
        failed: [...overallFailed, ...uploadIds],
        progress: difference(overallProgress, uploadIds),
      }
    }

    case annotations.actionTypes.ATTACHMENTS_ADDED: {
      const { files, resourceType, id } = action
      const key = `documents/${resourceType}/${id}/annotations`
      const uploadsState = state[key] || {}
      const attached = uploadsState.attached || []
      const overallProgress = state.progress || []
      const progress = uploadsState.progress || []

      const uploadIds = files.map(fileId => state.files[fileId].uploadId)

      return {
        ...state,
        [key]: {
          ...uploadsState,
          attached: [...attached, ...files],
          progress: difference(progress, uploadIds),
        },
        progress: difference(overallProgress, uploadIds),
      }
    }

    case annotations.actionTypes.ADD_ATTACHMENTS_REQUEST_FAILED: {
      const { files, resourceType, id } = action
      const key = `documents/${resourceType}/${id}/annotations`
      const uploadsState = state[key] || {}
      const attachFailed = uploadsState.attachFailed || []
      const overallFailed = state.failed || []
      const failed = uploadsState.failed || []
      const overallProgress = state.progress || []
      const progress = uploadsState.progress || []
    
      const uploadIds = files.map(fileId => state.files[fileId].uploadId)
      return {
        ...state,
        [key]: {
          ...uploadsState,
          attachFailed: [...attachFailed, ...files],
          failed: [...failed, ...uploadIds],
          progress: difference(progress, uploadIds),
        },
        failed: [...overallFailed, ...uploadIds],
        progress: difference(overallProgress, uploadIds),
      }
    }

    case actionTypes.UPLOAD_QUEUE_DRAINED: {
      const { key, completed, failed } = action
      const newFiles = { ...(state.files || {})}
      const newHandles = { ...(state.handles || {}) }
      const newUploads = { ...state.uploads }
      completed.forEach(fileId => {
        const handle = newFiles[fileId]?.handle
        const uploadId = newFiles[fileId]?.uploadId
        newFiles[fileId] = undefined
        newHandles[handle] = undefined
        newUploads[uploadId] = undefined
      })
      failed.forEach(uploadId => {
        const handle = newUploads[uploadId]?.handle
        const fileId = newUploads[uploadId]?.fileId
        newFiles[fileId] = undefined
        newHandles[handle] = undefined
        newUploads[uploadId] = undefined
      })

      return {
        ...state,
        files: newFiles,
        handles: newHandles,
        uploads: newUploads,
        completed: difference(state.completed, completed),
        failed: difference(state.failed, failed),
        [key]: undefined,
      }
    }

    default: return state
  }
}

const areFilesUploading = (state, key) => !!getProp(state, `uploadsUi.${key}.progress`, []).length
const selectUploadsFailed = (state, key) => getProp(state, `uploadsUi.${key}.failed`, [])
const selectUploadsInProgress = (state, key) => getProp(state, `uploadsUi.${key}.progress`, [])
const selectUploadsStarted = (state, key) => getProp(state, `uploadsUi.${key}.started`, [])
const selectUploadsImported = (state, key) => getProp(state, `uploadsUi.${key}.imported`, [])
const selectUploadsAttached = (state, key) => getProp(state, `uploadsUi.${key}.attached`, [])
const selectUpload = (state, uploadId) => getProp(state, `uploadsUi.uploads.${uploadId}`)

// it goes like this: 
// - files are uploaded through Filestack
// - upload succeeds or fails
// - files are imported as assets
// - files are attached to document (only annotations)
// the queue is drained when all files are imported or, if there is an attachments step,
// when all files are attached (or failed)
// Note: if we removed the stupid toast, we wouldn't need any of this
function* processUpload({ key }) {
  const started = yield select(selectUploadsStarted, key)
  const imported = yield select(selectUploadsImported, key)
  const attached = yield select(selectUploadsAttached, key)
  const failed = yield select(selectUploadsFailed, key)

  // for attachments (annotations) we have to wait until all the files have been attached
  // to drain the queue
  const attach = ANNOTATIONS_QUEUE_REGEX.test(key)

  const completed = attach ? attached : imported
  const total = completed?.length + failed.length

  if (started?.length && started?.length <= total) {
    yield put({
      type: actionTypes.UPLOAD_QUEUE_DRAINED,
      key,
      completed,
      failed,
    })
  }
}

function* processAnnotations({ resourceType, id }) {
  const key = `documents/${resourceType}/${id}/annotations`
  yield* processUpload({ key })
}

function* saga() {
  yield takeEvery([files.actionTypes.ASSETS_IMPORTED, files.actionTypes.ASSETS_IMPORT_FAILED, actionTypes.UPLOADS_FAILED], processUpload)
  yield takeEvery([
    annotations.actionTypes.ATTACHMENTS_ADDED,
    annotations.actionTypes.ADD_ATTACHMENTS_REQUEST_FAILED,
  ], processAnnotations)
}

const uploadsUi = {
  actionTypes,
  uploadProgress,
  uploadsFailed,
  uploadsStarted,
  uploadsCompleted,
  reducer,
  areFilesUploading,
  selectUpload,
  selectUploadsStarted,
  selectUploadsInProgress,
  selectUploadsImported,
  selectUploadsFailed,
  selectUploadsAttached,
  saga,
  processUpload,
  processAnnotations,
}

export default uploadsUi
