import React, { lazy, useCallback, useEffect, Suspense } from 'react'
import { useDispatch, useSelector } from "react-redux"
import { BrowserRouter } from 'react-router-dom'
import { DndProvider } from 'react-dnd-cjs'
import HTML5Backend from 'react-dnd-html5-backend-cjs'
import i18n from 'i18next'
import { session, bootstrap } from '@concrete/one-redux'
import { Loader } from '@concrete/web-components'
import NavigationView from 'components/NavigationView'
import ErrorBoundary from 'components/ErrorBoundary'
import Toast from 'components/Toast'
import Loading from 'pages/Loading'
import { GlobalStyle, AppWrapper, AppView, Body } from 'style'
import Routes, { RouteHeader, RouteHeaderNavigation, RouteFilters, RouteFooter } from 'Routes'
import { initStreams } from 'helpers'
import { useAnalytics } from 'hooks'

const ModalManager = lazy(() => import('components/ModalManager'))

const App = () => {
  const dispatch = useDispatch()
  const user = useSelector(session.selectCurrentUser)
  const loaded = useSelector(bootstrap.isComplete)

  useAnalytics(user)

  useEffect(() => {
    if (loaded && user) {
      // the app has been bootstrapped and the user loaded in the store, authenticate into firebase
      // to subscribe to notifications and events
      (async () => {
        i18n.changeLanguage(user.locale)
        // TODO: move firebase init and auth to one-redux?
        await initStreams(user, dispatch)
      })()
    }
  }, [dispatch, loaded, user])

  const renderHeader = useCallback((compact, resetScroll) => <RouteHeader compact={compact} resetScroll={resetScroll} />, [])

  return (
    <>
      <Suspense fallback={<Loading />}>
        <BrowserRouter>
          <DndProvider backend={HTML5Backend}>
            <ErrorBoundary
              mx={4}
              maxWidth="500px"
              text={i18n.t('errors.fullPage')}
              size="large"
            >
              <AppWrapper>
                <ModalManager />
                {loaded ? (
                  <>
                    <AppView>
                      <NavigationView renderTop={renderHeader}>
                        <RouteHeaderNavigation />
                        <RouteFilters />
                      </NavigationView>
                      <Body>
                        <Routes />
                      </Body>
                      <RouteFooter />
                    </AppView>
                    <Toast />
                  </>
                ) : <Loader />}
              </AppWrapper>
            </ErrorBoundary>
          </DndProvider>
        </BrowserRouter>
      </Suspense>
      <GlobalStyle />
    </>
  )
}

export default App
