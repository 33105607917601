import { getQueryParamsFromLocation, isSafari } from 'helpers'
import { isAllowedOrigin, getUniversalLoginUrl } from './helpers'

export const CHECK_SESSION_TIMEOUT = 10000

const auth = {
  getSsoLogoutUrl(federated = false) {
    return `${getUniversalLoginUrl()}/logout`
  },
  authorize(redirectUrl) {
    const returnUrl = encodeURIComponent(
      `${window.location.origin}/callback`
    )
    window.location.assign(
      `${getUniversalLoginUrl()}?callbackUrl=${returnUrl}${redirectUrl ? '&redirectUrl=' + redirectUrl : ''}`
    )
  },
  getSsoToken(callback) {
    try {
      if (isSafari()) {
        // Safari unfortunately doesn't allow access of indexed db in cross-origin iframes
        // which firebase uses to keep session data
        return callback(null, false)
      }
      // first, create an invisible iframe
      const iframe = document.createElement('iframe')
      iframe.style.display = 'none'
      document.body.appendChild(iframe)
      const timeout = setTimeout(() => {
        // as a fallback measure, in case the iframe doesn't respond within CHECK_SESSION_TIMEOUT ms
        // we just return to the caller with no sso token
        // if the user has a session cookie, they might still be logged in
        callback(null, false)
        document.body.removeChild(iframe)
      }, CHECK_SESSION_TIMEOUT)
      const messageListener = e => {
        // check the message origin comes from the Universal Login page
        if (isAllowedOrigin(e.origin)) {
          if (e.data?.startsWith?.('session:')) {
            // there is an active user session, calling back with the token
            callback(e.data.replace('session:', ''), true)
          } else {
            // there is no active session
            callback()
          }
          document.body.removeChild(iframe)
          clearTimeout(timeout)
          window.removeEventListener('message', messageListener)
        }
      }
      iframe.onerror = (error) => {
        console.error('Error connecting with UL page: ', error)
        // user might have a valid session
        callback('token', false)
        document.body.removeChild(iframe)
      }
      window.addEventListener('message', messageListener)
      // the iframe will communicate with the Concrete Universal Login page to
      // retrieve authentication status
      iframe.src = getUniversalLoginUrl()
    } catch (error) {
      console.error('Error checking the session: ', error)
      callback()
    }
  },
  handleSsoAuth(callback) {
    const { id_token, access_token } = getQueryParamsFromLocation(window.location.hash)
    callback(id_token || access_token)
  },
  logout() {
    const returnUrl = encodeURIComponent(
      `${window.location.origin}/callback`
    )
    window.location.href = `${getUniversalLoginUrl()}/logout?callbackUrl=${returnUrl}`
  },
}

function init() {
  return auth
}

const firebase = {
  init
}

export default firebase
