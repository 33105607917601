import { createTheme } from '@mui/material/styles'

const colors = {
  black: '#000000',
  white: '#FFFFFF',
  grey_100: '#F5F5F5',
  grey_400: '#5F5F5F',
  grey_600: '#1F1F1F',
  blue: '#0070F0',
  blue_light: '#18AAFF',
  blue_dark: '#2243CD',
  red: '#E22840',
  red_light: '#FC5161',
  red_dark: '#C70C2D',
  orange: '#FF9800',
  orange_light: '#FFCD80',
  orange_dark: '#F57D00',
  green: '#16D09B',
  green_light: '#A3F8DE',
  green_dark: '#009464',
}

const fontWeights = {
  light: 300,
  regular: 400,
  semiBold: 500
}

const fontSizes = [ 10, 12, 14, 16, 20, 24, 34, 48, 60, 96 ]

export const theme = createTheme({
  palette: {
    type: 'light',
    primary: {
      main: colors.grey_600,
      light: colors.grey_400,
      dark: colors.black,
    },
    secondary: {
      main: colors.blue,
      light: colors.blue_light,
      dark: colors.blue_dark,
    },
    text: {
      main: `${colors.black}87`,
      secondary: `${colors.black}54`,
      disabled: `${colors.black}38`,
      hint: `${colors.black}38`,
    },
    error: {
      main: colors.red,
      light: colors.red_light,
      dark: colors.red_dark,
    },
    warning: {
      main: colors.orange,
      light: colors.orange_light,
      dark: colors.orange_dark,
      contrastText: colors.black,
    },
    success: {
      main: colors.green,
      light: colors.green_light,
      dark: colors.green_dark,
      contrastText: colors.black,
    },
    info: {
      main: colors.grey_600,
      light: colors.grey_400,
      dark: colors.black,
    },
    background: {
      default: colors.white,
      paper: colors.white,
    },
    divider: colors.grey_100,
  },
  typography: {
    h1: {
      fontSize: fontSizes[9],
      fontWeight: fontWeights.light,
    },
    h2: {
      fontSize: fontSizes[8],
      fontWeight: fontWeights.regular,
    },
    h3: {
      fontSize: fontSizes[7],
      fontWeight: fontWeights.regular,
    },
    h4: {
      fontSize: fontSizes[6],
      fontWeight: fontWeights.regular,
    },
    h5: {
      fontSize: fontSizes[5],
      fontWeight: fontWeights.regular,
    },
    h6: {
      fontSize: fontSizes[4],
      fontWeight: fontWeights.regular,
    },
    subtitle1: {
      fontSize: fontSizes[3],
      fontWeight: fontWeights.regular,
    },
    subtitle2: {
      fontSize: fontSizes[2],
      fontWeight: fontWeights.semiBold,
    },
    body1: {
      fontSize: fontSizes[3],
      fontWeight: fontWeights.regular,
    },
    body2: {
      fontSize: fontSizes[2],
      fontWeight: fontWeights.regular,
    },
    button: {
      fontSize: fontSizes[2],
      fontWeight: fontWeights.semiBold,
    },
    caption: {
      fontSize: fontSizes[1],
      fontWeight: fontWeights.regular,
    },
    overline: {
      fontSize: fontSizes[0],
      fontWeight: fontWeights.regular,
    },
    fontFamily: 'Open Sans',
    fontSize: fontSizes[3],
  },
  shape: {
    borderRadius: 4,
  },
  components: {
    MuiChip: {
      styleOverrides: {
        sizeSmall: {
          fontSize: fontSizes[0],
        }
      }
    },
    MuiDialog: {
      styleOverrides: {
        paperWidthSm: {
          maxWidth: 280,
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          justifyContent: 'center',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          fontWeight: fontWeights.regular,
          boxShadow: 'none',
        },
      },
    },
  }
})
