import React, { useCallback, useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { useLocation } from 'react-router'
import { useSelector } from 'react-redux'
import { documents } from '@concrete/one-redux'
import { RESOURCE_TYPES } from '@concrete/resource'
import { Flex, FlexColumn, SearchBar, hooks } from '@concrete/web-components'
import FacetsFilterButton from './FacetsFilterButton'
import LocationPickerButton from './LocationPickerButton'
import FilterPickerButton from './FilterPickerButton'
import SortPickerButton from './SortPickerButton'
import ViewPickerButton from './ViewPickerButton'
import ResourceCount from './ResourceCount'
import { useResourceState, useResourceNavigator, useGridSize } from 'hooks'
import { getResource } from 'helpers'
import { resourceLocation } from 'state'
import { LAYOUTS } from 'helpers/constants'
import { useDebounce } from 'use-debounce'
import { useTranslation } from 'react-i18next'

export const DEBOUNCE_DELAY = 500

const { CALENDAR } = LAYOUTS
const { TASKS, FORMS, STORIES } = RESOURCE_TYPES

const ResourceFilters = () => {
  const { t } = useTranslation()
  const { pathname } = useLocation()
  const gridWidth = useGridSize()
  const resourceType = getResource(pathname)
  const resourceState = useResourceState(resourceType)
  const navigator = useResourceNavigator(resourceType)
  const currentGroupId = useSelector(state => resourceLocation.selectCurrentLocation(state, resourceType))
  const formsFacets = useSelector(state => documents.selectDocumentFacets(state, resourceType, currentGroupId))
  const { facet, sort, layout, q } = resourceState
  const showEmptyState = formsFacets?.every(facet => facet.total === 0)
  const isMobile = hooks.useMediaSize('xs')

  const qRef = useRef('')
  const [searchText, setSearchText] = useState(q)
  const [searchVal] = useDebounce(searchText, DEBOUNCE_DELAY)

  const handleSearch = useCallback(value => {
    const newQuery = {
      ...resourceState,
      page: 1,
      q: value,
      facet,
    }
    navigator.replace(newQuery, !value)
  }, [facet, navigator, resourceState])

  useEffect(() => {
    if (qRef.current !== searchVal) {
      handleSearch(searchVal)
      qRef.current = searchVal
    }
  }, [handleSearch, searchVal])

  const renderSearchBar = () => (
    <SearchBar
      key={`${resourceType}-${isMobile}-${q}`}
      data-testid={`${resourceType}-search-bar`}
      aria-label={`${resourceType} search`}
      onChange={setSearchText}
      onClear={setSearchText}
      defaultValue={q}
      placeholder={layout === CALENDAR ? t('search.calendar') : t(`search.${resourceType}`)}
      maxWidth={['none', 360]}
      fixedOpen={!!q || isMobile}
      ml={[0, 2]}
      mt={[2, 0]}
    />
  )

  if (resourceType === FORMS && showEmptyState) return null
  return (
    <FlexColumn
      data-testid="resource-filters"
      justifyContent="space-between"
      alignContent="center"
      mx="auto"
      mb={4}
      px={2}
      width="100%"
      maxWidth={gridWidth}
      flexShrink="0"
    >
      <Flex>
        <FacetsFilterButton resourceType={resourceType} activeItem={facet} layout={layout} navigator={navigator} />
        {!isMobile && renderSearchBar()}
        <ResourceCount resourceType={resourceType} />
        <Flex ml="auto">
          <LocationPickerButton navigator={navigator} resourceType={resourceType} facet={facet} layout={layout} persistGroup={false} />
          <FilterPickerButton navigator={navigator} resourceType={resourceType} facet={facet} layout={layout} />
          {![CALENDAR].includes(layout) && <SortPickerButton navigator={navigator} resourceType={resourceType} facet={facet} sort={sort?.toString()} />}
          {[TASKS, STORIES].includes(resourceType) && <ViewPickerButton navigator={navigator} facet={facet} layout={layout?.toString()} />}
        </Flex>
      </Flex>
      {isMobile && renderSearchBar()}
    </FlexColumn>
  )
}

ResourceFilters.propTypes = {
  resourceType: PropTypes.string,
}

export default ResourceFilters
