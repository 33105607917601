const actionTypes = {
  SAVE_SEARCH_FILTERS: 'SAVE_SEARCH_FILTERS',
  CLEAR_SEARCH_FILTERS: 'CLEAR_SEARCH_FILTERS',
}

const saveFilters = search => ({
  type: actionTypes.SAVE_SEARCH_FILTERS,
  search,
})

const clearFilters = () => ({
  type: actionTypes.CLEAR_SEARCH_FILTERS,
})

const reducer = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.SAVE_SEARCH_FILTERS: {
      return {
        ...state,
        lastSearch: action.search,
      }
    }

    case actionTypes.CLEAR_SEARCH_FILTERS: {
      const { lastSearch, ...newState } = state
      return newState
    }

    default:
      return state
  }
}

const selectLastSearch = state => state.searchUi?.lastSearch

const searchUi = {
  actionTypes,
  saveFilters,
  clearFilters,
  selectLastSearch,
  reducer,
}

export default searchUi
